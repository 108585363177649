import React from 'react';
import CategoryDetailRoutingComponent from './routing/category-detail-routing.component';
import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import businessObjectService from '../../../../../../services/business-object.service';

export default function CategoryDetailContextComponent(props: any) {
  const { match } = props;
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;

  const [category, setCategory] = React.useState<ProductCategory>(null);

  React.useEffect(() => {
    const getCategory = async (userEmail: string, boId: number, categoryId: number) => {
      const data = await businessObjectService.getCategoryById(userEmail, boId, categoryId);
      setCategory(data);

      return data;
    };

    const categoryId = match?.params?.categoryId;

    getCategory(user?.email, businessObject?.id, categoryId);
  }, []);

  return category ? <CategoryDetailRoutingComponent {...props} category={category} /> : <></>;
}
