import { PRIVATE_FRAME_ROUTE } from '../../../routing/private-frame-route-definition.model';
import { createRoute } from '../../../../../commons/utils/route-helper';

const USER_ROUTE = {
  USER_CREATE: `${PRIVATE_FRAME_ROUTE.USER}/create`,
  USER_DETAIL: `${PRIVATE_FRAME_ROUTE.USER}/:userId`,
};

const getUserDetailPath = function (userId: number): string {
  return createRoute(USER_ROUTE.USER_DETAIL, { userId });
};

export { USER_ROUTE, getUserDetailPath };
