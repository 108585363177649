import React from 'react';
import ScrollToTop from '../../../../../commons/utils/scroll-to-top';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../../../routes/private-route.component';
import { PRODUCT_ROUTE } from './product-route-definition.model';
import ProductCreateComponent from '../page/product-create/product-create.component';
import ProductListComponent from '../page/product-list/product-list.component';
import ProductDetailContextComponent from '../page/product-detail-context/product-detail-context.component';

export default function ProductRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} path={PRODUCT_ROUTE.PRODUCT_CREATE} component={ProductCreateComponent} />
          <PrivateRoute {...props} path={PRODUCT_ROUTE.PRODUCT_DETAIL} component={ProductDetailContextComponent} />
          <PrivateRoute {...props} component={ProductListComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
