import React from 'react';
import './not-found.scss';
import { PUBLIC_ROUTE } from '../../../routes/route-definition.model';

export default function NotFound(props: any) {
  return (
    <div className="not-found-container">
      <div className="not-found">
        <div className="not-found-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can't be found</h2>
        </div>
        <a href={PUBLIC_ROUTE.AUTH_PROCESS}>Go to Login</a>
      </div>
    </div>
  );
}
