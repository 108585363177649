import React from 'react';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';

export interface SmsProviderPrice {
  countryCode: string;
  bulkgatePriceEur: number;
  bulkgateTotalAvailableSms: number;
  gatewayApiPriceEur: number;
  gatewayApiTotalAvailableSms: number;
}

interface SmsProviderPriceTableComponentProps {
  data: SmsProviderPrice[];
}

export default function SmsProviderPriceTableComponent(props: SmsProviderPriceTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'countryCode', field: 'countryCode', label: 'Country Code' },
    { id: 'bulkgatePriceEur', field: 'bulkgatePriceEur', label: 'Bulkgate price' },
    { id: 'bulkgateTotalAvailableSms', field: 'bulkgateTotalAvailableSms', label: 'Bulkgate total SMS' },
    { id: 'gatewayApiPriceEur', field: 'gatewayApiPriceEur', label: 'GatewayApi price' },
    { id: 'gatewayApiTotalAvailableSms', field: 'gatewayApiTotalAvailableSms', label: 'GatewayApi total SMS' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: 'SMS price in EUR and total available SMS',
    rowsPerPage: 10,
    headCells,
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.data} />;
}
