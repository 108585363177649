import React from 'react';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import { Redirect } from 'react-router-dom';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { getCategoryDetailPath } from '../../routing/category-route-definition.model';

interface CategoryTableComponentProps {
  userId: number;
  businessObjectId: number;
  categories?: ProductCategory[];
}

export default function CategoryTableComponent(props: CategoryTableComponentProps) {
  const [pathToCategoryDetail, setPathToCategoryDetail] = React.useState<string>(null);

  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'displayOrder', field: 'displayOrder', label: 'Display order' },
    { id: 'name', field: 'name', label: 'Name' },
    { id: 'description', field: 'description', label: 'Description' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: 'Categories',
    rowsPerPage: 10,
    headCells,
  };

  const handleSelectRow = (selectedCategory: ProductCategory) => {
    if (selectedCategory?.id) {
      setPathToCategoryDetail(getCategoryDetailPath(props.userId, props.businessObjectId, selectedCategory.id));
    }
  };

  return pathToCategoryDetail ? (
    <Redirect to={{ pathname: pathToCategoryDetail }} />
  ) : (
    <CustomDataTableComponent
      tableConfig={tableConfig}
      data={props.categories as ProductCategory[]}
      onSelect={handleSelectRow}
    />
  );
}
