import React from 'react';
import { ServiceTag } from '../../../../../../models/interfaces/ServiceTag.interface';
import { Button, Chip, Grid, InputAdornment, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

interface ServiceTagVariantFormComponentProps {
  serviceTag?: ServiceTag;
  isLoading: boolean;
  languageOptions: string[];
  onUpdate: Function;
  onDelete: Function;
}

export default function ServiceTagVariantFormComponent(props: ServiceTagVariantFormComponentProps) {
  const [newVariant, setNewVariant] = React.useState<string>(null);
  const [serviceTag, setServiceTag] = React.useState<ServiceTag>(props.serviceTag || {});

  const isStringValid = (str: string): boolean => {
    return !!str && !!str.trim()?.length;
  };

  const isNewVariantValid = (): boolean => {
    if (!isStringValid(newVariant)) {
      return false;
    }

    return !serviceTag?.variantList?.length || serviceTag?.variantList?.findIndex(v => v === newVariant) < 0;
  };

  const areAllVariantsValid = (): boolean => {
    if (!serviceTag?.variantList?.length) {
      return false;
    }

    return serviceTag?.variantList.findIndex(v => !isStringValid(v)) < 0;
  };

  const generateAndEmitServiceTag = () => {
    const valueToEmit: ServiceTag =
      isStringValid(serviceTag?.lang) && areAllVariantsValid()
        ? {
            ...(props.serviceTag || {}),
            ...(serviceTag || {}),
          }
        : null;

    props.onUpdate(valueToEmit);
  };

  React.useEffect(() => {
    generateAndEmitServiceTag();
  }, [serviceTag]);

  const deleteHandler = () => {
    props.onDelete();
  };

  const addNewVariantHandler = () => {
    setNewVariant(null);
    setServiceTag({
      ...serviceTag,
      variantList: serviceTag?.variantList ? [...serviceTag?.variantList, newVariant] : [newVariant],
    });
  };

  const deleteVariantHandler = (variant: string) => {
    setServiceTag({
      ...serviceTag,
      variantList: serviceTag.variantList?.filter(v => v !== variant),
    });
  };

  const changeLangHandler = (lang: string) => {
    setServiceTag({
      ...serviceTag,
      lang,
    });
  };

  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.marginTop}>
      <Grid item xs={12} sm={4}>
        <div style={{ display: 'flex' }}>
          <IconButton aria-label="delete" color="secondary" onClick={deleteHandler}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <Autocomplete
            freeSolo
            style={{ width: '100%' }}
            value={serviceTag?.lang || ''}
            options={props.languageOptions}
            disabled={props.isLoading}
            onInputChange={(event: any, newValue: string | null) => changeLangHandler(newValue)}
            renderInput={params => <TextField {...params} required label="Language" variant="outlined" />}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          fullWidth
          key="variant"
          label="Add new variant"
          name="variant"
          value={newVariant || ''}
          onChange={(event: any) => setNewVariant(event.target.value)}
          disabled={props.isLoading || !isStringValid(serviceTag?.lang)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={addNewVariantHandler}
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={props.isLoading || !isStringValid(serviceTag?.lang) || !isNewVariantValid()}
                >
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {serviceTag?.variantList?.length ? (
          serviceTag?.variantList?.map(v => (
            <Chip
              className={classes.marginRight}
              size="small"
              key={v}
              label={v}
              disabled={props.isLoading}
              onDelete={() => {
                deleteVariantHandler(v);
              }}
            />
          ))
        ) : (
          <div>Please add at least 1 variant</div>
        )}
      </Grid>
    </Grid>
  );
}
