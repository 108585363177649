import React from 'react';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { CommunicationUnitPackage } from '../../../../../../models/interfaces/CommunicationUnitPackage.interface';

interface CreditPackageTableComponentProps {
  creditPackages: CommunicationUnitPackage[];
  onSelect?: Function;
}

export default function CreditPackageTableComponent(props: CreditPackageTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'balance', field: 'balance', label: 'Nr. Credit' },
    { id: 'price', field: 'price', label: 'Price' },
    { id: 'provider', field: 'provider', label: 'Provider' },
    { id: 'countryCode', field: 'countryCode', label: 'Locale' },
    { id: 'smsPrice', field: 'smsPrice', label: 'Price per SMS' },
    { id: 'emailPrice', field: 'emailPrice', label: 'Price per Email' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Credit Packages`,
    rowsPerPage: 10,
    headCells,
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.creditPackages} onSelect={props?.onSelect} />;
}
