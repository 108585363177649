import React from 'react';
import { ServiceTag } from '../../../../../../models/interfaces/ServiceTag.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';

interface ServiceTagTableComponentProps {
  serviceTags: ServiceTag[];
  onSelect?: Function;
}

export default function ServiceTagTableComponent(props: ServiceTagTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'lang', field: 'lang', label: 'Language' },
    { id: 'tag', field: 'tag', label: 'Tag' },
    { id: 'variantList', field: 'variantList', label: 'Variant' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Service tags`,
    rowsPerPage: 10,
    headCells,
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.serviceTags} onSelect={props?.onSelect} />;
}
