import { USER_ROLE } from '../enums/UserRole.enum';
import { BUSINESS_OBJECT_VERSION } from '../enums/BusinessObjectVersion.enum';

export const AUTHORITY_ROLE_WITH_ACCESS: USER_ROLE[] = [USER_ROLE.ADMIN];

export const NOT_INTERESTED_AUTHORITIES = ['ANONYMOUS', 'ADMIN'];

interface MapOfAuthorityAndBoVersion {
  [k: string]: BUSINESS_OBJECT_VERSION;
}

export const MAP_OF_AUTHORITY_AND_BO_VERSION: MapOfAuthorityAndBoVersion = {
  USER: BUSINESS_OBJECT_VERSION.PRO,
  USER_LITE: BUSINESS_OBJECT_VERSION.LITE,
  USER_LITE_PLUS: BUSINESS_OBJECT_VERSION.LITE_PLUS,
};
