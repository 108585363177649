import { NumberUtils } from '../../../../../../../commons/utils/number.utils';
import { EXTRA_FEE } from '../../../../../../../models/consts/ExtraFee.const';
import { DEFAULT_CREDIT_PRICE_PER_EMAIL } from '../../../../../../../models/consts/Other.const';

export class CreditPriceCalculatorUtils {
  private static readonly CALCULATION_FACTOR_A = 46.315888;
  private static readonly CALCULATION_FACTOR_B = -626.31777;
  private static readonly TOTAL_CREDIT_OF_THE_SMALLEST_CREDIT_PACKAGE = 3000;
  private static readonly EUR_PRICE_OF_THE_SMALLEST_CREDIT_PACKAGE = 30;

  public static calculateCreditPricePerSms(eurPricePerSms: number): number {
    if (!eurPricePerSms || eurPricePerSms < 0) {
      return null;
    }

    const totalExtraFeeInPercentage = CreditPriceCalculatorUtils.getTotalExtraFeeInPercentage();
    const eurPricePerSmsWithExtraFee = (100 * eurPricePerSms) / (100 - totalExtraFeeInPercentage);

    const totalEurCostOfTheSmallestCreditPackageForProvider =
      ((this.CALCULATION_FACTOR_A * this.EUR_PRICE_OF_THE_SMALLEST_CREDIT_PACKAGE + this.CALCULATION_FACTOR_B) *
        eurPricePerSmsWithExtraFee) /
      (1 + this.CALCULATION_FACTOR_A * eurPricePerSmsWithExtraFee);
    const maxNumberOfSmsCanBeSentForTheSmallestCreditPackage =
      totalEurCostOfTheSmallestCreditPackageForProvider / eurPricePerSmsWithExtraFee;
    const creditPricePerSmsOfTheSmallestCreditPackage =
      this.TOTAL_CREDIT_OF_THE_SMALLEST_CREDIT_PACKAGE / maxNumberOfSmsCanBeSentForTheSmallestCreditPackage;

    return NumberUtils.roundUpNumber(creditPricePerSmsOfTheSmallestCreditPackage, 2);
  }

  public static calculateCreditPricePerEmail(): number {
    const totalExtraFeeInPercentage = CreditPriceCalculatorUtils.getTotalExtraFeeInPercentage();
    return (100 * DEFAULT_CREDIT_PRICE_PER_EMAIL) / (100 - totalExtraFeeInPercentage);
  }

  private static getTotalExtraFeeInPercentage(): number {
    return EXTRA_FEE.reduce((a, b) => a + b, 0);
  }
}
