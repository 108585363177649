class StorageService {
  public getItem(key: string): any {
    try {
      const item = localStorage.getItem(key);
      if (item) {
        return JSON.parse(item);
      } else {
        return item;
      }
    } catch (e) {
      return undefined;
    }
  }

  public setItem(key: string, value: any): void {
    if (!key) {
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      localStorage.setItem(key, value);
    }
  }

  public removeItem(key: string): void {
    if (key) {
      localStorage.removeItem(key);
    }
  }

  public clear() {
    localStorage.clear();
  }
}

const storageService = new StorageService();

export default storageService;
