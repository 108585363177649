import React from 'react';
import { ServiceTag } from '../../../../../../models/interfaces/ServiceTag.interface';
import { Button, Grid, TextField } from '@material-ui/core';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { SERVICE_TAG_VARIANTS_DELIMITER } from '../../../../../../models/consts/Other.const';
import ServiceTagVariantFormComponent from '../service-tag-variant-form/service-tag-variant-form.component';

const useStyles = makeStyles(theme => ({
  marginTopMedium: {
    marginTop: theme.spacing(3),
  },
}));

interface ServiceTagDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  serviceTags?: ServiceTag[];
  languageOptions: string[];
  tagOptions: string[];
  onClose: Function;
  onUpdate?: Function;
  onCreate?: Function;
}

export default function ServiceTagDialogComponent(props: ServiceTagDialogComponentProps) {
  const [tag, setTag] = React.useState<string>(null);
  const [serviceTags, setServiceTags] = React.useState<ServiceTag[]>([]);
  const [languageOptions, setLanguageOptions] = React.useState<string[]>([]);
  const [availableLanguageOptions, setAvailableLanguageOptions] = React.useState<string[]>([]);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);

  React.useEffect(() => {
    setLanguageOptions(props.languageOptions?.filter(l => l !== 'all'));
    setTagOptions(props.tagOptions?.filter(t => t !== 'all'));
    setTag(props.serviceTags?.[0]?.tag);
    setServiceTags(props.serviceTags || [{}]);
  }, [props]);

  React.useEffect(() => {
    const usedLanguage: string[] = serviceTags?.filter(st => !!st)?.map(st => st.lang);
    setAvailableLanguageOptions(languageOptions?.filter(l => !usedLanguage.includes(l)));
  }, [serviceTags]);

  const isTagValid = (): boolean => {
    const tagTrimmed = tag?.trim();

    if (!tagTrimmed?.length) {
      // tag empty
      return false;
    }

    if (props.serviceTags?.length > 0) {
      const originalTag = props.serviceTags?.[0]?.tag;
      const usedTags = tagOptions?.filter(t => t !== originalTag);

      if (usedTags?.includes(tag)) {
        // tag is used
        return false;
      }
    }

    return true;
  };

  const isAnyDuplicatedLang = (): boolean => {
    const langSet = new Set<string>();

    let isDuplicate = false;

    serviceTags?.forEach(st => {
      if (langSet.has(st.lang)) {
        isDuplicate = true;
      }

      langSet.add(st.lang);
    });

    return isDuplicate;
  };

  const areAllServiceTagsValid = (): boolean => {
    return isTagValid() && !!serviceTags?.length && serviceTags?.findIndex(t => !t) < 0 && !isAnyDuplicatedLang();
  };

  const isAbleToAddNewLanguageVariant = (): boolean => {
    return isTagValid() && (!serviceTags?.length || (serviceTags?.findIndex(t => !t) < 0 && !isAnyDuplicatedLang()));
  };

  const addNewLanguageVariant = () => {
    if (!isAbleToAddNewLanguageVariant()) {
      return;
    }

    setServiceTags([...serviceTags, null]);
  };

  const tagChangeHandler = (event: any) => {
    const { value } = event.target;
    setTag(value);
  };

  const areSameServiceTag = (st1: ServiceTag, st2: ServiceTag): boolean => {
    if (!st1 && !st2) {
      return true;
    }

    if (!st1 || !st2) {
      return false;
    }

    if (st1.lang !== st2.lang) {
      return false;
    }

    if (st1.variantList?.length !== st2.variantList?.length) {
      return false;
    }

    return (
      st1.variantList?.join(SERVICE_TAG_VARIANTS_DELIMITER) === st2.variantList?.join(SERVICE_TAG_VARIANTS_DELIMITER)
    );
  };

  const onUpdateServiceTag = (serviceTag: ServiceTag, index: number) => {
    const originalServiceTag = serviceTags[index];
    /*if (!!serviceTag && !areSameServiceTag(originalServiceTag, serviceTag)) {*/
    if (!areSameServiceTag(originalServiceTag, serviceTag)) {
      setServiceTags(serviceTags.map((st, i) => (i === index ? serviceTag : st)));
    }
  };

  const onRemoveOneOfServiceTag = (index: number) => {
    setServiceTags(serviceTags?.filter((st, i) => i !== index));
  };

  const transformServiceTagsForBackend = (serviceTags: ServiceTag[]): ServiceTag[] => {
    return serviceTags?.map(st => ({
      ...st,
      tag,
      variant: st.variantList?.join(SERVICE_TAG_VARIANTS_DELIMITER),
    }));
  };

  const deleteAllHandler = () => {
    props.onUpdate([]);
  };

  const updateAllHandler = () => {
    if (!areAllServiceTagsValid()) {
      return;
    }
    props.onUpdate(transformServiceTagsForBackend(serviceTags));
  };

  const createAllHandler = () => {
    if (!areAllServiceTagsValid()) {
      return;
    }
    props.onCreate(transformServiceTagsForBackend(serviceTags));
  };

  const classes = useStyles();

  const getTitle = () => {
    const tag = props.serviceTags?.[0]?.tag;

    if (tag?.length) {
      return `Edit service tag [${tag}]`;
    } else {
      return 'Add a new service tag';
    }
  };

  const getContent = () => {
    return (
      <>
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          fullWidth
          key="tag"
          label="Tag"
          name="tag"
          value={tag}
          onChange={tagChangeHandler}
        />

        {serviceTags?.map((serviceTag, index) => (
          <ServiceTagVariantFormComponent
            key={index}
            serviceTag={serviceTag}
            isLoading={props.isLoading}
            languageOptions={availableLanguageOptions}
            onUpdate={(event: any) => onUpdateServiceTag(event, index)}
            onDelete={() => onRemoveOneOfServiceTag(index)}
          ></ServiceTagVariantFormComponent>
        ))}

        <Button
          className={classes.marginTopMedium}
          onClick={addNewLanguageVariant}
          fullWidth
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          disabled={!isAbleToAddNewLanguageVariant()}
        >
          New language
        </Button>
      </>
    );
  };

  const getButtons = () => {
    return props.serviceTags?.length ? (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" color="secondary" disabled={props.isLoading} onClick={deleteAllHandler}>
            Delete
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading || !areAllServiceTagsValid()}
            onClick={updateAllHandler}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading || !serviceTags?.length || !areAllServiceTagsValid()}
            onClick={createAllHandler}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      isLoading={props.isLoading}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
      styleOptions={{ fullWidth: true }}
    ></CustomDialogComponent>
  );
}
