import React from 'react';
import BusinessObjectRoutingComponent from './routing/business-object-routing.component';
import { User } from '../../../../models/interfaces/User.interface';
import UserInfoCardComponent from '../user-context/components/user-info-card/user-info-card.component';

export function BusinessObjectContextComponent(props: any) {
  const user: User = props.user;

  return (
    <>
      <UserInfoCardComponent user={user}></UserInfoCardComponent>
      <BusinessObjectRoutingComponent {...props} />
    </>
  );
}
