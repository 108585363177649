import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, TextField, MenuItem, InputAdornment, Toolbar, Chip } from '@material-ui/core';
import { Cancel, Edit, Save } from '@material-ui/icons';
import businessObjectService from '../../../../../../services/business-object.service';
import { getBoDetailPath } from '../../routing/business-object-route-definition.model';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { LOCALE } from '../../../../../../models/enums/Locale.enum';
import Typography from '@material-ui/core/Typography';
import { CURRENCY } from '../../../../../../models/enums/Currency.enum';
import { SERVICE_TAG_DELIMITER } from '../../../../../../models/consts/Other.const';
import serviceTagService from '../../../../../../services/service-tag.service';
import logger from '../../../../../../commons/logger';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ServiceTagUtils } from '../../../../../../commons/utils/service-tag.utils';
import GooglePlaceSearchDialogComponent, {
  MatchedGooglePlaceResult,
} from '../google-place-search-dialog/google-place-search-dialog.component';
import SearchIcon from '@material-ui/icons/Search';
import { BUSINESS_OBJECT_VERSION } from '../../../../../../models/enums/BusinessObjectVersion.enum';
import { User } from '../../../../../../models/interfaces/User.interface';
import adminService from '../../../../../../services/admin.service';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  serviceTagLabel: {
    marginTop: theme.spacing(1),
    color: 'grey',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginTopAndBottom: {
    margin: theme.spacing(1, 0),
  },
  tagCategory: {
    display: 'block',
    backgroundColor: 'lightgrey',
  },
  tagItem: {
    marginLeft: theme.spacing(1),
  },
  editButton: {
    width: 'fit-content',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface BusinessObjectFormComponentProps {
  owner: User;
  businessObject?: BusinessObject;
}

const listOfCurrency = convertEnumToArray(CURRENCY);
const listOfLocale = convertEnumToArray(LOCALE);
const listOfVersion = convertEnumToArray(BUSINESS_OBJECT_VERSION);

export default function BusinessObjectFormComponent(props: BusinessObjectFormComponentProps) {
  const [isEmitMode, setIsEmitMode] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [bo, setBo] = React.useState<BusinessObject>(null);
  const [boToSubmit, setBoToSubmit] = React.useState<BusinessObject>({});
  const [redirectToBoDetail, setRedirectToBoDetail] = React.useState<string>(null);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOpenGooglePlaceSearchDialog, setIsOpenGooglePlaceSearch] = React.useState<boolean>(false);

  const fetchAllServiceTags = async () => {
    try {
      setIsLoading(true);

      const data = await serviceTagService.fetchAllServiceTags();

      const tagSet = new Set<string>();
      data?.forEach(t => tagSet.add(t.tag));

      const unitTags = Array.from(tagSet.values());
      unitTags.sort();

      setTagOptions(unitTags);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchAllServiceTags();
    setIsEmitMode(!props?.businessObject);

    const initBo: BusinessObject = props?.businessObject
      ? { ...props.businessObject }
      : businessObjectService.createBasicBusinessObjects(props.owner);

    setBo(initBo);
    setBoToSubmit(initBo);
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    if (name === 'locale') {
      setBoToSubmit({
        ...boToSubmit,
        [name]: type === 'checkbox' ? checked : value,
      });
    } else {
      setBoToSubmit({
        ...boToSubmit,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const updateGooglePlaceData = (data: MatchedGooglePlaceResult) => {
    if (data?.placeId) {
      setBoToSubmit({
        ...boToSubmit,
        longitude: data?.longitude,
        latitude: data?.latitude,
        placeId: data?.placeId,
        address: boToSubmit.address || data?.address,
      });
      closeGooglePlaceSearchDialog();
    }
  };

  const openGooglePlaceSearchDialog = () => {
    setIsOpenGooglePlaceSearch(true);
  };

  const closeGooglePlaceSearchDialog = () => {
    setIsOpenGooglePlaceSearch(false);
  };

  const serviceTagChangeHandler = (event: any) => {
    const { name, checked } = event.target;

    const isTagCategory = ServiceTagUtils.isTreatmentCategory({ tag: name });
    let newServiceTagsList: string[];

    if (isTagCategory) {
      if (checked) {
        newServiceTagsList = tagOptions?.filter(t => t.startsWith(name) || boToSubmit.serviceTagsList?.includes(t));
      } else {
        newServiceTagsList = boToSubmit.serviceTagsList?.filter(t => !t.startsWith(name));
      }
    } else {
      newServiceTagsList = checked
        ? [...(boToSubmit.serviceTagsList || []), name]
        : boToSubmit.serviceTagsList?.filter(st => st !== name);

      const categoryTag = tagOptions?.find(t => ServiceTagUtils.isTreatmentCategory({ tag: t }) && name.startsWith(t));

      if (categoryTag) {
        const isAnyUnselectedSubcategoryTags =
          tagOptions?.filter(
            t =>
              !ServiceTagUtils.isTreatmentCategory({ tag: t }) &&
              t.startsWith(categoryTag) &&
              !newServiceTagsList.includes(t)
          )?.length > 0;

        if (checked) {
          // if all subcategory-tags are selected -> category tag will be selected automatically
          if (!isAnyUnselectedSubcategoryTags) {
            newServiceTagsList = tagOptions?.filter(
              t => t.startsWith(categoryTag) || boToSubmit.serviceTagsList?.includes(t)
            );
          }
        } else {
          // if not all subcategory-tags are selected -> category tag will be deselected automatically
          newServiceTagsList = newServiceTagsList?.filter(t => t !== categoryTag);
        }
      }
    }

    const newServiceTagsString = newServiceTagsList?.length ? newServiceTagsList.join(SERVICE_TAG_DELIMITER) : null;

    setBoToSubmit({
      ...boToSubmit,
      serviceTagsList: newServiceTagsList,
      serviceTags: newServiceTagsString,
    });
  };

  const isServiceTagIncluded = (serviceTag: string): boolean => {
    return boToSubmit?.serviceTagsList?.includes(serviceTag);
  };

  const reset = () => {
    setBoToSubmit(bo || businessObjectService.createBasicBusinessObjects(props.owner));
    setIsEmitMode(false);
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    let response: BusinessObject;
    if (boToSubmit?.id) {
      response = await businessObjectService.updateBusinessObject(boToSubmit);
    } else {
      const allBoOfUser = await businessObjectService.getAllBusinessObjectsOfUser(props.owner?.email);
      const isCreateTheFirstBoForUser = !allBoOfUser?.length;

      response = await businessObjectService.createBusinessObject(boToSubmit);

      if (response.id) {
        // after creating the first BO for user, need to toggle user role
        if (isCreateTheFirstBoForUser) {
          await adminService.toggleUserAuthorities(props.owner?.id);
        }

        setRedirectToBoDetail(getBoDetailPath(props.owner?.id, response.id));
      }
    }

    setBo(response);
    setBoToSubmit(response);

    setIsEmitMode(false);
    setIsSubmitting(false);
  };

  const classes = useStyles();

  return redirectToBoDetail ? (
    <Redirect to={redirectToBoDetail} />
  ) : (
    <>
      <GooglePlaceSearchDialogComponent
        isOpen={isOpenGooglePlaceSearchDialog}
        placeId={boToSubmit?.placeId}
        address={boToSubmit?.address}
        onClose={closeGooglePlaceSearchDialog}
        onSave={updateGooglePlaceData}
      ></GooglePlaceSearchDialogComponent>

      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Business object Info
          </Typography>

          {/*Edit button*/}
          {boToSubmit?.id && !isEmitMode ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              disabled={isSubmitting}
              onClick={() => {
                setIsEmitMode(true);
              }}
              className={classes.editButton}
            >
              Edit
            </Button>
          ) : (
            <></>
          )}
        </Toolbar>

        <form className={classes.form} onSubmit={submit}>
          {boToSubmit?.id ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/*ID*/}
                <TextField
                  variant="outlined"
                  margin="dense"
                  type="number"
                  fullWidth
                  key="id"
                  label="ID"
                  name="id"
                  value={boToSubmit?.id}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={6}>
                {/*urlName*/}
                <TextField
                  variant="outlined"
                  margin="dense"
                  type="text"
                  required
                  fullWidth
                  key="urlName"
                  label="Booking URL name"
                  name="urlName"
                  value={boToSubmit?.urlName}
                  disabled={true}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*Name*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                key="name"
                label="Name"
                name="name"
                value={boToSubmit?.name || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>

            <Grid item xs={6}>
              {/*SMS Sender ID*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                key="smsSenderId"
                label="SMS Sender ID"
                name="smsSenderId"
                value={boToSubmit?.smsSenderId || ''}
                disabled={!isEmitMode}
                inputProps={{ maxLength: 11 }}
                onChange={submitDataChangeHandler}
                helperText={<span>SMS Sender ID should contain only alphabet and spaces</span>}
              />
            </Grid>
          </Grid>

          {/*SMS checkout template*/}
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            required
            multiline
            fullWidth
            key="smsCheckoutTemplate"
            label="SMS checkout template"
            name="smsCheckoutTemplate"
            value={boToSubmit?.smsCheckoutTemplate || ''}
            disabled={!isEmitMode}
            onChange={submitDataChangeHandler}
            helperText={
              <span>
                Dynamic values in SMS checkout template:
                <ul>
                  <li>
                    <strong>{'{date}'}</strong> Checkout date
                  </li>
                  <li>
                    <strong>{'{p}'}</strong> Current available points of customer
                  </li>
                  <li>
                    <strong>{'{reviewUrl}'}</strong> Feedback link
                  </li>
                </ul>
              </span>
            }
          />

          <Grid container spacing={3}>
            <Grid item xs={12} lg={isEmitMode ? 6 : 12}>
              {/*Address*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                key="address"
                label="Address"
                name="address"
                value={boToSubmit?.address || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>
            {isEmitMode ? (
              <Grid item xs={12} lg={6}>
                <Button
                  className={classes.marginTopAndBottom}
                  onClick={openGooglePlaceSearchDialog}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchIcon />}
                >
                  Find google place
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              {/*Google Place ID*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                key="placeId"
                label="Google place ID"
                name="placeId"
                value={boToSubmit?.placeId || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                required
                fullWidth
                label="Geo Latitude"
                name="latitude"
                value={boToSubmit?.latitude || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                required
                fullWidth
                label="Geo Longitude"
                name="longitude"
                value={boToSubmit?.longitude || ''}
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*Locale*/}
              <TextField
                variant="outlined"
                margin="dense"
                select
                required
                fullWidth
                label="Locale"
                name="locale"
                value={boToSubmit?.locale || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
                placeholder="Please select locale"
              >
                {listOfLocale.map(locale => (
                  <MenuItem value={locale} key={locale}>
                    {locale}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {/*Currency*/}
              <TextField
                variant="outlined"
                margin="dense"
                select
                required
                fullWidth
                label="Currency"
                name="currency"
                value={boToSubmit?.currency || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
                placeholder="Please select currency"
              >
                {listOfCurrency.map(currency => (
                  <MenuItem value={currency} key={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*Telephone*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                key="telephone"
                label="Telephone"
                name="telephone"
                placeholder="eg. Mobile: +49176543210 or Festnetz: +49 631 543210"
                value={boToSubmit?.telephone || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>
            <Grid item xs={6}>
              {/*Website*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                fullWidth
                label="Website"
                name="onlineBookingDomain"
                value={boToSubmit?.onlineBookingDomain || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*Version*/}
              <TextField
                variant="outlined"
                margin="dense"
                select
                required
                fullWidth
                label="Version"
                name="version"
                value={boToSubmit?.version || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
                placeholder="Please select version"
              >
                {listOfVersion.map(version => (
                  <MenuItem value={version} key={version}>
                    {version}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {/*Conversion rate*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                required
                fullWidth
                label="Conversion rate"
                name="spendAmountToBonusPointConversionRate"
                value={boToSubmit?.spendAmountToBonusPointConversionRate || ''}
                InputProps={{
                  endAdornment: <InputAdornment position="end">point/1 {boToSubmit?.currency}</InputAdornment>,
                }}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>
          </Grid>

          <div className={classes.serviceTagLabel}>Service tags</div>
          {!isEmitMode && !boToSubmit?.serviceTagsList?.length ? <div>No service tags added</div> : <></>}

          {isEmitMode
            ? tagOptions?.map(tag => (
                <FormControlLabel
                  className={ServiceTagUtils.isTreatmentCategory({ tag }) ? classes.tagCategory : classes.tagItem}
                  control={
                    <Checkbox
                      checked={isServiceTagIncluded(tag)}
                      onChange={serviceTagChangeHandler}
                      key={tag}
                      name={tag}
                      color="primary"
                    />
                  }
                  key={tag}
                  label={tag}
                />
              ))
            : boToSubmit?.serviceTagsList?.map(st => (
                <Chip className={classes.marginRight} size="small" key={st} label={st} />
              ))}

          {/*Submit button*/}
          {isEmitMode ? (
            <Grid container spacing={3}>
              <Grid item xs={6} hidden={!boToSubmit?.id}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  disabled={isSubmitting}
                  onClick={reset}
                  startIcon={<Cancel />}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={boToSubmit?.id ? 6 : 12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting || isLoading}
                  startIcon={<Save />}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </form>
      </Paper>
    </>
  );
}
