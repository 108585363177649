import React from 'react';
import UserFormComponent from '../../components/user-form/user-form.component';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PRIVATE_FRAME_ROUTE } from '../../../../routing/private-frame-route-definition.model';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      marginBottom: '20px',
    },
  })
);

export default function UserCreateComponent(props: any) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>Create new user</div>

      <UserFormComponent />

      <Link to={`${PRIVATE_FRAME_ROUTE.USER}`}>Back to user table</Link>
    </div>
  );
}
