import React from 'react';
import ProductRoutingComponent from './routing/product-routing.component';
import { ProductCategory } from '../../../../models/interfaces/ProductCategory.interface';
import CategoryInfoCardComponent from '../category-context/components/category-info-card/category-info-card.component';

export default function ProductContextComponent(props: any) {
  const category: ProductCategory = props.category;

  return (
    <>
      <CategoryInfoCardComponent category={category}></CategoryInfoCardComponent>
      <ProductRoutingComponent {...props} />
    </>
  );
}
