export class UrlUtils {
  public static addParameterToUrl(originalUrl: string, parameterOption: any): string {
    let parameterPath = '';

    Object.keys(parameterOption).forEach(key => {
      if (key && parameterOption[key]) {
        parameterPath += parameterPath !== '' ? '&' : '';
        parameterPath += `${key}=${encodeURIComponent(parameterOption[key])}`;
      }
    });

    return parameterPath !== '' ? `${originalUrl}?${parameterPath}` : originalUrl;
  }
}
