import backendCallerService from './backend-caller.service';
import { TopUp } from '../models/interfaces/TopUp.interface';
import { BulkgateWalletInfoData } from '../models/interfaces/smsProviderInterfaces/BulkgateWalletInfoData.interface';
import { CREDIT_ORDER_STATUS } from '../models/enums/CreditOrderStatus.enum';
import { UrlUtils } from '../commons/utils/url.utils';
import { CommunicationUnitPrice } from '../models/interfaces/CommunicationUnitPrice.interface';
import { CommunicationUnitPackage } from '../models/interfaces/CommunicationUnitPackage.interface';
import { CreditOrder } from '../models/interfaces/CreditOrder.interface';
import { IsoCountryCodeWithPhoneCountryCode } from '../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { DateTimeUtils } from '../commons/utils/date-time.utils';
import { DATE_FORMAT } from '../models/enums/DateFormat.enum';
import { DailyCreditUsageStatistic } from '../models/interfaces/DailyCreditUsageStatistic.interface';
import { GatewayApiBalance } from '../models/interfaces/smsProviderInterfaces/GatewayApiBalance.interface';
import { GatewayApiCountryPriceResponse } from '../models/interfaces/smsProviderInterfaces/GatewayApiCountryPriceResponse.interface';

class SmsService {
  public async getAllCountryCodes(): Promise<IsoCountryCodeWithPhoneCountryCode[]> {
    const url = `/v1/sms/iso-codes`;

    const response = await backendCallerService.get(url);

    const data = response?.body || [];
    data.sort((a: IsoCountryCodeWithPhoneCountryCode, b: IsoCountryCodeWithPhoneCountryCode) => {
      try {
        const phoneCodeAsNumberA = Number(a.phoneCountryCode);
        const phoneCodeAsNumberB = Number(b.phoneCountryCode);

        if (phoneCodeAsNumberA < phoneCodeAsNumberB) {
          return -1;
        }

        if (phoneCodeAsNumberA > phoneCodeAsNumberB) {
          return 1;
        }

        return 0;
      } catch (e) {
        return a.phoneCountryCode.localeCompare(b.phoneCountryCode);
      }
    });

    return data;
  }

  public async topUp(ownerId: number, topUpData: TopUp): Promise<boolean> {
    const url = `/v1/business-object-owner/${ownerId}/budget`;

    const response = await backendCallerService.post(url, topUpData);

    return response?.status === 200;
  }

  public async getCreditBudgetOfUser(ownerId: number): Promise<number> {
    const url = `/v1/business-object-owner/${ownerId}/budget`;

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getBulkgateWalletInfo(): Promise<BulkgateWalletInfoData> {
    const url = `/v1/sms/bulkgate-wallet-info`;

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getGatewayApiBalance(): Promise<GatewayApiBalance> {
    const url = `/v1/gatewayapi/balance`;

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getGatewayApiPrices(countryCode: string[]): Promise<Map<string, number>> {
    const url = '/v1/gatewayapi/prices';

    const response = await backendCallerService.get(url);

    const mapOfCountryCodeAndPrice = new Map<string, number>();

    const priceList = (response?.body as GatewayApiCountryPriceResponse)?.premium;
    priceList?.forEach(price => {
      if (countryCode.includes(price.country.toUpperCase()) || countryCode.includes(price.country.toLowerCase())) {
        mapOfCountryCodeAndPrice.set(price.country.toUpperCase(), price.eur);
      }
    });

    return mapOfCountryCodeAndPrice;
  }

  public async getDailyCreditUsageStatistic(fromDate: Date, toDate: Date): Promise<DailyCreditUsageStatistic[]> {
    const fromDateAsString = DateTimeUtils.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND);
    const toDateAsString = DateTimeUtils.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND);

    const url = `/v1/business-object/0/sent-sms-statistics/from/${fromDateAsString}/to/${toDateAsString}`;

    const response = await backendCallerService.get(url);
    return response?.body || [];
  }

  public async createCreditOrderForUser(userId: number, creditOrder: CreditOrder): Promise<CreditOrder> {
    const url = `/v1/communication-unit-packages/users/${userId}/orders`;

    const response = await backendCallerService.post(url, creditOrder);

    return this.transformCreditOrderForFrontend(response?.body);
  }

  public async getCreditOrdersByStatus(creditOrderStatus: CREDIT_ORDER_STATUS): Promise<CreditOrder[]> {
    const url = UrlUtils.addParameterToUrl(`/v1/communication-unit-packages/orders`, { status: creditOrderStatus });

    const response = await backendCallerService.get(url);

    return this.transformCreditOrdersForFrontend(response?.body);
  }

  public async rejectCreditOrder(creditOrderId: number): Promise<boolean> {
    const url = `/v1/communication-unit-packages/orders/${creditOrderId}/rejection`;

    const response = await backendCallerService.post(url, {});

    return response?.status === 200;
  }

  public async fulfillCreditOrderAndDepositCredit(creditOrderId: number): Promise<boolean> {
    const url = `/v1/communication-unit-packages/orders/${creditOrderId}/bank-transfer-payment`;

    const response = await backendCallerService.patch(url, {});

    return response?.status === 200;
  }

  public async getAllCommunicationUnitPrices(): Promise<CommunicationUnitPrice[]> {
    const url = `/v1/communication-unit-prices`;

    const response = await backendCallerService.get(url);

    return this.transformCommunicationUnitPricesForFrontend(response?.body);
  }

  public async createCommunicationUnitPrice(unitPrice: CommunicationUnitPrice): Promise<CommunicationUnitPrice> {
    const url = '/v1/communication-unit-prices';

    const response = await backendCallerService.post(url, unitPrice);

    return response?.body;
  }

  public async updateCommunicationUnitPrice(unitPrice: CommunicationUnitPrice): Promise<boolean> {
    const url = `/v1/communication-unit-prices/${unitPrice.id}`;

    const response = await backendCallerService.patch(url, {
      price: unitPrice.price,
    } as CommunicationUnitPrice);

    return response?.status === 200;
  }

  public async deleteCommunicationUnitPrice(unitPrice: CommunicationUnitPrice): Promise<boolean> {
    const url = `/v1/communication-unit-prices/${unitPrice.id}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 204;
  }

  public async getAllCommunicationUnitPackages(): Promise<CommunicationUnitPackage[]> {
    const url = `/v1/communication-unit-packages`;

    const response = await backendCallerService.get(url);

    return this.transformCommunicationUnitPackagesForFrontend(response?.body);
  }

  public async createCommunicationUnitPackage(
    unitPackage: CommunicationUnitPackage
  ): Promise<CommunicationUnitPackage> {
    const url = '/v1/communication-unit-packages';

    const response = await backendCallerService.post(url, unitPackage);

    return response?.body;
  }

  public async updateCommunicationUnitPackage(unitPrice: CommunicationUnitPackage): Promise<boolean> {
    const url = `/v1/communication-unit-packages/${unitPrice.id}`;

    const response = await backendCallerService.patch(url, {
      balance: unitPrice.balance,
      price: unitPrice.price,
      smsPrice: unitPrice.smsPrice,
      emailPrice: unitPrice.emailPrice,
    } as CommunicationUnitPackage);

    return response?.status === 200;
  }

  public async deleteCommunicationUnitPackage(unitPrice: CommunicationUnitPackage): Promise<boolean> {
    const url = `/v1/communication-unit-packages/${unitPrice.id}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 204;
  }

  private transformCommunicationUnitPricesForFrontend(unitPrices: CommunicationUnitPrice[]): CommunicationUnitPrice[] {
    if (!unitPrices?.length) {
      return [];
    }

    return unitPrices.sort((a: CommunicationUnitPrice, b: CommunicationUnitPrice): number => {
      if (a.communicationWay < b.communicationWay) {
        return -1;
      }

      if (a.communicationWay > b.communicationWay) {
        return 1;
      }

      if (a.countryCode < b.countryCode) {
        return -1;
      }

      if (a.countryCode > b.countryCode) {
        return 1;
      }

      if (a.provider < b.provider) {
        return -1;
      }

      if (a.provider > b.provider) {
        return 1;
      }

      return 0;
    });
  }

  private transformCommunicationUnitPackagesForFrontend(
    unitPackage: CommunicationUnitPackage[]
  ): CommunicationUnitPackage[] {
    if (!unitPackage?.length) {
      return [];
    }

    return unitPackage.sort((a: CommunicationUnitPackage, b: CommunicationUnitPackage): number => {
      if (a.countryCode < b.countryCode) {
        return -1;
      }

      if (a.countryCode > b.countryCode) {
        return 1;
      }

      if (a.provider < b.provider) {
        return -1;
      }

      if (a.provider > b.provider) {
        return 1;
      }

      return 0;
    });
  }

  private transformCreditOrdersForFrontend(creditOrders: CreditOrder[]): CreditOrder[] {
    const result = creditOrders?.map(c => this.transformCreditOrderForFrontend(c));

    // sort by id in desc
    result.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    return result;
  }

  private transformCreditOrderForFrontend(creditOrder: CreditOrder): CreditOrder {
    if (!creditOrder) {
      return null;
    }

    return {
      ...creditOrder,
      createdAt: this.simplifyDateTime(creditOrder.createdAt),
      lastModified: this.simplifyDateTime(creditOrder.lastModified),
    };
  }

  private simplifyDateTime(date: string): string {
    if (!date) {
      return date;
    }

    const parts: string[] = date.split('.')[0].split('T');
    return parts?.join(' ');
  }
}

const smsService = new SmsService();

export default smsService;
