import React from 'react';
import { Switch } from 'react-router-dom';
import ScrollToTop from '../../../../../../../commons/utils/scroll-to-top';
import PrivateRoute from '../../../../../../../routes/private-route.component';
import UserDetailComponent from '../pages/user-detail/user-detail.component';
import { USER_DETAIL_ROUTE } from './user-detail-route-definition.model';
import { BusinessObjectContextComponent } from '../../../../business-object-context/business-object-context.component';

export default function UserDetailRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute
            {...props}
            path={USER_DETAIL_ROUTE.BUSINESS_OBJECT}
            component={BusinessObjectContextComponent}
          />
          <PrivateRoute {...props} component={UserDetailComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
