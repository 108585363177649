import { CreditPrice } from '../interfaces/CreditPrice.interface';

export const DEFAULT_CREDIT_PRICE: CreditPrice[] = [
  {
    credit: 3000,
    price: 30,
  },
  {
    credit: 5000,
    price: 40,
  },
  {
    credit: 10000,
    price: 70,
  },
  {
    credit: 21000,
    price: 130,
  },
  {
    credit: 43000,
    price: 250,
  },
  {
    credit: 105000,
    price: 590,
  },
  {
    credit: 205000,
    price: 1140,
  },
];
