import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../../../routes/private-route.component';
import ScrollToTop from '../../../../../commons/utils/scroll-to-top';

import { BUSINESS_OBJECT_ROUTE } from './business-object-route-definition.model';
import BusinessObjectCreateComponent from '../pages/business-object-create/business-object-create.component';
import BusinessObjectListComponent from '../pages/business-object-list/business-object-list.component';
import BusinessObjectDetailContextComponent from '../pages/business-object-detail-context/business-object-detail-context.component';

export default function BusinessObjectRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute
            {...props}
            path={BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_CREATE}
            component={BusinessObjectCreateComponent}
          />
          <PrivateRoute
            {...props}
            path={BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_DETAIL}
            component={BusinessObjectDetailContextComponent}
          />
          <PrivateRoute {...props} component={BusinessObjectListComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
