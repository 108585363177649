import { BusinessObjectStatistic } from '../models/interfaces/BusinessObjectStatistic.interface';
import { UrlUtils } from '../commons/utils/url.utils';
import backendCallerService from './backend-caller.service';
import { UserCreditBudget } from '../models/interfaces/UserCreditBudget.interface';
import { Authority } from '../models/interfaces/Authority.interface';
import { NOT_INTERESTED_AUTHORITIES } from '../models/consts/AuthorityRole.const';
import { LexofficeContact } from '../models/interfaces/LexofficeContact.interface';
import { LexofficeConnection } from '../models/interfaces/LexofficeConnection.interface';
import { LEXOFFICE_TAX_TYPE } from '../models/enums/LexofficeTaxType.enum';
import { LEXOFFICE_BILLING_LANGUAGE } from '../models/enums/LexofficeBillingLanguage.enum';
import { InvoiceJob } from '../models/interfaces/InvoiceJob.interface';

class AdminService {
  public async getBusinessObjectStatistic(from: string, to: string): Promise<BusinessObjectStatistic[]> {
    const url = UrlUtils.addParameterToUrl('/v1/admin/statistics', {
      from,
      to,
    });

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getBusinessObjectStatisticForBo(
    from: string,
    to: string,
    boId: number
  ): Promise<BusinessObjectStatistic> {
    const url = UrlUtils.addParameterToUrl(`/v1/business-object/${boId}/admin/statistics`, {
      from,
      to,
    });

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getUserCreditBudget(): Promise<UserCreditBudget> {
    const url = '/v1/admin/budget-report';

    const response = await backendCallerService.get(url);
    return response?.body;
  }

  public async getAllAuthorities(): Promise<Authority[]> {
    const url = '/v1/admin/authorities';
    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async getAuthoritiesOfUser(userId: number): Promise<Authority[]> {
    const url = `/v1/admin/users/${userId}/authorities`;
    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async updateAuthoritiesOfUser(userId: number, authoritiesIds: number[]) {
    const url = `/v1/admin/users/${userId}/authorities`;

    const currentAuthoritiesOfUser = await this.getAuthoritiesOfUser(userId);

    const newAuthoritiesToAdd = authoritiesIds?.filter(id => !currentAuthoritiesOfUser?.find(a => a.id === id));
    const oldAuthoritiesToDelete = currentAuthoritiesOfUser
      ?.filter(a => !authoritiesIds.includes(a.id) && !NOT_INTERESTED_AUTHORITIES.includes(a.authority))
      ?.map(a => a.id);

    if (!newAuthoritiesToAdd?.length && !oldAuthoritiesToDelete?.length) {
      return true;
    }

    const response = await backendCallerService.patch(url, {
      deleteAuthorityIds: oldAuthoritiesToDelete || undefined,
      addAuthorityIds: newAuthoritiesToAdd || undefined,
    });

    return response?.status;
  }

  // Workaround to update role in sms_business_object_owner Table
  // sms_business_object_owner will only sync role of user if user is owner of at least 1 BO
  // -> after create the first BO of user, need to call this function
  public async toggleUserAuthorities(userId: number) {
    const userAuthorities = await this.getAuthoritiesOfUser(userId);
    const currentAuthorityOfUser = userAuthorities?.filter(a => !NOT_INTERESTED_AUTHORITIES.includes(a.authority))?.[0];

    if (currentAuthorityOfUser.authority === 'USER') {
      // no need to sync
      return;
    }

    const allAuthorities = await this.getAllAuthorities();
    const listOfAuthorities = allAuthorities?.filter(a => !NOT_INTERESTED_AUTHORITIES.includes(a.authority));

    const tmpAuthority = currentAuthorityOfUser.authority === 'USER_LITE' ? 'USER_LITE_PLUS' : 'USER_LITE';
    const tmpAuthorityId = listOfAuthorities?.find(a => a.authority === tmpAuthority)?.id;

    // wait for 1s to avoid race condition
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.updateAuthoritiesOfUser(userId, [tmpAuthorityId]);

    // wait for 1s to avoid race condition
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.updateAuthoritiesOfUser(userId, [currentAuthorityOfUser?.id]);
  }

  // -------------------
  // LEXOFFICE Endpoints
  // -------------------
  public async fetchAllLexofficeContacts(): Promise<LexofficeContact[]> {
    const url = `/v1/admin/lexoffice-contacts`;
    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async fetchAllLexofficeConnections(): Promise<LexofficeConnection[]> {
    const url = `/v1/admin/user/lexoffice-connections`;
    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async createLexofficeConnection(
    userId: number,
    contact: LexofficeContact,
    taxType: LEXOFFICE_TAX_TYPE,
    language: LEXOFFICE_BILLING_LANGUAGE,
    businessObjectId?: number,
    businessObjectName?: string
  ): Promise<LexofficeConnection> {
    const url = UrlUtils.addParameterToUrl(`/v1/admin/user/${userId}/lexoffice-connection`, {
      taxType,
      language,
      businessObjectId,
      businessObjectName,
    });

    const response = await backendCallerService.post(url, contact);

    return response?.body;
  }

  public async updateLexofficeConnection(
    userId: number,
    connectionToUpdate: LexofficeConnection
  ): Promise<LexofficeConnection> {
    const url = `/v1/admin/user/${userId}/lexoffice-connection`;

    const response = await backendCallerService.put(url, connectionToUpdate);

    return response?.body;
  }

  public async deleteLexofficeConnection(userId: number, connectionId: number): Promise<boolean> {
    const url = `/v1/admin/user/${userId}/lexoffice-connection/${connectionId}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 200;
  }

  // -------------------
  // Invoice Job Endpoints
  // -------------------
  public async fetchAllInvoiceJobs(): Promise<InvoiceJob[]> {
    const url = `/v1/admin/invoice-jobs`;

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async fetchInvoiceJobById(invoiceJobId: number): Promise<InvoiceJob> {
    const url = `/v1/admin/invoice-job/${invoiceJobId}`;

    const response = await backendCallerService.get(url);

    return response?.body;
  }

  public async createInvoiceJob(invoiceJob: InvoiceJob): Promise<InvoiceJob> {
    const url = `/v1/admin/invoice-job`;

    const response = await backendCallerService.post(url, invoiceJob);

    return response?.body;
  }

  public async updateInvoiceJob(invoiceJob: InvoiceJob): Promise<InvoiceJob> {
    const url = `/v1/admin/invoice-job`;

    const response = await backendCallerService.put(url, invoiceJob);

    return response?.body;
  }

  public async deleteInvoiceJobById(invoiceJobId: number): Promise<boolean> {
    const url = `/v1/admin/invoice-job/${invoiceJobId}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 200;
  }
}

const adminService = new AdminService();
export default adminService;
