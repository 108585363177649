import React from 'react';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { EurPriceOfProvider } from './interfaces/EurPriceOfProvider.interface';
import { CreditPriceOfProvider } from './interfaces/CreditPriceOfProvider.interface';
import { CreditPriceCalculatorUtils } from './utils/credit-price-calculator.utils';
import { ContinentData } from './interfaces/ContinentData.interface';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { ContinentDataUtils } from './utils/continent-data.utils';
import smsService from '../../../../../../services/sms.service';
import { COMMUNICATION_WAY } from '../../../../../../models/enums/CommunicationWay.enum';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { SMS_PROVIDER } from '../../../../../../models/enums/SmsProvider.enum';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CountryData } from './interfaces/CountryData.interface';
import { NumberUtils } from '../../../../../../commons/utils/number.utils';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 'bold',
  },
  dashedBorderBottom: {
    borderBottom: '1px dashed lightgrey',
  },
}));

interface QuickCreateUpdatePackageStep3Props {
  mapOfIsoCodeAndEurPriceOfProvider: Map<string, EurPriceOfProvider[]>;
  selectedIsoCodes: string[];
  isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[];
  isLoading: boolean;
  onSubmit: Function;
  onBack: Function;
}

interface ProviderPriceInCreditRowProps {
  countryData: CountryData;
  newPrice: CreditPriceOfProvider[];
  currentPrice: CreditPriceOfProvider[];
}

const LIST_OF_SMS_PROVIDERS: string[] = convertEnumToArray(SMS_PROVIDER);

const calculateColumnWidth = (isFirstColumn: boolean): GridSize => {
  const roundedWidth = Math.floor(12 / (LIST_OF_SMS_PROVIDERS.length + 1));

  if (isFirstColumn) {
    return (12 - roundedWidth * LIST_OF_SMS_PROVIDERS.length) as GridSize;
  } else {
    return roundedWidth as GridSize;
  }
};

function ProviderPriceInCreditRow(props: ProviderPriceInCreditRowProps) {
  const getCurrentPrice = (provider: SMS_PROVIDER | string): number => {
    return props?.currentPrice?.find(p => p.provider === provider)?.creditPerSms;
  };

  const getNewPrice = (provider: SMS_PROVIDER | string): number => {
    return props?.newPrice?.find(p => p.provider === provider)?.creditPerSms;
  };

  const isPriceIncreased = (provider: SMS_PROVIDER | string): boolean => {
    const newPrice = getNewPrice(provider);
    const currentPrice = getCurrentPrice(provider);

    if (!newPrice || newPrice < 0) {
      return false;
    }

    if (!currentPrice || currentPrice < 0) {
      return false;
    }

    return newPrice > currentPrice;
  };

  const isPriceDecreased = (provider: SMS_PROVIDER | string): boolean => {
    const newPrice = getNewPrice(provider);
    const currentPrice = getCurrentPrice(provider);

    if (!newPrice || newPrice < 0) {
      return false;
    }

    if (!currentPrice || currentPrice < 0) {
      return false;
    }

    return newPrice < currentPrice;
  };

  const getBackgroundColor = (provider: SMS_PROVIDER | string): string => {
    if (isPriceDecreased(provider)) {
      return 'limegreen';
    }

    if (isPriceIncreased(provider)) {
      return 'palevioletred';
    }

    return 'transparent';
  };

  const classes = useStyles();

  return (
    <>
      <Grid item xs={calculateColumnWidth(true)} className={classes.dashedBorderBottom}>
        <div>{props?.countryData?.info?.country}</div>
        <div>
          [{props?.countryData?.phoneData?.isoCode}] [{props?.countryData?.phoneData?.phoneCountryCode}]
        </div>
      </Grid>
      {LIST_OF_SMS_PROVIDERS.map(p => (
        <Grid
          item
          xs={calculateColumnWidth(false)}
          key={p + '-price-changed'}
          className={classes.dashedBorderBottom}
          style={{ backgroundColor: getBackgroundColor(p) }}
        >
          {isPriceIncreased(p) || isPriceDecreased(p) ? (
            <div>
              <div style={{ textDecoration: 'line-through' }}>{getCurrentPrice(p)}</div>
              <div>{getNewPrice(p)}</div>
            </div>
          ) : (
            <div>{getCurrentPrice(p) || getNewPrice(p) || '-'}</div>
          )}
        </Grid>
      ))}
    </>
  );
}

export default function QuickCreateUpdatePackageStep3(props: QuickCreateUpdatePackageStep3Props) {
  const [continents, setContinents] = React.useState<ContinentData[]>([]);
  const [mapOfIsoCodeAndNewProviderPrice, setMapOfIcoCodeAndNewProviderPrice] = React.useState<
    Map<string, CreditPriceOfProvider[]>
  >(new Map<string, CreditPriceOfProvider[]>());
  const [mapOfIsoCodeAndCurrentProviderPrice, setMapOfIcoCodeAndCurrentProviderPrice] = React.useState<
    Map<string, CreditPriceOfProvider[]>
  >(new Map<string, CreditPriceOfProvider[]>());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const initCurrentPriceMapData = async () => {
    const tmpMap = new Map<string, CreditPriceOfProvider[]>();

    const data = await smsService.getAllCommunicationUnitPrices();
    data
      ?.filter(d => d.communicationWay === COMMUNICATION_WAY.SMS)
      ?.forEach(d => {
        const creditPriceData = {
          provider: d.provider,
          creditPerSms: d.price,
        };

        if (!tmpMap.has(d.countryCode)) {
          tmpMap.set(d.countryCode, [creditPriceData]);
        } else {
          tmpMap.get(d.countryCode).push(creditPriceData);
        }
      });

    setMapOfIcoCodeAndCurrentProviderPrice(tmpMap);
  };

  const initNewPriceMapData = (eurPriceData: Map<string, EurPriceOfProvider[]>) => {
    if (!eurPriceData) {
      return;
    }

    const tmpMap = new Map<string, CreditPriceOfProvider[]>();
    eurPriceData.forEach((value, key) => {
      const creditPriceOfProvider: CreditPriceOfProvider[] = value?.map(v => ({
        provider: v.provider,
        creditPerSms: CreditPriceCalculatorUtils.calculateCreditPricePerSms(v.eurPricePerSms),
      }));

      tmpMap.set(key, creditPriceOfProvider);
    });

    setMapOfIcoCodeAndNewProviderPrice(tmpMap);
    setIsLoading(false);
  };

  const initContinentData = async (
    selectedIsoCodes: string[],
    isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[]
  ) => {
    const tmpContinents = ContinentDataUtils.generateContinentData(
      isoCodeWithCountryCodes?.filter(i => selectedIsoCodes.includes(i.isoCode))
    );

    setContinents(tmpContinents);
  };

  const initData = async () => {
    setIsLoading(true);

    initContinentData(props.selectedIsoCodes, props.isoCodeWithCountryCodes);
    initNewPriceMapData(props.mapOfIsoCodeAndEurPriceOfProvider);
    await initCurrentPriceMapData();

    setIsLoading(false);
  };

  React.useEffect(() => {
    initData();
  }, [props]);

  const isPriceChanged = (newPrice: number, oldPrice: number): boolean => {
    if (!newPrice || newPrice < 0) {
      return false;
    }

    if (!oldPrice || oldPrice < 0) {
      return true;
    }

    return !NumberUtils.isSameDecimalNumber(newPrice, oldPrice);
  };

  const submitHandler = (event: any) => {
    event.preventDefault();

    const tmpMap = new Map<string, CreditPriceOfProvider[]>();

    mapOfIsoCodeAndNewProviderPrice.forEach((value, key) => {
      const oldData = mapOfIsoCodeAndCurrentProviderPrice.get(key);

      const finalDataToSubmit: CreditPriceOfProvider[] = [];

      LIST_OF_SMS_PROVIDERS?.forEach(provider => {
        const newProviderPrice = value?.find(p => p.provider === provider)?.creditPerSms;
        const oldProviderPrice = oldData?.find(p => p.provider === provider)?.creditPerSms;

        if (isPriceChanged(newProviderPrice, oldProviderPrice)) {
          finalDataToSubmit.push({
            provider: provider as SMS_PROVIDER,
            creditPerSms: newProviderPrice,
          });
        }
      });

      if (finalDataToSubmit?.length) {
        tmpMap.set(key, finalDataToSubmit);
      }
    });

    props.onSubmit(tmpMap);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={calculateColumnWidth(true)} className={classes.boldText + ' ' + classes.dashedBorderBottom}>
          Country
        </Grid>
        {LIST_OF_SMS_PROVIDERS.map(p => (
          <Grid
            item
            xs={calculateColumnWidth(false)}
            key={p + '-title'}
            className={classes.boldText + ' ' + classes.dashedBorderBottom}
          >
            {p}
          </Grid>
        ))}

        {continents?.map(continent => (
          <>
            <Grid item xs={calculateColumnWidth(true)} className={classes.boldText}>
              {continent.name}
            </Grid>
            {LIST_OF_SMS_PROVIDERS.map(p => (
              <Grid item xs={calculateColumnWidth(false)} key={p + '-empty'}></Grid>
            ))}

            {continent.countries.map(countryData => (
              <ProviderPriceInCreditRow
                key={countryData.phoneData?.isoCode}
                currentPrice={mapOfIsoCodeAndCurrentProviderPrice.get(countryData?.phoneData?.isoCode)}
                newPrice={mapOfIsoCodeAndNewProviderPrice.get(countryData?.phoneData?.isoCode)}
                countryData={countryData}
              ></ProviderPriceInCreditRow>
            ))}
          </>
        ))}
      </Grid>

      {isLoading || props.isLoading ? <LinearProgress /> : <></>}

      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={isLoading || props.isLoading} onClick={() => props.onBack()}>
            Back
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading || props.isLoading}
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
