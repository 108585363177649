import React from 'react';
import smsService from '../../../../services/sms.service';
import CreditPackageTableComponent from './components/credit-package-table/credit-package-table.component';
import { SMS_PROVIDER } from '../../../../models/enums/SmsProvider.enum';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ProviderOption } from '../../../../models/interfaces/ProviderOption.interface';
import { DEFAULT_PROVIDER_OPTIONS } from '../../../../models/consts/DefaultProviderOptions.const';
import logger from '../../../../commons/logger';
import CreditPackageDialogComponent from './components/credit-package-dialog/credit-package-dialog.component';
import { CommunicationUnitPackage } from '../../../../models/interfaces/CommunicationUnitPackage.interface';
import { CommunicationUnitPrice } from '../../../../models/interfaces/CommunicationUnitPrice.interface';
import CommunicationUnitDialogComponent from './components/communication-unit-dialog/communication-unit-dialog.component';
import CommunicationUnitTableComponent from './components/communication-unit-table/communication-unit-table.component';
import QuickCreateUpdatePackageDialogComponent from './components/quick-create-update-package-dialog/quick-create-update-package-dialog.component';
import { NumberUtils } from '../../../../commons/utils/number.utils';
import { COMMUNICATION_WAY } from '../../../../models/enums/CommunicationWay.enum';
import { DEFAULT_CREDIT_PRICE } from '../../../../models/consts/DefaultCreditPrice.const';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { CreditPriceOfProvider } from './components/quick-create-update-package-dialog/interfaces/CreditPriceOfProvider.interface';
import { CreditPriceCalculatorUtils } from './components/quick-create-update-package-dialog/utils/credit-price-calculator.utils';

const useStyles = makeStyles(theme => ({
  filterForm: {
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
}));

interface CreditPackageFilterForm {
  countryCode: string | 'all';
  provider: SMS_PROVIDER | 'all';
}

const providerOptions: ProviderOption[] = [
  {
    value: 'all',
    label: 'All providers',
  },
  ...DEFAULT_PROVIDER_OPTIONS,
];

export default function CreditPackageManagementComponent(props: any) {
  const [countryCodeOptions, setCountryCodeOptions] = React.useState<IsoCountryCodeWithPhoneCountryCode[]>([]);

  const [creditPackages, setCreditPackages] = React.useState<CommunicationUnitPackage[]>([]);
  const [filteredCreditPackages, setFilteredCreditPackages] = React.useState<CommunicationUnitPackage[]>([]);

  const [unitPrices, setUnitPrices] = React.useState<CommunicationUnitPrice[]>([]);
  const [filteredUnitPrices, setFilteredUnitPrices] = React.useState<CommunicationUnitPrice[]>([]);

  const [filterForm, setFilterForm] = React.useState<CreditPackageFilterForm>({ countryCode: 'all', provider: 'all' });

  const [selectedCreditPackage, setSelectedCreditPackage] = React.useState<CommunicationUnitPackage>(null);
  const [isOpenEditPackageDialog, setIsOpenEditPackageDialog] = React.useState<boolean>(false);

  const [selectedUnitPrice, setSelectedUnitPrice] = React.useState<CommunicationUnitPrice>(null);
  const [isOpenEditUnitPriceDialog, setIsOpenEditUnitPriceDialog] = React.useState<boolean>(false);

  const [isOpenQuickCreateUpdatePackageDialog, setIsOpenQuickCreateUpdatePackageDialog] =
    React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchIsoCodeWithCountryCodes = async () => {
    const response = await smsService.getAllCountryCodes();
    setCountryCodeOptions([{ isoCode: 'all' }, ...response]);
  };

  const fetchAllCreditPackages = async () => {
    const data = await smsService.getAllCommunicationUnitPackages();
    setCreditPackages(data);
  };

  const fetchAllCommunicationUnitPrices = async () => {
    const data = await smsService.getAllCommunicationUnitPrices();
    setUnitPrices(data);
  };

  // use this syntax to trigger function on init
  React.useEffect(() => {
    fetchIsoCodeWithCountryCodes();
    fetchAllCreditPackages();
    fetchAllCommunicationUnitPrices();
  }, []);

  // use this syntax to watch change of creditPackages, unitPrices and filterForm
  React.useEffect(() => {
    updateFilteredCreditPackages();
  }, [creditPackages, unitPrices, filterForm]);

  const filterFormChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    // @ts-ignore
    setFilterForm({
      ...filterForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const updateFilteredCreditPackages = () => {
    let tmpFilteredCreditPackages = [...creditPackages];
    let tmpFilteredUnitPrices = [...unitPrices];

    if (!!filterForm.countryCode && filterForm.countryCode !== 'all') {
      tmpFilteredCreditPackages = tmpFilteredCreditPackages?.filter(p => p.countryCode === filterForm.countryCode);
      tmpFilteredUnitPrices = tmpFilteredUnitPrices?.filter(p => p.countryCode === filterForm.countryCode);
    }

    if (!!filterForm.provider && filterForm.provider !== 'all') {
      tmpFilteredCreditPackages = tmpFilteredCreditPackages?.filter(p => p.provider === filterForm.provider);
      tmpFilteredUnitPrices = tmpFilteredUnitPrices?.filter(p => p.provider === filterForm.provider);
    }

    setFilteredCreditPackages(tmpFilteredCreditPackages);
    setFilteredUnitPrices(tmpFilteredUnitPrices);
  };

  const openQuickCreateUpdatePackageDialog = () => {
    setIsOpenQuickCreateUpdatePackageDialog(true);
  };

  const openCreditPackageDialog = () => {
    setIsOpenEditPackageDialog(true);
  };

  const openUnitPriceDialog = () => {
    setIsOpenEditUnitPriceDialog(true);
  };

  const onSelectCreditPackage = (creditPackage: CommunicationUnitPackage) => {
    setSelectedCreditPackage(creditPackage);
    openCreditPackageDialog();
  };

  const onSelectUnitPrice = (unitPrice: CommunicationUnitPrice) => {
    setSelectedUnitPrice(unitPrice);
    openUnitPriceDialog();
  };

  const closeQuickCreateUpdatePackageDialog = () => {
    if (isLoading) {
      return;
    }

    setIsOpenQuickCreateUpdatePackageDialog(false);
  };

  const closeEditPackageDialog = () => {
    if (isLoading) {
      return;
    }

    setSelectedCreditPackage(null);
    setIsOpenEditPackageDialog(false);
  };

  const closeEditUnitPriceDialog = () => {
    if (isLoading) {
      return;
    }

    setSelectedUnitPrice(null);
    setIsOpenEditUnitPriceDialog(false);
  };

  const updateCreditPackage = async (creditPackage: CommunicationUnitPackage) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.updateCommunicationUnitPackage(creditPackage);

      if (isSuccess) {
        await fetchAllCreditPackages();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditPackageDialog();
    }
  };

  const deleteCreditPackage = async (creditPackage: CommunicationUnitPackage) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.deleteCommunicationUnitPackage(creditPackage);

      if (isSuccess) {
        await fetchAllCreditPackages();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditPackageDialog();
    }
  };

  const addNewCreditPackage = async (creditPackage: CommunicationUnitPackage) => {
    setIsLoading(true);

    try {
      const addedPackage = await smsService.createCommunicationUnitPackage(creditPackage);

      if (addedPackage?.id) {
        await fetchAllCreditPackages();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditPackageDialog();
    }
  };

  const updateUnitPrice = async (unitPrice: CommunicationUnitPrice) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.updateCommunicationUnitPrice(unitPrice);

      if (isSuccess) {
        await fetchAllCommunicationUnitPrices();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditUnitPriceDialog();
    }
  };

  const deleteUnitPrice = async (unitPrice: CommunicationUnitPrice) => {
    setIsLoading(true);

    try {
      const isSuccess = await smsService.deleteCommunicationUnitPrice(unitPrice);

      if (isSuccess) {
        await fetchAllCommunicationUnitPrices();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditUnitPriceDialog();
    }
  };

  const addNewUnitPrice = async (unitPrice: CommunicationUnitPrice) => {
    setIsLoading(true);

    try {
      const addedPackage = await smsService.createCommunicationUnitPrice(unitPrice);

      if (addedPackage?.id) {
        await fetchAllCommunicationUnitPrices();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeEditUnitPriceDialog();
    }
  };

  const quickCreateUpdateSmsPackagesForACountry = async (
    countryCode: string,
    providerAndCreditPerSms: CreditPriceOfProvider[],
    currentUnitPrice: CommunicationUnitPrice[],
    currentUnitPackage: CommunicationUnitPackage[]
  ) => {
    try {
      // 1. Create/Update SMS Unit price
      // 1.1. Generate list of unit prices to update
      const listOfUnitPriceToUpdate: CommunicationUnitPrice[] = currentUnitPrice
        ?.map(unitPrice => {
          if (unitPrice.countryCode === countryCode && unitPrice.communicationWay === COMMUNICATION_WAY.SMS) {
            const newCreditPrice = providerAndCreditPerSms?.find(p => p.provider === unitPrice.provider)?.creditPerSms;
            if (NumberUtils.isSameDecimalNumber(newCreditPrice, unitPrice.price)) {
              return null;
            } else {
              return {
                ...unitPrice,
                price: newCreditPrice,
              };
            }
          } else {
            return null;
          }
        })
        ?.filter(p => !!p);

      // 1.2. Update unit prices
      if (listOfUnitPriceToUpdate?.length) {
        await Promise.all(listOfUnitPriceToUpdate?.map(p => smsService.updateCommunicationUnitPrice(p)));
      }

      // 1.3. Generate list of unit prices to create
      const listOfUnitPriceToCreate: CommunicationUnitPrice[] = providerAndCreditPerSms
        ?.map(p => ({
          countryCode,
          provider: p.provider,
          communicationWay: COMMUNICATION_WAY.SMS,
          price: p.creditPerSms,
        }))
        ?.filter(
          p =>
            !currentUnitPrice?.find(
              unitPrice =>
                unitPrice.countryCode === countryCode &&
                unitPrice.communicationWay === COMMUNICATION_WAY.SMS &&
                unitPrice.provider === p.provider
            )
        );

      // 1.4. Create unit prices
      if (listOfUnitPriceToCreate?.length) {
        await Promise.all(listOfUnitPriceToCreate?.map(p => smsService.createCommunicationUnitPrice(p)));
      }

      // 2. Create/Update Credit package
      // 2.1. Generate list of unit packages to update
      const listOfUnitPackageToUpdate: CommunicationUnitPackage[] = currentUnitPackage
        ?.map(unitPackage => {
          if (unitPackage.countryCode === countryCode) {
            const pricePerCredit = unitPackage.price / unitPackage.balance;
            const newCreditPrice = providerAndCreditPerSms?.find(
              p => p.provider === unitPackage.provider
            )?.creditPerSms;
            const newSmsPrice = NumberUtils.roundUpNumber(pricePerCredit * newCreditPrice, 4);

            if (NumberUtils.isSameDecimalNumber(newSmsPrice, unitPackage.smsPrice)) {
              return null;
            } else {
              return {
                ...unitPackage,
                smsPrice: newSmsPrice,
              };
            }
          } else {
            return null;
          }
        })
        ?.filter(p => !!p);

      // 2.2. Update unit packages
      if (listOfUnitPackageToUpdate?.length) {
        await Promise.all(listOfUnitPackageToUpdate?.map(p => smsService.updateCommunicationUnitPackage(p)));
      }

      // 2.3. Generate list of unit packages to create
      const listOfUnitPackageToCreate: CommunicationUnitPackage[] = [];
      providerAndCreditPerSms?.forEach(providerSmsPrice => {
        DEFAULT_CREDIT_PRICE?.forEach(creditPackagePrice => {
          const pricePerCredit = creditPackagePrice.price / creditPackagePrice.credit;
          const currentProviderSmsPrice = currentUnitPackage?.find(
            p =>
              p.countryCode === countryCode &&
              p.provider === providerSmsPrice.provider &&
              NumberUtils.isSameDecimalNumber(p.balance, creditPackagePrice.credit)
          );
          if (!currentProviderSmsPrice) {
            listOfUnitPackageToCreate.push({
              countryCode,
              provider: providerSmsPrice.provider,
              balance: creditPackagePrice.credit,
              price: creditPackagePrice.price,
              smsPrice: pricePerCredit * providerSmsPrice.creditPerSms,
              emailPrice: pricePerCredit * CreditPriceCalculatorUtils.calculateCreditPricePerEmail(),
            });
          }
        });
      });

      // 2.4. Create unit packages
      if (listOfUnitPackageToCreate?.length) {
        await Promise.all(listOfUnitPackageToCreate?.map(p => smsService.createCommunicationUnitPackage(p)));
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const quickCreateUpdateSmsPackages = async (data: Map<string, CreditPriceOfProvider[]>) => {
    setIsLoading(true);

    try {
      // Fetch current unit prices
      const currentUnitPrice = await smsService.getAllCommunicationUnitPrices();
      // Fetch current unit packages
      const currentUnitPackage = await smsService.getAllCommunicationUnitPackages();

      const promises: any[] = [];

      data?.forEach((value, key) => {
        promises.push(quickCreateUpdateSmsPackagesForACountry(key, value, currentUnitPrice, currentUnitPackage));
      });

      await Promise.all(promises);

      // Update tables
      await fetchAllCommunicationUnitPrices();
      await fetchAllCreditPackages();
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeQuickCreateUpdatePackageDialog();
    }
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.filterForm}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            fullWidth
            label="Locale"
            name="countryCode"
            value={filterForm?.countryCode || 'all'}
            onChange={filterFormChangeHandler}
          >
            {countryCodeOptions.map(item => (
              <MenuItem value={item.isoCode} key={item.isoCode}>
                {item.isoCode} {item.phoneCountryCode ? `(${item.phoneCountryCode})` : ''}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            fullWidth
            label="Provider"
            name="provider"
            value={filterForm?.provider || 'all'}
            onChange={filterFormChangeHandler}
          >
            {providerOptions.map(provider => (
              <MenuItem value={provider.value} key={provider.value}>
                {provider.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <QuickCreateUpdatePackageDialogComponent
        isOpen={isOpenQuickCreateUpdatePackageDialog}
        isLoading={isLoading}
        onClose={closeQuickCreateUpdatePackageDialog}
        onSubmit={quickCreateUpdateSmsPackages}
      ></QuickCreateUpdatePackageDialogComponent>

      <Button
        onClick={openQuickCreateUpdatePackageDialog}
        fullWidth
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
      >
        Quick update/create packages
      </Button>

      <div className={classes.marginTop}></div>

      <CreditPackageDialogComponent
        isOpen={isOpenEditPackageDialog}
        isLoading={isLoading}
        creditPackage={selectedCreditPackage}
        onClose={closeEditPackageDialog}
        onUpdate={updateCreditPackage}
        onDelete={deleteCreditPackage}
        onCreate={addNewCreditPackage}
      ></CreditPackageDialogComponent>

      <CreditPackageTableComponent
        creditPackages={filteredCreditPackages}
        onSelect={onSelectCreditPackage}
      ></CreditPackageTableComponent>

      <Button onClick={openCreditPackageDialog} fullWidth variant="contained" color="primary" startIcon={<AddIcon />}>
        Add new package
      </Button>

      <CommunicationUnitDialogComponent
        isOpen={isOpenEditUnitPriceDialog}
        isLoading={isLoading}
        communicationUnitPrice={selectedUnitPrice}
        onClose={closeEditUnitPriceDialog}
        onUpdate={updateUnitPrice}
        onDelete={deleteUnitPrice}
        onCreate={addNewUnitPrice}
      ></CommunicationUnitDialogComponent>

      <div className={classes.marginTop}></div>

      <CommunicationUnitTableComponent
        unitPrices={filteredUnitPrices}
        onSelect={onSelectUnitPrice}
      ></CommunicationUnitTableComponent>

      <Button onClick={openUnitPriceDialog} fullWidth variant="contained" color="primary" startIcon={<AddIcon />}>
        Add new communication unit price
      </Button>
    </>
  );
}
