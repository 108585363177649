import ENV from './environment';

class Logger {
  private static readonly logLevelDefinition = ['info', 'log', 'debug', 'error'];
  private static logLevel: string;

  constructor(logLevel: string) {
    if (!logLevel || Logger.logLevelDefinition.indexOf(logLevel) < 0) {
      Logger.logLevel = 'error';
    } else {
      Logger.logLevel = logLevel;
    }
  }

  public info(...args: any[]): void {
    if (args && Logger.shouldBeExecuted('info')) {
      console.info(args.join(' '));
    }
  }

  public log(...args: any[]): void {
    if (args && Logger.shouldBeExecuted('log')) {
      console.log(args.join(' '));
    }
  }

  public debug(...args: any[]): void {
    if (args && Logger.shouldBeExecuted('debug')) {
      console.debug(args.join(' '));
    }
  }

  public error(...args: any[]): void {
    if (args && Logger.shouldBeExecuted('error')) {
      console.error(args.join(' '));
    }
  }

  public get level(): string {
    return Logger.logLevel;
  }

  private static shouldBeExecuted(level: string): boolean {
    return Logger.getLogLevelOf(level) >= Logger.getCurrentLogLevel();
  }

  private static getLogLevelOf(level: string): number {
    return Logger.logLevelDefinition.indexOf(level);
  }

  private static getCurrentLogLevel(): number {
    return Logger.getLogLevelOf(Logger.logLevel);
  }
}

const logger = new Logger(ENV.LOG_LEVEL as string);

export default logger;
