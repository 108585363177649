import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Save } from '@material-ui/icons';
import BackupIcon from '@material-ui/icons/Backup';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import logger from '../../../../commons/logger';
import countries from '../../../../models/static/countries.json';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../services/business-object.service';
import { StringUtils } from '../../../../commons/utils/string.util';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    flexDirection: 'column',
    padding: 0,
    alignItems: 'flex-start',
  },
  title: {
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 'bold',
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  optionDescription: {
    marginLeft: '5px',
    fontSize: 'small',
    color: 'grey',
  },
}));

enum HEADER {
  NAME = 'Name',
  VORNAME = 'Vorname',
  NACHNAME = 'Nachname',
  TELEFON = 'Telefon',
  EMAIL = 'Email',
  GEBURTSDATUM = 'Geburtsdatum',
  GEBURTSMONAT = 'Geburtsmonat',
  GEBURTSTAG = 'Geburstag',
}

const HEADERS = [
  HEADER.NAME,
  HEADER.VORNAME,
  HEADER.NACHNAME,
  HEADER.TELEFON,
  HEADER.EMAIL,
  HEADER.GEBURTSDATUM,
  HEADER.GEBURTSMONAT,
  HEADER.GEBURTSTAG,
];

const DEFAULT_NAME = 'Kunde';

export default function ImportCustomerCsvComponent(props: any) {
  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [selectedBusinessObject, setSelectedBusinessObject] = React.useState<BusinessObject>(null);
  const [customerCsvToImport, setCustomerCsvToImport] = React.useState<File>(null);
  const [isImportingFile, setIsImportingFile] = React.useState<boolean>(false);

  const [isFormattingFile, setIsFormattingFile] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getAllBusinessObjects = async () => {
      const availableBo = await businessObjectService.getAllBusinessObjects();
      setBusinessObjects(availableBo.sort((a, b) => -b.owner.localeCompare(a.owner)));
    };

    getAllBusinessObjects();
  }, []);

  const objectsToCsv = (objects: any, headers: string[], delimiter = ',') => {
    const row = headers.join(delimiter);

    //append Label row with line break
    let str = row + '\r\n';

    for (let i = 0; i < objects.length; i++) {
      let line = '';

      headers?.forEach(header => {
        if (line !== '') line += delimiter;

        line += objects[i][header] || '';
      });

      str += line + '\r\n';
    }
    return str;
  };

  const csvToObjects = (csv: string, delimiter = ',') => {
    const lines: string[] = csv.split('\n');

    const result = [];

    const headers: string[] = lines[0].split(delimiter);

    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      const currentLine: string[] = lines[i].split(delimiter);

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = currentLine[j]?.trim();
      }

      result.push(obj);
    }

    return result;
  };

  const readFileContent = (file: any) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event?.target?.result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  };

  const exportAsCsv = (objects: any, filename: string) => {
    const csvData = objectsToCsv(objects, HEADERS);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  };

  const getParsedNumber = (phoneNumber: string) => {
    const countryCodeList = Object.keys(countries);
    const phoneUtil = PhoneNumberUtil.getInstance();

    const matchedCodes = countryCodeList.filter(countryCode => {
      try {
        return phoneUtil.isValidNumberForRegion(phoneUtil.parse(phoneNumber, countryCode), countryCode);
      } catch (e) {
        return false;
      }
    });

    const matchedCodesWithMatchedProvider = matchedCodes?.filter(countryCode => {
      try {
        // @ts-ignore
        const matchedCountry: any = countries?.[countryCode];

        const providerCode = matchedCountry.providerCode;
        if (!providerCode?.length) {
          return false;
        }

        const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
        const e164Format = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);

        const matchedProviderCode = providerCode.find((code: any) => {
          return e164Format.indexOf(`+${matchedCountry.countryPrefix}${code}`) >= 0;
        });

        return !!matchedProviderCode;
      } catch (e) {
        return false;
      }
    });

    const matchedCode = matchedCodesWithMatchedProvider?.length ? matchedCodesWithMatchedProvider?.[0] : null;
    if (!matchedCode) {
      logger.debug(`matchedCodes=${JSON.stringify(matchedCodes)}`);
      logger.debug(`matchedCodesWithMatchedProvider=${JSON.stringify(matchedCodesWithMatchedProvider)}`);
    }

    return phoneUtil.parseAndKeepRawInput(phoneNumber, matchedCode);
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const parsedNumber = getParsedNumber(phoneNumber);

      return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
    } catch (err) {
      logger.error(`Parsing "${phoneNumber}" error: `, err);
      return null;
    }
  };

  const formatEmail = (email: string) => {
    if (!email?.length) {
      return null;
    }

    const simplifiedEmail = email?.replace(/"/g, '');

    const re = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,24}$/;
    return re.test(String(simplifiedEmail).toLowerCase()) ? simplifiedEmail : null;
  };

  const formatObjectField = (object: any, field: string) => {
    if (field === HEADER.TELEFON) {
      return formatPhoneNumber(object[field]);
    } else if (field === HEADER.VORNAME) {
      if (!object[HEADER.NAME]) {
        return null;
      } else if (!object[field]) {
        const nameArr = object[HEADER.NAME].split(' ');
        if (nameArr.length < 2) {
          return null;
        }
        const nameArrWithOutLastItem = nameArr.slice(0, nameArr.length - 1);
        return nameArrWithOutLastItem.join(' ');
      } else {
        return object[field];
      }
    } else if (field === HEADER.NACHNAME) {
      if (!object[HEADER.NAME]) {
        return DEFAULT_NAME;
      } else if (!object[field]) {
        const nameArr = object[HEADER.NAME].split(' ');
        return nameArr[nameArr?.length - 1];
      } else {
        return object[field];
      }
    } else if (field === HEADER.EMAIL) {
      return formatEmail(object[field]);
    }
    return object[field];
  };

  const formatObject = (object: any) => {
    HEADERS.forEach(header => {
      object[header] = formatObjectField(object, header);
    });
    return object;
  };

  const filterInvalidObj = (objects: any) => {
    return objects?.map((obj: any) => formatObject(obj)).filter((obj: any) => !!obj?.[HEADER.TELEFON]?.length);
  };

  const processFile = (event: any) => {
    setIsFormattingFile(true);

    const file = event?.target?.files?.[0];

    const fileName = file?.name;

    readFileContent(file)
      .then(content => {
        const objects = csvToObjects(content as string);
        exportAsCsv(filterInvalidObj(objects), fileName);
      })
      .finally(() => {
        setIsFormattingFile(false);
      });
  };

  const uploadCustomerCsvToImportHandler = (event: any) => {
    setCustomerCsvToImport(event?.target?.files?.[0]);
  };

  const isAbleToImportCustomerCsv = (): boolean => {
    return !isImportingFile && !!customerCsvToImport && !!selectedBusinessObject?.id;
  };

  const importCustomerCsvToSelectedBo = async () => {
    if (!isAbleToImportCustomerCsv()) {
      return;
    }

    setIsImportingFile(true);

    try {
      const isSuccess = await businessObjectService.importCustomerCsv(selectedBusinessObject.id, customerCsvToImport);

      if (isSuccess) {
        setCustomerCsvToImport(null);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsImportingFile(false);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Customer as CSV formatter
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            <span className={classes.boldText}>Use following headers:</span> {HEADERS.join(', ')}
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            <span className={classes.boldText}>Encode uploaded csv:</span> UTF-8
          </Typography>
        </Toolbar>

        <Button
          fullWidth
          variant="contained"
          component="label"
          color="primary"
          className={classes.button}
          disabled={isFormattingFile}
          startIcon={<Save />}
        >
          Upload raw CSV file to format
          <input type="file" onChange={processFile} hidden />
        </Button>
      </Paper>

      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Import customers from CSV file
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            Please consider to format this file before importing
          </Typography>
        </Toolbar>

        <Autocomplete
          style={{ width: '100%' }}
          autoHighlight
          options={businessObjects}
          groupBy={option => option.owner}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              item =>
                StringUtils.simplifyString(item.owner).includes(StringUtils.simplifyString(inputValue)) ||
                StringUtils.simplifyString(item.name).includes(StringUtils.simplifyString(inputValue)) ||
                StringUtils.simplifyString(item.address).includes(StringUtils.simplifyString(inputValue))
            )
          }
          value={selectedBusinessObject}
          onChange={(event: any, newValue) => setSelectedBusinessObject(newValue as BusinessObject)}
          getOptionLabel={option => `[${option.id}] ${option.name} [${option.address}]`}
          renderOption={option => (
            <>
              <div>
                [{option.id}]&nbsp;<strong>{option.name}</strong>
                <div className={classes.optionDescription}>
                  {option.address}
                  <div>{option.owner}</div>
                </div>
              </div>
            </>
          )}
          renderInput={params => (
            <TextField
              {...params}
              required
              label="Salon"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'salon-to-assign', // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Button fullWidth variant="outlined" component="label" color="primary" className={classes.button}>
          Upload customer csv file
          <input type="file" onChange={uploadCustomerCsvToImportHandler} hidden />
        </Button>

        {customerCsvToImport ? <div>{customerCsvToImport.name}</div> : <></>}

        <Button
          fullWidth
          variant="contained"
          component="label"
          color="primary"
          className={classes.button}
          startIcon={<BackupIcon />}
          disabled={!isAbleToImportCustomerCsv()}
          onClick={importCustomerCsvToSelectedBo}
        >
          Start import
        </Button>
      </Paper>
    </>
  );
}
