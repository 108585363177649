import React from 'react';
import { Product } from '../../../../../../models/interfaces/Product.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import { Redirect } from 'react-router-dom';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { getProductDetailPath } from '../../routing/product-route-definition.model';

interface ProductTableComponentProps {
  userId: number;
  businessObjectId: number;
  categoryId: number;
  products?: Product[];
}

export default function ProductTableComponent(props: ProductTableComponentProps) {
  const [pathToProductDetail, setPathToProductDetail] = React.useState<string>(null);

  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'displayOrder', field: 'displayOrder', label: 'Display order' },
    { id: 'name', field: 'name', label: 'Name' },
    { id: 'description', field: 'description', label: 'Description' },
    { id: 'price', field: 'price', label: 'Price' },
    { id: 'duration', field: 'duration', label: 'Duration' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: 'Products',
    rowsPerPage: 10,
    headCells,
  };

  const handleSelectRow = (selectedProduct: Product) => {
    if (selectedProduct?.id) {
      setPathToProductDetail(
        getProductDetailPath(props.userId, props.businessObjectId, props.categoryId, selectedProduct.id)
      );
    }
  };

  return pathToProductDetail ? (
    <Redirect to={{ pathname: pathToProductDetail }} />
  ) : (
    <CustomDataTableComponent tableConfig={tableConfig} data={props.products as Product[]} onSelect={handleSelectRow} />
  );
}
