import React from 'react';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { CreditOrder } from '../../../../../../models/interfaces/CreditOrder.interface';
import { DateTimeUtils } from '../../../../../../commons/utils/date-time.utils';
import { DATE_FORMAT } from '../../../../../../models/enums/DateFormat.enum';
import { CREDIT_ORDER_STATUS } from '../../../../../../models/enums/CreditOrderStatus.enum';

interface CreditOrderTableComponentProps {
  isLoading: boolean;
  creditOrders: CreditOrder[];
  onSelect?: Function;
}

export default function CreditOrderTableComponent(props: CreditOrderTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'balance', field: 'balance', label: 'Nr. Credit' },
    { id: 'price', field: 'price', label: 'Price' },
    { id: 'createdAt', field: 'createdAt', label: 'Created at' },
    { id: 'description', field: 'description', label: 'Description' },
    { id: 'provider', field: 'provider', label: 'Provider' },
    { id: 'countryCode', field: 'countryCode', label: 'Locale' },
    { id: 'orderNr', field: 'orderNr', label: 'Order number' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Credit orders`,
    rowsPerPage: 10,
    headCells,
    highlightRowColorFunc: (creditOrder: CreditOrder): string | null => {
      if (!creditOrder?.paypalPayment?.orderId || creditOrder?.status !== CREDIT_ORDER_STATUS.PENDING) {
        return null;
      }

      const fiveHoursBefore = DateTimeUtils.addHourToDate(new Date(), -5);
      const fiveHoursBeforeAsStringWithoutGtm = DateTimeUtils.formatDateTimeToString(
        new Date(fiveHoursBefore.getTime() + fiveHoursBefore.getTimezoneOffset() * 60000),
        DATE_FORMAT.DATE_FORMAT_BACKEND
      );

      if (creditOrder?.createdAt <= fiveHoursBeforeAsStringWithoutGtm) {
        return '#F15757';
      } else {
        return null;
      }
    },
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.creditOrders} onSelect={props?.onSelect} />;
}
