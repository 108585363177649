import React from 'react';
import { TextField, Button } from '@material-ui/core';
import authenticationService from '../../../../services/auth.service';
import authenticationHelperService from '../../../../services/auth-helper.service';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DEFAULT_PRIVATE_ROUTE } from '../../../private/routing/private-frame-route-definition.model';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginComponent(props: any) {
  const { from } = props.location.state?.from?.pathname
    ? props.location.state
    : { from: { pathname: DEFAULT_PRIVATE_ROUTE } };

  if (authenticationHelperService.isAuthenticated()) {
    props.history.push(from.pathname);
  }

  const submitData: any = {
    email: '',
    password: '',
  };

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;
    submitData[name] = type === 'checkbox' ? checked : value;
  };

  const login = (event: any) => {
    authenticationService.login(submitData.email, submitData.password).then(isSuccess => {
      if (isSuccess) {
        props.history.push(from.pathname);
      }
    });

    event.preventDefault();
  };

  const classes = useStyles();

  return (
    <div>
      <Typography component="h1" variant="h5" className={classes.title}>
        Sign in
      </Typography>
      <form className={classes.form} onSubmit={login}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          defaultValue=""
          onChange={submitDataChangeHandler}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={submitDataChangeHandler}
        />
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          Login
        </Button>
      </form>
    </div>
  );
}
