import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import shortenedLinkService from '../../../../services/shortened-link.service';
import logger from '../../../../commons/logger';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import ShortenedLinkFormComponent from './components/shortened-link-form.component';
import { Button, Grid, LinearProgress, TextField } from '@material-ui/core';
import { ShortenedLink } from '../../../../models/interfaces/ShortenedLink.interface';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
}));

export default function ShortenedLinkComponent(props: any) {
  const [shortenedLinkIdToGetInput, setShortenedLinkIdToGetInput] = React.useState<string>(null);
  const [shortenedLinkToGet, setShortenedLinkToGet] = React.useState<ShortenedLink>(null);

  const [linkToShorten, setLinkToShorten] = React.useState<string>(null);
  const [createdShortenedLink, setCreatedShortenedLink] = React.useState<ShortenedLink>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchShortenedLink = async (event: any) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const data = await shortenedLinkService.getShortenedLinkById(shortenedLinkIdToGetInput);

      setShortenedLinkToGet(data);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewShortenedLink = async (event: any) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const createdLink = await shortenedLinkService.createNewShortenedLink(linkToShorten);
      if (createdLink?.id) {
        setCreatedShortenedLink(createdLink);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Get shortened link by ID
          </Typography>
        </Toolbar>

        <form className={classes.form} onSubmit={fetchShortenedLink}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                multiline
                fullWidth
                label="ID of shortened link"
                value={shortenedLinkIdToGetInput || ''}
                disabled={isLoading}
                onChange={(event: any) => setShortenedLinkIdToGetInput(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                Get
              </Button>
            </Grid>
          </Grid>
        </form>

        {isLoading ? <LinearProgress /> : <></>}
      </Paper>

      {shortenedLinkToGet?.id ? (
        <ShortenedLinkFormComponent shortenedLink={shortenedLinkToGet}></ShortenedLinkFormComponent>
      ) : (
        <></>
      )}

      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Create new shortened link
          </Typography>
        </Toolbar>

        <form className={classes.form} onSubmit={createNewShortenedLink}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                multiline
                fullWidth
                label="Link to shorten"
                value={linkToShorten || ''}
                disabled={isLoading}
                onChange={(event: any) => setLinkToShorten(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>

        {isLoading ? <LinearProgress /> : <></>}
      </Paper>

      {createdShortenedLink?.id ? (
        <ShortenedLinkFormComponent shortenedLink={createdShortenedLink}></ShortenedLinkFormComponent>
      ) : (
        <></>
      )}
    </>
  );
}
