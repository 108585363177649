export class ObjectUtils {
  public static getValueOfField(obj: any, field: string): any {
    if (!obj) {
      return obj;
    }

    const fieldsSeparatedByDot = field.split('.');

    if (fieldsSeparatedByDot?.length === 1) {
      return obj[field];
    }

    const firstField = fieldsSeparatedByDot[0];

    return this.getValueOfField(obj[firstField], fieldsSeparatedByDot.slice(1).join('.'));
  }
}
