import React from 'react';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { Redirect } from 'react-router-dom';
import { getBoDetailPath } from '../../routing/business-object-route-definition.model';

interface BusinessObjectTableComponentProps {
  userId?: number;
  businessObjects?: BusinessObject[];
  onClick?: Function;
}

export default function BusinessObjectTableComponent(props: BusinessObjectTableComponentProps) {
  const [pathToBoDetail, setPathToBoDetail] = React.useState<string>(null);

  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'name', field: 'name', label: 'Name' },
    { id: 'owner', field: 'owner', label: 'Owner' },
    { id: 'address', field: 'address', label: 'Address' },
    { id: 'locale', field: 'locale', label: 'Locale' },
    { id: 'serviceTagsList', field: 'serviceTagsList', label: 'Service tags for searching' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: 'Business Objects',
    rowsPerPage: 10,
    headCells,
  };

  const handleSelectRow = (selectedBo: BusinessObject) => {
    if (selectedBo?.id) {
      if (props.onClick) {
        props.onClick(selectedBo);
      } else if (props.userId) {
        setPathToBoDetail(getBoDetailPath(props.userId, selectedBo.id));
      }
    }
  };

  return pathToBoDetail ? (
    <Redirect to={{ pathname: pathToBoDetail }} />
  ) : (
    <CustomDataTableComponent
      tableConfig={tableConfig}
      data={props.businessObjects as BusinessObject[]}
      onSelect={handleSelectRow}
    />
  );
}
