import { ChartJsDataSet } from '../models/interfaces/ChartJsDataSet.interface';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

class ChartJsLineChartService {
  constructor() {
    Chart.register(...registerables);
  }

  public generateMultiLinesChartOption(dataSets: ChartJsDataSet[], labels: string[]): ChartConfiguration {
    return {
      type: 'line',
      data: {
        labels,
        datasets: dataSets,
      },
      options: {
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          // x: { stacked: true },
          y: {
            // stacked: true,
            type: 'linear',
            display: true,
            position: 'left',
            beginAtZero: true,
            min: 0, // it is for ignoring negative step.
          },
        },
      },
    };
  }

  public getStackedBarChartOption(dataSets: ChartJsDataSet[], labels: string[]): ChartConfiguration {
    return {
      type: 'bar',
      data: {
        labels,
        datasets: dataSets,
      },
      options: {
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { stacked: true },
          y: {
            stacked: true,
            min: 0, // it is for ignoring negative step.
            beginAtZero: true,
            ticks: {
              callback: (value, index, values) => {
                if (Math.floor(Number(value)) === Number(value)) {
                  return value;
                }
              },
            },
          },
        },
      },
    };
  }
}

const chartJsLineChartService = new ChartJsLineChartService();
export default chartJsLineChartService;
