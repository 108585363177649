import httpService from './http.service';
import { BulkGateCountryPriceRequest } from '../models/interfaces/smsProviderInterfaces/BulkGateCountryPriceRequest.interface';
import { BulkGateCountryPriceResponse } from '../models/interfaces/smsProviderInterfaces/BulkGateCountryPriceResponse.interface';
import { ArrayUtils } from '../commons/utils/array.utils';

class SmsProviderService {
  private static readonly BULKGATE_APP_ID = '4121';
  private static readonly BULKGATE_APP_TOKEN = 'IkimKPC8JIV8nv3Dr13hBSk18Z3MhXoKzVcQbluXAniEzPIL8y';

  // see https://help.bulkgate.com/docs/en/http-price-list-get.html
  // Api is limited by minute quota 20 requests.
  public async getAllBulkgatePrices(countryCode: string[]) {
    const mapOfCountryCodeAndPrice = new Map<string, number>();

    const response = await httpService.post(
      'https://portal.bulkgate.com/api/1.0/price-list/get',
      {
        application_id: SmsProviderService.BULKGATE_APP_ID,
        application_token: SmsProviderService.BULKGATE_APP_TOKEN,
        country: countryCode?.map(c => c.toLowerCase()),
      } as BulkGateCountryPriceRequest,
      this.getDefaultHeader()
    );

    const priceList = (response?.data as BulkGateCountryPriceResponse)?.data?.price_list;
    for (const isoCode in priceList) {
      const operatorPriceList = priceList[isoCode];

      const maxEurPrice = ArrayUtils.getMaxValueOfArray(
        operatorPriceList
          ?.filter(o => o.currency === 'EUR' && (o.type === 'gTextOwn' || o.type === 'gText'))
          ?.map(o => o.price) || []
      );

      if (!!maxEurPrice && maxEurPrice > 0) {
        mapOfCountryCodeAndPrice.set(isoCode.toUpperCase(), maxEurPrice);
      }
    }

    return mapOfCountryCodeAndPrice;
  }

  private getDefaultHeader(): any {
    return {
      'Content-Type': 'application/json',
    };
  }
}

const smsProviderService = new SmsProviderService();
export default smsProviderService;
