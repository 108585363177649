import { ShortenedLink } from '../models/interfaces/ShortenedLink.interface';
import { AxiosResponse } from 'axios';
import ENV from '../commons/environment';
import { v4 as uuidv4 } from 'uuid';
import httpService from './http.service';
import { BackendResponse } from '../models/interfaces/BackendResponse.interface';

class ShortenedLinkService {
  // see: https://pro.onl.fast-crm.dnsuser.de:8080/specification-ui/#/

  public async getShortenedLinkById(id: string): Promise<ShortenedLink> {
    const url = `/v1/shortened-links/${id}`;

    const response = await this.get(url);

    return response?.body;
  }

  public async createNewShortenedLink(link: string): Promise<ShortenedLink> {
    const url = '/v1/shortened-links';

    const response = await this.post(url, { link });

    return response?.body;
  }

  public async updateShortenedLink(shortenedLink: ShortenedLink): Promise<boolean> {
    const url = `/v1/shortened-links/${shortenedLink.id}`;

    const response = await this.patch(url, {
      link: shortenedLink.originalLink,
    });
    return response?.status === 204;
  }

  private async get(url: string): Promise<BackendResponse> {
    const response = await httpService.get(this.createConcreteUrl(url), this.generateHeader());
    return this.handleResponse(response);
  }

  private async post(url: string, data: any): Promise<BackendResponse> {
    const response = await httpService.post(this.createConcreteUrl(url), JSON.stringify(data), this.generateHeader());
    return this.handleResponse(response);
  }

  private async patch(url: string, data: any): Promise<BackendResponse> {
    const response = await httpService.patch(this.createConcreteUrl(url), JSON.stringify(data), this.generateHeader());
    return this.handleResponse(response);
  }

  private generateHeader(): any {
    return {
      'Content-Type': 'application/json',
      'User-Id': uuidv4(),
      'User-Roles': 'ADMIN',
    };
  }

  private handleResponse(response: AxiosResponse): any {
    return {
      header: response.headers,
      body: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  private createConcreteUrl(url: string): string {
    return ENV.MODE === 'dev' ? url : `${ENV.SHORTENED_LINK_SERVICE_URL}${url}`;
  }
}

const shortenedLinkService = new ShortenedLinkService();
export default shortenedLinkService;
