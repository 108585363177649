import React from 'react';
import BusinessObjectFormComponent from '../../components/business-object-form/business-object-form.component';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { User } from '../../../../../../models/interfaces/User.interface';
import { getUserDetailPath } from '../../../user-context/routing/user-route-definition.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      marginBottom: '20px',
    },
  })
);

export default function BusinessObjectCreateComponent(props: any) {
  const user: User = props.user;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>Create new business object</div>

      {user?.id ? <BusinessObjectFormComponent owner={user} /> : <></>}

      <Link to={`${getUserDetailPath(user?.id)}`}>Back to user detail</Link>
    </div>
  );
}
