import React from 'react';
import BusinessObjectDetailRoutingComponent from './routing/business-object-detail-routing.component';
import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../../../services/business-object.service';

export default function BusinessObjectDetailContextComponent(props: any) {
  const { match } = props;
  const [businessObject, setBusinessObject] = React.useState<BusinessObject>(null);

  React.useEffect(() => {
    const getBusinessObject = async (userEmail: string, boId: number) => {
      const data = await businessObjectService.getBusinessObjectById(userEmail, boId);
      setBusinessObject(data);
    };

    const user: User = props.user;
    const boId = match?.params?.boId;

    getBusinessObject(user?.email, boId);
  }, [match]);

  return businessObject ? <BusinessObjectDetailRoutingComponent {...props} businessObject={businessObject} /> : <></>;
}
