import { ProviderOption } from '../interfaces/ProviderOption.interface';
import { SMS_PROVIDER } from '../enums/SmsProvider.enum';
import { LOCALE } from '../enums/Locale.enum';

export const DEFAULT_PROVIDER_OPTIONS: ProviderOption[] = [
  {
    value: SMS_PROVIDER.BULKGATE,
    label: 'Bulkgate',
  },
  {
    value: SMS_PROVIDER.GATEWAY_API,
    label: 'Gateway API',
  },
  /*  {
    value: SMS_PROVIDER.TWILLIO,
    label: 'Twillio',
  },*/
];

export const COUNTRY_USING_GATEWAY_API_AS_DEFAULT_PROVIDER: LOCALE[] = [LOCALE.de_DE, LOCALE.en_NL, LOCALE.fr_CH];
