import React from 'react';
import { User } from '../../../../../../models/interfaces/User.interface';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, InputAdornment, MenuItem, TextField, Toolbar } from '@material-ui/core';
import { Cancel, Edit, Save } from '@material-ui/icons';
import userService from '../../../../../../services/user.service';
import { Redirect } from 'react-router-dom';
import { getUserDetailPath } from '../../routing/user-route-definition.model';
import Typography from '@material-ui/core/Typography';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { LOCALE } from '../../../../../../models/enums/Locale.enum';
import { SMS_PROVIDER } from '../../../../../../models/enums/SmsProvider.enum';
import adminService from '../../../../../../services/admin.service';
import { Authority } from '../../../../../../models/interfaces/Authority.interface';
import {
  MAP_OF_AUTHORITY_AND_BO_VERSION,
  NOT_INTERESTED_AUTHORITIES,
} from '../../../../../../models/consts/AuthorityRole.const';
import businessObjectService from '../../../../../../services/business-object.service';
import { BUSINESS_OBJECT_VERSION } from '../../../../../../models/enums/BusinessObjectVersion.enum';
import { NumberUtils } from '../../../../../../commons/utils/number.utils';
import { COUNTRY_USING_GATEWAY_API_AS_DEFAULT_PROVIDER } from '../../../../../../models/consts/DefaultProviderOptions.const';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  editButton: {
    width: 'fit-content',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const listOfLocale = convertEnumToArray(LOCALE);

interface UserFormComponentProps {
  user?: User;
}

const listOfSmsProvider = convertEnumToArray(SMS_PROVIDER);

export default function UserFormComponent(props: UserFormComponentProps) {
  const [isEmitMode, setIsEmitMode] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User>(null);
  const [userToSubmit, setUserToSubmit] = React.useState<User>(null);
  const [redirectToUserDetail, setRedirectToUserDetail] = React.useState<string>(null);

  const [listOfAuthorities, setListOfAuthorities] = React.useState<Authority[]>([]);
  const [userAuthorityId, setUserAuthorityId] = React.useState<number>(null);

  const fetchAllAuthorities = async () => {
    const allAuthorities = await adminService.getAllAuthorities();
    setListOfAuthorities(allAuthorities?.filter(a => !NOT_INTERESTED_AUTHORITIES.includes(a.authority)));
  };

  const fetchAuthorities = async (userId: number) => {
    const userAuthorities = await adminService.getAuthoritiesOfUser(userId);
    setUserAuthorityId(userAuthorities?.filter(a => !NOT_INTERESTED_AUTHORITIES.includes(a.authority))?.[0]?.id);
  };

  React.useEffect(() => {
    fetchAllAuthorities();
  }, []);

  React.useEffect(() => {
    if (!!props?.user?.id && props?.user?.id !== user?.id) {
      fetchAuthorities(props?.user?.id);
    }

    setIsEmitMode(!props?.user);

    const initUser: User = props?.user ? { ...props.user } : userService.createBasicUser();

    setUser(initUser);
    setUserToSubmit(initUser);
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    const isSmsProviderChanged = name === 'smsProvider';

    if (isSmsProviderChanged) {
      const selectedSmsProvider: SMS_PROVIDER = isSmsProviderChanged ? value : userToSubmit?.smsProvider;

      // @ts-ignore
      setUserToSubmit({
        ...userToSubmit,
        smsProvider: selectedSmsProvider,
      });
    } else {
      const isLocalChanged = name === 'locale';

      // auto select provider
      const selectedSmsProvider = isLocalChanged
        ? COUNTRY_USING_GATEWAY_API_AS_DEFAULT_PROVIDER?.includes(value)
          ? SMS_PROVIDER.GATEWAY_API
          : SMS_PROVIDER.BULKGATE
        : userToSubmit?.smsProvider;

      // @ts-ignore
      setUserToSubmit({
        ...userToSubmit,
        [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value,
        smsProvider: selectedSmsProvider,
      });
    }
  };

  const reset = () => {
    setUserToSubmit(user || userService.createBasicUser());
    setIsEmitMode(false);
  };

  const updateUserAuthority = async (userId: number) => {
    await adminService.updateAuthoritiesOfUser(userId, [userAuthorityId]);
  };

  const updateUserAuthorityWithTimeout = async (userId: number) => {
    return new Promise(resolve => {
      setTimeout(() => {
        updateUserAuthority(userId).then(() => resolve(true));
      }, 1000);
    });
  };

  const updateUserAuthorityAndBoVersion = async () => {
    await updateUserAuthority(user?.id);

    const selectedAuthority = listOfAuthorities?.find(a => a.id === userAuthorityId);
    const newBoVersion: BUSINESS_OBJECT_VERSION = MAP_OF_AUTHORITY_AND_BO_VERSION[selectedAuthority?.authority];

    if (newBoVersion) {
      const boOfUser = await businessObjectService.getAllBusinessObjectsOfUser(user?.email);
      boOfUser?.forEach(bo => {
        if (bo.version !== newBoVersion) {
          businessObjectService.updateBusinessObject({
            ...bo,
            version: newBoVersion,
          });
        }
      });
    }
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    let response: User;
    if (userToSubmit?.id) {
      response = await userService.updateUser(userToSubmit);

      await updateUserAuthorityAndBoVersion();

      window.location.reload();
    } else {
      response = await userService.createUser(userToSubmit);

      if (response?.id) {
        await updateUserAuthorityWithTimeout(response.id);
        setRedirectToUserDetail(getUserDetailPath(response.id));
      }
    }

    // window.location.reload();

    setUser(response);
    setUserToSubmit(response);

    setIsEmitMode(false);
    setIsSubmitting(false);
  };

  const classes = useStyles();

  return redirectToUserDetail ? (
    <Redirect to={redirectToUserDetail} />
  ) : (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          User Info
        </Typography>

        {/*Edit button*/}
        {userToSubmit?.id && !isEmitMode ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            disabled={isSubmitting}
            onClick={() => {
              setIsEmitMode(true);
            }}
            className={classes.editButton}
          >
            Edit
          </Button>
        ) : (
          <></>
        )}
      </Toolbar>

      <form className={classes.form} onSubmit={submit}>
        <Grid container spacing={3}>
          {userToSubmit?.id ? (
            <Grid item xs={12} sm={6}>
              {/*ID*/}
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                fullWidth
                key="id"
                label="ID"
                name="id"
                value={userToSubmit?.id}
                disabled={true}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} sm={userToSubmit?.id ? 6 : 12}>
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Role"
              name="role"
              value={userAuthorityId || ''}
              disabled={!isEmitMode}
              onChange={event => setUserAuthorityId(Number(event.target?.value))}
              placeholder="Please select role"
            >
              {listOfAuthorities.map(authority => (
                <MenuItem value={authority.id} key={authority.id}>
                  {authority.authority}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/*Name*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="name"
              label="Name"
              name="name"
              value={userToSubmit?.name || ''}
              disabled={!isEmitMode}
              onChange={submitDataChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*Surname*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="surname"
              label="Surname"
              name="surname"
              value={userToSubmit?.surname || ''}
              disabled={!isEmitMode}
              onChange={submitDataChangeHandler}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/*Email address*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="email"
              required
              fullWidth
              key="email"
              label="Email address"
              name="email"
              value={userToSubmit?.email || ''}
              disabled={!isEmitMode || !!userToSubmit?.id}
              onChange={submitDataChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*Phone number*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              label="Phone number"
              name="phoneNr"
              placeholder="+49176543210"
              value={userToSubmit?.phoneNr || ''}
              disabled={!isEmitMode}
              onChange={submitDataChangeHandler}
            />
          </Grid>
        </Grid>

        {/*Password*/}
        {!userToSubmit?.id ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                fullWidth
                label="Password"
                name="password"
                value={userToSubmit?.password || ''}
                disabled={!isEmitMode}
                onChange={submitDataChangeHandler}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/*Locale*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Locale"
              name="locale"
              value={userToSubmit?.locale || ''}
              disabled={!isEmitMode}
              onChange={submitDataChangeHandler}
              placeholder="Please select locale"
            >
              {listOfLocale.map(locale => (
                <MenuItem value={locale} key={locale}>
                  {locale}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*SMS provider*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="SMS provider"
              name="smsProvider"
              value={userToSubmit?.smsProvider || ''}
              disabled={!isEmitMode}
              onChange={submitDataChangeHandler}
              helperText={<span>SMS Provider will be selected automatically based on Locale</span>}
            >
              {listOfSmsProvider.map(provider => (
                <MenuItem value={provider} key={provider}>
                  {provider}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {/*Credit Budget Notification Template*/}
        {/*<TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          fullWidth
          key="budgetUsageNotificationTemplate"
          label="Credit Budget Notification Template"
          name="budgetUsageNotificationTemplate"
          value={userToSubmit?.budgetUsageNotificationTemplate || ''}
          disabled={!isEmitMode}
          onChange={submitDataChangeHandler}
          helperText={
            <span>
              Dynamic values in Credit Budget Notification Template:
              <ul>
                <li>
                  <strong>{'__{currentBudget}'}</strong> Current Credit budget
                </li>
                <li>
                  <strong>{'__{threshold}'}</strong> Credit budget should cover usage of next x days (currently = 7
                  days)
                </li>
              </ul>
            </span>
          }
        />*/}

        {/*Estimated average daily Credit usage*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="number"
          required
          fullWidth
          key="estimatedAverageBudgetUsage"
          label="Estimated average daily Credit usage"
          name="estimatedAverageBudgetUsage"
          value={
            NumberUtils.isNumber(userToSubmit?.estimatedAverageBudgetUsage)
              ? userToSubmit?.estimatedAverageBudgetUsage
              : ''
          }
          disabled={!isEmitMode}
          onChange={submitDataChangeHandler}
          InputProps={{
            endAdornment: <InputAdornment position="end">Credit/day</InputAdornment>,
          }}
          helperText={<span>Simple estimation: 1 * numberOfStaff (e.g. 5 Staffs = 5 Credit / day)</span>}
        />

        {/*Submit button*/}
        {isEmitMode ? (
          <Grid container spacing={3}>
            <Grid item xs={6} hidden={!userToSubmit?.id}>
              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={reset}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={userToSubmit?.id ? 6 : 12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                startIcon={<Save />}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </form>
    </Paper>
  );
}
