export function createRoute(routePath: string, params: any = null, query: any = null): string {
  let transformedRoutePath = `${routePath}`;

  if (params) {
    const paramsKeys = Object.keys(params);
    paramsKeys.forEach(key => {
      const urlParam = `:${key}`;
      if (transformedRoutePath.indexOf(urlParam) >= 0) {
        transformedRoutePath = transformedRoutePath.replace(urlParam, params[key]);
      }
    });
  }

  if (query) {
    const queryKeys = Object.keys(query);
    queryKeys.forEach((key, index) => {
      const prefix = index ? '&' : '?';
      transformedRoutePath = `${transformedRoutePath}${prefix}${key}=${query[key]}`;
    });
  }

  return transformedRoutePath;
}

export function isMatchedRoute(originalRoutePath: string, currentRoutePath: string): boolean {
  const originalRouteAsArray: string[] = originalRoutePath.split('/');
  const currentRouteAsArray: string[] = currentRoutePath.split('/');

  if (originalRouteAsArray.length !== currentRouteAsArray.length) {
    return false;
  }

  for (let i = 0; i < originalRouteAsArray.length; i += 1) {
    const originalPart = originalRouteAsArray[i];
    const currentPart = currentRouteAsArray[i];

    if (originalPart !== currentPart) {
      if (originalPart.indexOf(':') < 0 && currentPart.indexOf(':') < 0) {
        return false;
      }
    }
  }
  return true;
}

export function getParamsFromRoute(currentRoute: string, routeContext: string): any {
  if (!isMatchedRoute(currentRoute, routeContext)) {
    return {};
  }

  const routeAsArray: string[] = currentRoute.split('/');
  const routeContextAsArray: string[] = routeContext.split('/');

  const params: any = {};

  for (let i = 0; i < routeAsArray.length; i += 1) {
    const routePart = routeAsArray[i];
    const contextPart = routeContextAsArray[i];

    if (routePart !== contextPart && contextPart.indexOf(':') >= 0) {
      params[contextPart.replace(':', '')] = routePart;
    }
  }

  return params;
}

export function createParentRoute(
  currentRoute: string,
  currentRouteContext: string,
  parentRouteContext: string
): string {
  const params = getParamsFromRoute(currentRoute, currentRouteContext);
  return createRoute(parentRouteContext, params);
}
