import React from 'react';
import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../../../services/business-object.service';
import BusinessObjectTableComponent from '../../components/business-object-table/business-object-table.component';
import { Link } from 'react-router-dom';
import { getUserDetailPath } from '../../../user-context/routing/user-route-definition.model';

export default function BusinessObjectListComponent(props: any) {
  const user: User = props.user;

  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);

  React.useEffect(() => {
    const getAllBusinessObjectsOfUser = async (userEmail: string) => {
      const data = await businessObjectService.getAllBusinessObjectsOfUser(userEmail);
      setBusinessObjects(data);
    };

    getAllBusinessObjectsOfUser(user?.email);
  }, [user]);

  return (
    <div>
      <BusinessObjectTableComponent userId={user?.id} businessObjects={businessObjects}></BusinessObjectTableComponent>

      <div>
        <Link to={`${getUserDetailPath(user?.id)}`}>Back to user detail</Link>
      </div>
    </div>
  );
}
