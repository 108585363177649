import backendCallerService from './backend-caller.service';
import { ServiceTag } from '../models/interfaces/ServiceTag.interface';
import { SERVICE_TAG_VARIANTS_DELIMITER } from '../models/consts/Other.const';

class ServiceTagService {
  public async fetchAllServiceTags(): Promise<ServiceTag[]> {
    const url = '/v1/service-tags';
    const response = await backendCallerService.get(url);

    return response?.body?.map((item: ServiceTag) => this.formatServiceTagForFrontend(item));
  }

  public async createServiceTag(serviceTag: ServiceTag): Promise<ServiceTag> {
    const url = '/v1/service-tag';
    const response = await backendCallerService.post(url, serviceTag);

    return this.formatServiceTagForFrontend(response?.body);
  }

  public async updateServiceTag(serviceTag: ServiceTag): Promise<ServiceTag> {
    const url = '/v1/service-tag';
    const response = await backendCallerService.post(url, serviceTag);

    return response?.body;
  }

  public async deleteServiceTag(serviceTag: ServiceTag): Promise<boolean> {
    const url = `/v1/service-tag/${serviceTag.id}`;
    const response = await backendCallerService.delete(url);

    return response?.status === 200;
  }

  private formatServiceTagForFrontend(serviceTag: ServiceTag): ServiceTag {
    if (!serviceTag) {
      return serviceTag;
    }

    return {
      ...serviceTag,
      variantList: serviceTag.variant.split(SERVICE_TAG_VARIANTS_DELIMITER),
    };
  }
}

const serviceTagService = new ServiceTagService();
export default serviceTagService;
