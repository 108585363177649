import { User } from '../models/interfaces/User.interface';
import backendCallerService from './backend-caller.service';
import { __TYPE__ } from '../models/enums/__Type__.enum';
import { DateTimeUtils } from '../commons/utils/date-time.utils';

class UserService {
  public createBasicUser(): User {
    return {
      __type__: __TYPE__.BASIC_USER,
      budgetUsageNotificationTemplate:
        'Số lượng Credit hiện tại (__{currentBudget}) không đủ cho __{threshold} ngày tiếp theo',
    };
  }

  public async getAllUsers(): Promise<User[]> {
    const url = '/v1/users';

    const response = await backendCallerService.get(url);

    return this.transformUsersForFrontend(response?.body);
  }

  public async getAllBusinessOwnerUsers(): Promise<User[]> {
    const url = '/v1/business-owner-users';

    const response = await backendCallerService.get(url);

    return this.transformUsersForFrontend(response?.body);
  }

  public async getUserById(userId: number): Promise<User> {
    const url = `/v1/user/${userId}`;

    const response = await backendCallerService.get(url);

    return this.transformUserForFrontend(response?.body);
  }

  public async getCurrentLoggedInUser(): Promise<User> {
    const url = `/v1/user/me`;

    const response = await backendCallerService.get(url);
    return this.transformUserForFrontend(response?.body);
  }

  public async getCurrentLoggedInUserByToken(token: string): Promise<User> {
    const url = `/v1/user/me`;

    const response = await backendCallerService.get(url, backendCallerService.generateHeaderWithToken(token));
    return this.transformUserForFrontend(response?.body);
  }

  public async createUser(user: User): Promise<User> {
    const url = '/v1/admin/users';

    const response = await backendCallerService.post(url, {
      ...user,
      __type__: user.__type__ || __TYPE__.BASIC_USER,
    });

    return this.transformUserForFrontend(response?.body);
  }

  public async updateUser(user: User): Promise<User> {
    const url = `/v1/user/${user.id}`;

    const response = await backendCallerService.put(url, {
      ...user,
      __type__: user.__type__ || __TYPE__.BASIC_USER,
      id: undefined,
      password: undefined,
    });

    return this.transformUserForFrontend(response?.body);
  }

  private transformUsersForFrontend(users: User[]): User[] {
    const result = users?.map(u => this.transformUserForFrontend(u));

    // sort by id desc
    result.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    return result;
  }

  private transformUserForFrontend(user: User): User {
    if (!user) {
      return user;
    }

    let fullName = user.name || '';

    if (fullName?.length > 0 && user.surname) {
      fullName += ' ';
    }

    if (user.surname) {
      fullName += user.surname;
    }

    return {
      ...user,
      fullName,
      createdAtAsDate: user?.createdAt ? DateTimeUtils.parseStringToDate(user.createdAt) : null,
    };
  }
}

const userService = new UserService();

export default userService;
