import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import businessObjectService from '../../../../../../services/business-object.service';
import { getCategoryDetailPath } from '../../routing/category-route-definition.model';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, TextField, Toolbar } from '@material-ui/core';
import { Cancel, Delete, Edit, Save } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { getBoDetailPath } from '../../../business-object-context/routing/business-object-route-definition.model';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    marginRight: theme.spacing(1),
  },
  editButton: {
    width: 'fit-content',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface CategoryFormComponentProps {
  ownerId: number;
  businessObjectId: number;
  ownerEmail?: string;
  category?: ProductCategory;
}

export default function CategoryFormComponent(props: CategoryFormComponentProps) {
  const [isEmitMode, setIsEmitMode] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [category, setCategory] = React.useState<ProductCategory>(null);
  const [categoryToSubmit, setCategoryToSubmit] = React.useState<ProductCategory>(null);

  const [redirectToCategoryDetail, setRedirectToCategoryDetail] = React.useState<string>(null);
  const [redirectToBoDetail, setRedirectToBoDetail] = React.useState<string>(null);

  React.useEffect(() => {
    setIsEmitMode(!props?.category);

    const initCategory: ProductCategory = props?.category
      ? { ...props.category }
      : businessObjectService.createBasicCategory(props.ownerEmail, props.businessObjectId);

    setCategory(initCategory);
    setCategoryToSubmit(initCategory);
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    // @ts-ignore
    setCategoryToSubmit({
      ...categoryToSubmit,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const reset = () => {
    setCategoryToSubmit(
      category || businessObjectService.createBasicCategory(props.ownerEmail, props.businessObjectId)
    );
    setIsEmitMode(false);
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    let response: ProductCategory;
    if (categoryToSubmit?.id) {
      response = await businessObjectService.updateCategory(categoryToSubmit);
    } else {
      response = await businessObjectService.createCategoryForBo(categoryToSubmit);

      if (response.id) {
        setRedirectToCategoryDetail(getCategoryDetailPath(props.ownerId, props.businessObjectId, response.id));
      }
    }

    setCategory(response);
    setCategoryToSubmit(response);

    setIsEmitMode(false);
    setIsSubmitting(false);
  };

  const deleteCategory = async (event: any) => {
    event.preventDefault();

    if (!category?.id) {
      return;
    }

    const response = await businessObjectService.deleteCategory(category);

    if (response) {
      setRedirectToBoDetail(getBoDetailPath(props.ownerId, props.businessObjectId));
    }
  };

  const classes = useStyles();

  return redirectToCategoryDetail ? (
    <Redirect to={redirectToCategoryDetail} />
  ) : redirectToBoDetail ? (
    <Redirect to={redirectToBoDetail} />
  ) : (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        {categoryToSubmit?.id && isEmitMode ? (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Delete />}
            disabled={isSubmitting}
            onClick={deleteCategory}
            className={classes.deleteButton}
          >
            Delete
          </Button>
        ) : (
          <></>
        )}

        <Typography variant="h6" className={classes.title}>
          Category Info
        </Typography>

        {/*Edit button*/}
        {categoryToSubmit?.id && !isEmitMode ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            disabled={isSubmitting}
            onClick={() => {
              setIsEmitMode(true);
            }}
            className={classes.editButton}
          >
            Edit
          </Button>
        ) : (
          <></>
        )}
      </Toolbar>

      <form className={classes.form} onSubmit={submit}>
        {/*ID*/}
        {categoryToSubmit?.id ? (
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            fullWidth
            key="id"
            label="ID"
            name="id"
            value={categoryToSubmit?.id}
            disabled={true}
          />
        ) : (
          <></>
        )}

        {/*Name*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          fullWidth
          key="name"
          label="Category name"
          name="name"
          value={categoryToSubmit?.name || ''}
          disabled={!isEmitMode}
          onChange={submitDataChangeHandler}
        />

        {/*Description*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          key="description"
          label="Description"
          name="description"
          value={categoryToSubmit?.description || ''}
          disabled={!isEmitMode}
          onChange={submitDataChangeHandler}
        />

        {/*Submit button*/}
        {isEmitMode ? (
          <Grid container spacing={3}>
            <Grid item xs={6} hidden={!categoryToSubmit?.id}>
              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={reset}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={categoryToSubmit?.id ? 6 : 12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                startIcon={<Save />}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </form>
    </Paper>
  );
}
