export enum LOCALE {
  de_DE = 'de_DE',
  de_CH = 'de_CH',
  fr_CH = 'fr_CH',
  de_AT = 'de_AT',
  en_US = 'en_US',
  cs_CZ = 'cs_CZ',
  en_CZ = 'en_CZ',
  en_NL = 'en_NL',
  sq_AL = 'sq_AL',
}
