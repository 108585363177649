import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LexofficeConnection } from '../../../../../models/interfaces/LexofficeConnection.interface';
import { InvoiceJob } from '../../../../../models/interfaces/InvoiceJob.interface';
import { CommunicationUnitPackage } from '../../../../../models/interfaces/CommunicationUnitPackage.interface';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import CustomDialogComponent from '../../../../../commons/components/custom-dialog/custom-dialog.component';
import { User } from '../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../models/interfaces/BusinessObject.interface';
import { convertEnumToArray } from '../../../../../commons/utils/enum-to-array';
import { INVOICE_INTERVAL } from '../../../../../models/enums/InvoiceInterval.enum';
import { DateTimeUtils } from '../../../../../commons/utils/date-time.utils';
import { DATE_FORMAT } from '../../../../../models/enums/DateFormat.enum';
import { StringUtils } from '../../../../../commons/utils/string.util';
import { Autocomplete } from '@material-ui/lab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionDescription: {
      marginLeft: '5px',
      fontSize: 'small',
      color: 'grey',
    },
  })
);

export interface InvoiceJobDataForDialog {
  invoiceJob?: InvoiceJob;
  lexofficeConnections: LexofficeConnection[];
  communicationUnitPackages: CommunicationUnitPackage[];
  users: User[];
  businessObjects: BusinessObject[];
}

interface InvoiceJobDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  data: InvoiceJobDataForDialog;
  onClose: Function;
  onCreate: Function;
  onUpdate: Function;
  onDelete: Function;
}

const invoiceIntervalOptions = convertEnumToArray(INVOICE_INTERVAL);

export default function InvoiceJobDialogComponent(props: InvoiceJobDialogComponentProps) {
  const [selectedUser, setSelectedUser] = React.useState<User>(null);
  const [selectedBusinessObject, setSelectedBusinessObject] = React.useState<BusinessObject>(null);
  const [selectedLexofficeConnection, setSelectedLexofficeConnection] = React.useState<LexofficeConnection>(null);
  const [selectedCommunicationUnitPackage, setSelectedCommunicationUnitPackage] =
    React.useState<CommunicationUnitPackage>(null);
  const [invoiceJobToSubmit, setInvoiceJobToSubmit] = React.useState<InvoiceJob>({
    userId: null,
    userEmail: null,
    businessObjectId: null,
    businessObjectName: null,
    connection: null,
    appointmentFee: true,
    twFee: false,
    bpFee: false,
    startDate: DateTimeUtils.formatDateToString(new Date(), DATE_FORMAT.DATE_FORMAT_BACKEND),
    active: true,
    repeated: true,
    jobInterval: INVOICE_INTERVAL.MONTHLY,
  });

  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [communicationUnitPackages, setCommunicationUnitPackages] = React.useState<CommunicationUnitPackage[]>([]);

  const _findUserById = (userId: number) => {
    if (!userId) {
      return null;
    }
    return props.data?.users?.find(u => u.id === userId);
  };

  const _findUserByEmail = (userEmail: string) => {
    if (!userEmail) {
      return null;
    }
    return props.data?.users?.find(u => u.email === userEmail);
  };

  const _findBusinessObjectById = (boId: number) => {
    if (!boId) {
      return null;
    }
    return props.data?.businessObjects?.find(bo => bo.id === boId);
  };

  const _findLexofficeConnectionById = (lexofficeConnectionId: number) => {
    if (!lexofficeConnectionId) {
      return null;
    }
    return props.data?.lexofficeConnections?.find(l => l.id === lexofficeConnectionId);
  };

  const _findCommunicationUnitPackageById = (packageId: number) => {
    if (!packageId) {
      return null;
    }
    return props.data?.communicationUnitPackages?.find(c => c.id === packageId);
  };

  React.useEffect(() => {
    // filter BO
    const availableBo = selectedUser?.email
      ? props?.data?.businessObjects?.filter(bo => bo.owner === selectedUser.email)
      : [...(props?.data?.businessObjects || [])];

    // sorting is required for display as group in dropdown
    setBusinessObjects(availableBo.sort((a, b) => -b.owner.localeCompare(a.owner)));

    if (!!selectedBusinessObject?.id && selectedBusinessObject?.owner !== selectedUser?.email) {
      setSelectedBusinessObject(null);
    }

    // filter CommunicationUnitPackage
    const availableCommunicationUnitPackages = selectedUser?.locale
      ? props?.data?.communicationUnitPackages?.filter(
          c => selectedUser?.locale?.toString().includes(c.countryCode) && c.provider === selectedUser?.smsProvider
        )
      : [...props?.data?.communicationUnitPackages];

    setCommunicationUnitPackages(availableCommunicationUnitPackages);

    if (
      !!selectedCommunicationUnitPackage?.id &&
      !availableCommunicationUnitPackages?.find(c => c.id === selectedCommunicationUnitPackage?.id)
    ) {
      setSelectedCommunicationUnitPackage(null);
    }

    setInvoiceJobToSubmit({
      ...invoiceJobToSubmit,
      userId: selectedUser?.id,
      userEmail: selectedUser?.email,
    });
  }, [selectedUser]);

  React.useEffect(() => {
    if (selectedBusinessObject?.id && selectedBusinessObject?.owner !== selectedUser?.email) {
      setSelectedUser(_findUserByEmail(selectedBusinessObject.owner));
    }

    setInvoiceJobToSubmit({
      ...invoiceJobToSubmit,
      businessObjectId: selectedBusinessObject?.id,
      businessObjectName: selectedBusinessObject?.name,
    });
  }, [selectedBusinessObject]);

  React.useEffect(() => {
    setInvoiceJobToSubmit({
      ...invoiceJobToSubmit,
      connection: selectedLexofficeConnection,
    });
  }, [selectedLexofficeConnection]);

  React.useEffect(() => {
    setInvoiceJobToSubmit({
      ...invoiceJobToSubmit,
      communicationUnitPackage: selectedCommunicationUnitPackage,
    });
  }, [selectedCommunicationUnitPackage]);

  React.useEffect(() => {
    const invoiceJob = props?.data?.invoiceJob;

    if (invoiceJob?.id) {
      setInvoiceJobToSubmit(invoiceJob);
      setSelectedUser(_findUserById(invoiceJob?.userId));
      setSelectedBusinessObject(_findBusinessObjectById(invoiceJob?.businessObjectId));
      setSelectedLexofficeConnection(_findLexofficeConnectionById(invoiceJob?.connection?.id));
      setSelectedCommunicationUnitPackage(_findCommunicationUnitPackageById(invoiceJob?.communicationUnitPackage?.id));
    }
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    if (name === 'locale') {
      setInvoiceJobToSubmit({
        ...invoiceJobToSubmit,
        [name]: type === 'checkbox' ? checked : value,
      });
    } else {
      setInvoiceJobToSubmit({
        ...invoiceJobToSubmit,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const onCreateNewInvoiceJob = async () => {
    if (!!props?.data?.invoiceJob?.id) {
      return;
    }

    await props.onCreate(invoiceJobToSubmit);
  };

  const onUpdateInvoiceJob = async () => {
    if (!props?.data?.invoiceJob?.id) {
      return;
    }

    await props.onUpdate(invoiceJobToSubmit);
  };

  const onDeleteInvoiceJob = async () => {
    const invoiceJobId = props?.data?.invoiceJob?.id;
    if (!invoiceJobId || props.isLoading) {
      return;
    }
    await props.onDelete(invoiceJobId);
  };

  const classes = useStyles();

  const getTitle = () => {
    const invoiceJobId = props?.data?.invoiceJob?.id;

    return invoiceJobId ? `Edit invoice job with id=${invoiceJobId}` : 'Create new invoice job';
  };

  const getContent = () => {
    return (
      <>
        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={props?.data?.users?.sort((a, b) => -b.locale.localeCompare(a.locale))}
              groupBy={option => option.locale}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(item.email).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.phoneNr).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.fullName).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedUser}
              onChange={(event: any, newValue) => setSelectedUser(newValue as User)}
              getOptionLabel={option => `[${option.id}] [${option.email}] [${option.fullName}]`}
              renderOption={option => (
                <>
                  <div>
                    [{option.id}]&nbsp;<strong>{option.email}</strong>
                    <div className={classes.optionDescription}>
                      {option.fullName}
                      <div>{option.phoneNr}</div>
                    </div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="User"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'user-to-assign', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={businessObjects}
              groupBy={option => option.owner}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(item.name).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.address).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedBusinessObject}
              onChange={(event: any, newValue) => setSelectedBusinessObject(newValue as BusinessObject)}
              getOptionLabel={option => `[${option.id}] ${option.name} [${option.address}]`}
              renderOption={option => (
                <>
                  <div>
                    [{option.id}]&nbsp;<strong>{option.name}</strong>
                    <div className={classes.optionDescription}>{option.address}</div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="Salon"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'salon-to-assign', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={props?.data?.lexofficeConnections}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(item.userEmail).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.businessObjectName).includes(
                      StringUtils.simplifyString(inputValue)
                    ) ||
                    StringUtils.simplifyString(item.contactName).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedLexofficeConnection}
              onChange={(event: any, newValue) => setSelectedLexofficeConnection(newValue as LexofficeConnection)}
              getOptionLabel={option =>
                `[${option.userEmail || ''}] ${option.contactName} ${option.businessObjectName || ''}`
              }
              renderOption={option => (
                <>
                  <div>
                    <strong>{option.contactName || '-'}</strong>
                    <div className={classes.optionDescription}>
                      {option.userEmail || ''}
                      <div>{option.businessObjectName || ''}</div>
                    </div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="Lexoffice connection"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'lexoffice-connection', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={communicationUnitPackages}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(`${item.balance}`).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(`${item.price}`).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedCommunicationUnitPackage}
              onChange={(event: any, newValue) =>
                setSelectedCommunicationUnitPackage(newValue as CommunicationUnitPackage)
              }
              getOptionLabel={option =>
                `[${option.countryCode} - ${option.provider}] ${option.balance} Credit - ${option.price} EUR`
              }
              renderOption={option => (
                <>
                  <div>
                    [{option.balance} Credit]&nbsp;<strong>{option.price} EUR</strong>
                    <div className={classes.optionDescription}>
                      {option.countryCode} - {option.provider}
                    </div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Monthly deposit credit (optional)"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'credit-package', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              type="date"
              required
              fullWidth
              label="Start date"
              name="startDate"
              value={invoiceJobToSubmit?.startDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={submitDataChangeHandler}
            />

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      required
                      key="repeated"
                      name="repeated"
                      checked={invoiceJobToSubmit?.repeated}
                      onChange={submitDataChangeHandler}
                    />
                  }
                  label="Repeat"
                />
              </Grid>
              <Grid item xs={6}>
                {invoiceJobToSubmit?.repeated ? (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    select
                    required
                    fullWidth
                    label="Interval"
                    name="jobInterval"
                    value={invoiceJobToSubmit?.jobInterval || ''}
                    onChange={submitDataChangeHandler}
                  >
                    {invoiceIntervalOptions.map(interval => (
                      <MenuItem value={interval} key={interval}>
                        {interval}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceJobToSubmit?.appointmentFee}
                  onChange={submitDataChangeHandler}
                  key="appointmentFee"
                  name="appointmentFee"
                  color="primary"
                />
              }
              key="appointmentFee"
              label="Appointment fee"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceJobToSubmit?.twFee}
                  onChange={submitDataChangeHandler}
                  key="twFee"
                  name="twFee"
                  color="primary"
                />
              }
              key="twFee"
              label="TW connect fee"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceJobToSubmit?.bpFee}
                  onChange={submitDataChangeHandler}
                  key="bpFee"
                  name="bpFee"
                  color="primary"
                />
              }
              key="bpFee"
              label="PRO fee"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="note"
              label="Note"
              name="note"
              value={invoiceJobToSubmit?.note || ''}
              onChange={submitDataChangeHandler}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  required
                  key="active"
                  name="active"
                  color="primary"
                  checked={invoiceJobToSubmit?.active}
                  onChange={submitDataChangeHandler}
                />
              }
              label="Activation"
            />
          </Grid>
        </Grid>

        {invoiceJobToSubmit?.id ? (
          <Grid container spacing={3} style={{ marginTop: '5px' }}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                disabled={true}
                fullWidth
                key="lastExecutionDate"
                label="Last execution date"
                name="lastExecutionDate"
                value={invoiceJobToSubmit?.lastExecutionDate || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                disabled={true}
                fullWidth
                key="nextExecutionDate"
                label="Next execution date"
                name="nextExecutionDate"
                value={invoiceJobToSubmit?.nextExecutionDate || ''}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getButtons = () => {
    if (props?.data?.invoiceJob?.id) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={props.isLoading}
              onClick={onDeleteInvoiceJob}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onUpdateInvoiceJob}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onCreateNewInvoiceJob}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <CustomDialogComponent
      isLoading={props.isLoading}
      styleOptions={{ fullWidth: true }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
