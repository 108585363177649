import { DateTimeUtils } from './date-time.utils';
import { DateRange } from '../../models/interfaces/DateRange.interface';
import { RANGE_VIEW_MODE } from '../../models/enums/RangeViewMode.enum';

export class RangeViewModeUtils {
  private static DAY_RANGE_IN_DAY_MODE = 7; // 7 days
  private static MONTH_RANGE_IN_MONTH_MODE = 12; // 12 months
  private static YEAR_RANGE_IN_YEAR_MODE = 5; // 5 years

  public static generateEarliestDateRangeFromViewMode(selectedViewMode: RANGE_VIEW_MODE): DateRange {
    let toDate = new Date();
    let fromDate: Date;

    if (selectedViewMode === RANGE_VIEW_MODE.DAY) {
      fromDate = DateTimeUtils.addDayToDate(toDate, 1 - RangeViewModeUtils.DAY_RANGE_IN_DAY_MODE);
    } else if (selectedViewMode === RANGE_VIEW_MODE.MONTH) {
      fromDate = DateTimeUtils.getFirstDateOfMonth(
        DateTimeUtils.addMonthToDate(toDate, 1 - RangeViewModeUtils.MONTH_RANGE_IN_MONTH_MODE)
      );
    } else if (selectedViewMode === RANGE_VIEW_MODE.YEAR) {
      fromDate = DateTimeUtils.getFirstDateOfYear(
        DateTimeUtils.addYearToDate(toDate, 1 - RangeViewModeUtils.YEAR_RANGE_IN_YEAR_MODE)
      );
      toDate = DateTimeUtils.getLastDateOfYear(toDate);
    }

    return {
      fromDate,
      toDate,
    };
  }

  public static getNextDateRange(dateRange: DateRange, selectedViewMode: RANGE_VIEW_MODE): DateRange {
    return RangeViewModeUtils.changeTimeRangeWindow(dateRange, selectedViewMode, true);
  }

  public static getPreviousDateRange(dateRange: DateRange, selectedViewMode: RANGE_VIEW_MODE): DateRange {
    return RangeViewModeUtils.changeTimeRangeWindow(dateRange, selectedViewMode, false);
  }

  private static changeTimeRangeWindow(
    dateRange: DateRange,
    selectedViewMode: RANGE_VIEW_MODE,
    isNext: boolean
  ): DateRange {
    const factor = isNext ? 1 : -1;

    let fromDate: Date, toDate: Date;

    if (selectedViewMode === RANGE_VIEW_MODE.DAY) {
      fromDate = DateTimeUtils.addDayToDate(dateRange.fromDate, factor * RangeViewModeUtils.DAY_RANGE_IN_DAY_MODE);
      toDate = DateTimeUtils.addDayToDate(dateRange.toDate, factor * RangeViewModeUtils.DAY_RANGE_IN_DAY_MODE);
    } else if (selectedViewMode === RANGE_VIEW_MODE.MONTH) {
      fromDate = DateTimeUtils.addMonthToDate(
        dateRange.fromDate,
        factor * RangeViewModeUtils.MONTH_RANGE_IN_MONTH_MODE
      );
      toDate = DateTimeUtils.getFirstDateOfMonth(
        DateTimeUtils.addMonthToDate(dateRange.toDate, factor * RangeViewModeUtils.MONTH_RANGE_IN_MONTH_MODE)
      );
    } else if (selectedViewMode === RANGE_VIEW_MODE.YEAR) {
      fromDate = DateTimeUtils.getFirstDateOfYear(
        DateTimeUtils.addYearToDate(dateRange.fromDate, factor * RangeViewModeUtils.YEAR_RANGE_IN_YEAR_MODE)
      );
      toDate = DateTimeUtils.getFirstDateOfYear(
        DateTimeUtils.addYearToDate(dateRange.toDate, factor * RangeViewModeUtils.YEAR_RANGE_IN_YEAR_MODE)
      );
    }

    return {
      fromDate,
      toDate,
    };
  }
}
