const ENV = {
  MODE: process.env.REACT_APP_MODE,
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  BEAUTY_ADMIN_URL: process.env.REACT_APP_BEAUTY_ADMIN_URL,
  SHORTENED_LINK_SERVICE_URL: process.env.REACT_APP_SHORTENED_LINK_SERVICE_URL,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
};

export default ENV;
