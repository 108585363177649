import React from 'react';
import serviceTagService from '../../../../services/service-tag.service';
import { ServiceTag } from '../../../../models/interfaces/ServiceTag.interface';
import logger from '../../../../commons/logger';
import { Button, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ServiceTagTableComponent from './components/service-tag-table/service-tag-table.component';
import ServiceTagDialogComponent from './components/service-tag-dialog/service-tag-dialog.component';

const useStyles = makeStyles(theme => ({
  filterForm: {
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
}));

interface ServiceTagFilterForm {
  language: string | 'all';
  tag: string | 'all';
}

export default function ServiceTagSettingsComponent(props: any) {
  const [serviceTags, setServiceTags] = React.useState<ServiceTag[]>([]);
  const [filteredServiceTags, setFilteredServiceTags] = React.useState<ServiceTag[]>([]);

  const [languageOptions, setLanguageOptions] = React.useState<string[]>([]);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);

  const [filterForm, setFilterForm] = React.useState<ServiceTagFilterForm>({
    tag: 'all',
    language: 'all',
  });

  const [selectedServiceTags, setSelectedServiceTags] = React.useState<ServiceTag[]>([]);
  const [isOpenServiceTagDialog, setIsOpenServiceTagDialog] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchAllServiceTags = async () => {
    try {
      setIsLoading(true);

      const data = await serviceTagService.fetchAllServiceTags();
      setServiceTags(data);

      setLanguageOptions(generateUniqueLanguageOptions(data));
      setTagOptions(generateUniqueTagOptions(data));
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const generateUniqueLanguageOptions = (serviceTags: ServiceTag[]): string[] => {
    const languageSet = new Set<string>();

    serviceTags?.forEach(t => languageSet.add(t.lang));

    return ['all', ...Array.from(languageSet.values())];
  };

  const generateUniqueTagOptions = (serviceTags: ServiceTag[]): string[] => {
    const tagSet = new Set<string>();

    serviceTags?.forEach(t => tagSet.add(t.tag));

    return ['all', ...Array.from(tagSet.values())];
  };

  React.useEffect(() => {
    fetchAllServiceTags();
  }, []);

  React.useEffect(() => {
    updateFilteredServiceTags();
  }, [serviceTags, filterForm]);

  const updateFilteredServiceTags = () => {
    let tmpFilteredServiceTags = [...serviceTags];

    if (!!filterForm.language && filterForm.language !== 'all') {
      tmpFilteredServiceTags = tmpFilteredServiceTags?.filter(t => t.lang === filterForm.language);
    }

    if (!!filterForm.tag && filterForm.tag !== 'all') {
      tmpFilteredServiceTags = tmpFilteredServiceTags?.filter(t => t.tag === filterForm.tag);
    }

    setFilteredServiceTags(tmpFilteredServiceTags);
  };

  const filterFormChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    // @ts-ignore
    setFilterForm({
      ...filterForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const openServiceTagDialog = () => {
    setIsOpenServiceTagDialog(true);
  };

  const closeServiceTagDialog = () => {
    if (isLoading) {
      return;
    }

    setSelectedServiceTags([]);
    setIsOpenServiceTagDialog(false);
  };

  const onSelectServiceTag = (serviceTag: ServiceTag) => {
    const matchedServiceTags = serviceTags?.filter(t => t.tag === serviceTag.tag);

    setSelectedServiceTags(matchedServiceTags);
    openServiceTagDialog();
  };

  const addNewServiceTags = async (serviceTagsToCreate: ServiceTag[]) => {
    setIsLoading(true);

    try {
      await Promise.all(serviceTagsToCreate?.map(t => serviceTagService.createServiceTag(t)));
      await fetchAllServiceTags();
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeServiceTagDialog();
    }
  };

  const updateServiceTags = async (editedServiceTags: ServiceTag[]) => {
    setIsLoading(true);

    try {
      const originalLangSet = new Set<string>(selectedServiceTags?.map(st => st.lang));
      const newLangSet = new Set<string>(editedServiceTags?.map(st => st.lang));

      const serviceTagsToCreate = editedServiceTags?.filter(st => !originalLangSet.has(st.lang));
      const serviceTagsToUpdate = editedServiceTags?.filter(st => originalLangSet.has(st.lang));
      const serviceTagsToDelete = selectedServiceTags?.filter(st => !newLangSet.has(st.lang));

      const promises: Promise<any>[] = [
        ...serviceTagsToCreate?.map(st => serviceTagService.createServiceTag(st)),
        ...serviceTagsToUpdate?.map(st => serviceTagService.updateServiceTag(st)),
        ...serviceTagsToDelete?.map(st => serviceTagService.deleteServiceTag(st)),
      ];

      await Promise.all(promises);

      await fetchAllServiceTags();
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
      closeServiceTagDialog();
    }
  };

  const classes = useStyles();

  return (
    <>
      {isLoading && !serviceTags?.length ? (
        <LinearProgress />
      ) : (
        <>
          <Grid container spacing={3} className={classes.filterForm}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="dense"
                select
                required
                fullWidth
                label="Language"
                name="language"
                value={filterForm?.language || 'all'}
                onChange={filterFormChangeHandler}
              >
                {languageOptions.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="dense"
                select
                required
                fullWidth
                label="Tag"
                name="tag"
                value={filterForm?.tag || 'all'}
                onChange={filterFormChangeHandler}
              >
                {tagOptions.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Button onClick={openServiceTagDialog} fullWidth variant="contained" color="primary" startIcon={<AddIcon />}>
            Add new service tag
          </Button>

          <ServiceTagDialogComponent
            isOpen={isOpenServiceTagDialog}
            isLoading={isLoading}
            serviceTags={selectedServiceTags}
            languageOptions={languageOptions}
            tagOptions={tagOptions}
            onClose={closeServiceTagDialog}
            onCreate={addNewServiceTags}
            onUpdate={updateServiceTags}
          ></ServiceTagDialogComponent>

          <div className={classes.marginTop}></div>

          <ServiceTagTableComponent
            serviceTags={filteredServiceTags}
            onSelect={onSelectServiceTag}
          ></ServiceTagTableComponent>
        </>
      )}
    </>
  );
}
