import React from 'react';
import { Box, Grid, LinearProgress, MenuItem, Tab, Tabs, TextField } from '@material-ui/core';
import { LexofficeConnection } from '../../../../models/interfaces/LexofficeConnection.interface';
import adminService from '../../../../services/admin.service';
import logger from '../../../../commons/logger';
import LexofficeConnectionTableComponent from './components/lexoffice-connection-table.component';
import { LexofficeContact } from '../../../../models/interfaces/LexofficeContact.interface';
import LexofficeContactTableComponent from './components/lexoffice-contact-table.component';
import { User } from '../../../../models/interfaces/User.interface';
import userService from '../../../../services/user.service';
import {
  LexofficeConnectionDataForDialog,
  LexofficeConnectionDialogComponent,
} from './components/lexoffice-connection-dialog.component';
import { LEXOFFICE_TAX_TYPE } from '../../../../models/enums/LexofficeTaxType.enum';
import { LEXOFFICE_BILLING_LANGUAGE } from '../../../../models/enums/LexofficeBillingLanguage.enum';
import { StringUtils } from '../../../../commons/utils/string.util';
import { LexofficeContactUtils } from '../../../../commons/utils/lexoffice-contact.utils';
import { convertEnumToArray } from '../../../../commons/utils/enum-to-array';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../services/business-object.service';
import BusinessObjectTableComponent from '../business-object-context/components/business-object-table/business-object-table.component';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterForm: {
      marginBottom: theme.spacing(1),
    },
  })
);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ width: '100%', maxWidth: '100%' }}>
          <Box p={3}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

enum LEXOFFICE_CONTACT_TYPE_FILTER {
  ALL = 'All',
  ONLY_ASSIGNED = 'Only assigned',
  ONLY_UNASSIGNED = 'Only unassigned',
}
const LEXOFFICE_CONTACT_TYPES = convertEnumToArray(LEXOFFICE_CONTACT_TYPE_FILTER);

enum LEXOFFICE_CONNECTION_TYPE_FILTER {
  ALL = 'All',
  ONLY_ASSIGNED_TO_SALON = 'Only assigned to salon',
  ONLY_ASSIGNED_TO_USER = 'Only assigned to user',
}
const LEXOFFICE_CONNECTION_TYPES = convertEnumToArray(LEXOFFICE_CONNECTION_TYPE_FILTER);

export default function LexofficePageComponent() {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const [lexofficeConnections, setLexofficeConnections] = React.useState<LexofficeConnection[]>([]);
  const [lexofficeConnectionType, setLexofficeConnectionType] = React.useState<LEXOFFICE_CONNECTION_TYPE_FILTER>(
    LEXOFFICE_CONNECTION_TYPE_FILTER.ALL
  );
  const [lexofficeConnectionKeywordFilter, setLexofficeConnectionKeywordFilter] = React.useState<string>(null);
  const [filteredLexofficeConnections, setFilteredLexofficeConnections] = React.useState<LexofficeConnection[]>([]);

  const [lexofficeContacts, setLexofficeContacts] = React.useState<LexofficeContact[]>([]);
  const [lexofficeContactType, setLexofficeContactType] = React.useState<LEXOFFICE_CONTACT_TYPE_FILTER>(
    LEXOFFICE_CONTACT_TYPE_FILTER.ALL
  );
  const [lexofficeContactKeywordFilter, setLexofficeContactKeywordFilter] = React.useState<string>(null);
  const [filteredLexofficeContacts, setFilteredLexofficeContacts] = React.useState<LexofficeContact[]>([]);

  const [users, setUsers] = React.useState<User[]>([]);
  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);

  const [businessObjectsKeywordFilter, setBusinessObjectsKeywordFilter] = React.useState<string>(null);
  const [unassignedBusinessObjects, setUnassignedBusinessObjects] = React.useState<BusinessObject[]>([]);

  const [lexofficeConnectionDataForDialog, setLexofficeConnectionDataForDialog] =
    React.useState<LexofficeConnectionDataForDialog>(null);
  const [isOpenLexofficeConnectionDialog, setIsOpenLexofficeConnectionDialog] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const _fetchAllLexofficeConnections = async () => {
    const data = await adminService.fetchAllLexofficeConnections();
    setLexofficeConnections(data || []);
  };

  const _fetchAllLexofficeContacts = async () => {
    const data = await adminService.fetchAllLexofficeContacts();
    setLexofficeContacts(data || []);
  };

  const _fetchAllBusinessOwnerUsers = async () => {
    const data = await userService.getAllBusinessOwnerUsers();
    setUsers(data);
  };

  const _fetchAllBusinessObjects = async () => {
    const data = await businessObjectService.getAllBusinessObjects();
    setBusinessObjects(data);
  };

  const _updateLexofficeConnectionToDisplay = () => {
    const keyword = StringUtils.simplifyString(lexofficeConnectionKeywordFilter);

    setFilteredLexofficeConnections(
      lexofficeConnections?.filter(c => {
        if (
          !!keyword?.length &&
          !StringUtils.simplifyString(c.userEmail).includes(keyword) &&
          !StringUtils.simplifyString(c.contactName).includes(keyword) &&
          !StringUtils.simplifyString(c.businessObjectName).includes(keyword)
        ) {
          return false;
        }

        if (
          lexofficeConnectionType === LEXOFFICE_CONNECTION_TYPE_FILTER.ONLY_ASSIGNED_TO_USER &&
          !!c.businessObjectId
        ) {
          return false;
        }

        if (
          lexofficeConnectionType === LEXOFFICE_CONNECTION_TYPE_FILTER.ONLY_ASSIGNED_TO_SALON &&
          !c.businessObjectId
        ) {
          return false;
        }

        return true;
      })
    );
  };

  const _updateLexofficeContactToDisplay = () => {
    const keyword = StringUtils.simplifyString(lexofficeContactKeywordFilter);

    setFilteredLexofficeContacts(
      lexofficeContacts?.filter(c => {
        if (
          !!keyword?.length &&
          !LexofficeContactUtils.isCompanyNameContainsString(keyword, c) &&
          !LexofficeContactUtils.isContactPersonContainsString(keyword, c) &&
          !LexofficeContactUtils.isBillingAddressContainsString(keyword, c) &&
          !LexofficeContactUtils.isAnyEmailContainsString(keyword, c) &&
          !LexofficeContactUtils.isAnyPhoneNumberContainsString(keyword, c)
        ) {
          return false;
        }

        const setOfAssignedLexofficeContact = new Set<string>(
          lexofficeConnections?.filter(connection => !!connection.userId)?.map(connection => connection.contactId)
        );

        if (
          lexofficeContactType === LEXOFFICE_CONTACT_TYPE_FILTER.ONLY_ASSIGNED &&
          !setOfAssignedLexofficeContact.has(c.id)
        ) {
          return false;
        }

        if (
          lexofficeContactType === LEXOFFICE_CONTACT_TYPE_FILTER.ONLY_UNASSIGNED &&
          setOfAssignedLexofficeContact.has(c.id)
        ) {
          return false;
        }

        return true;
      })
    );
  };

  const _updateUnassignedBusinessObjects = () => {
    const keyword = StringUtils.simplifyString(businessObjectsKeywordFilter);

    const setOfAssignedUserEmail = new Set<string>(
      lexofficeConnections?.filter(c => !c.businessObjectId)?.map(c => c.userEmail)
    );
    const setOfAssignedBoId = new Set<number>(lexofficeConnections?.map(c => c.businessObjectId)?.filter(id => !!id));

    setUnassignedBusinessObjects(
      businessObjects?.filter(bo => {
        if (setOfAssignedBoId.has(bo.id)) {
          return false;
        }

        if (setOfAssignedUserEmail.has(bo.owner)) {
          return false;
        }

        if (!keyword?.length) {
          return true;
        }

        return (
          StringUtils.simplifyString(bo.name)?.includes(keyword) ||
          StringUtils.simplifyString(bo.address)?.includes(keyword) ||
          StringUtils.simplifyString(bo.owner)?.includes(keyword)
        );
      })
    );
  };

  const _init = async () => {
    setIsLoading(true);

    try {
      await Promise.all([
        _fetchAllLexofficeConnections(),
        _fetchAllLexofficeContacts(),
        _fetchAllBusinessOwnerUsers(),
        _fetchAllBusinessObjects(),
      ]);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    _init();
  }, []);

  React.useEffect(() => {
    _updateLexofficeConnectionToDisplay();
  }, [lexofficeConnections, lexofficeConnectionType, lexofficeConnectionKeywordFilter]);

  React.useEffect(() => {
    _updateLexofficeContactToDisplay();
  }, [lexofficeContacts, lexofficeContactType, lexofficeContactKeywordFilter]);

  React.useEffect(() => {
    _updateUnassignedBusinessObjects();
  }, [lexofficeConnections, businessObjects, businessObjectsKeywordFilter]);

  const closeLexofficeConnectionDialog = () => {
    setIsOpenLexofficeConnectionDialog(false);
    setLexofficeConnectionDataForDialog(null);
  };

  const onCloseLexofficeConnectionDialog = () => {
    if (isLoading) {
      return;
    }

    closeLexofficeConnectionDialog();
  };

  const _openLexofficeConnectionDialog = (
    lexofficeConnection: LexofficeConnection,
    preselectedLexofficeContact: LexofficeContact,
    preselectedBusinessObject: BusinessObject
  ) => {
    if (isLoading) {
      return;
    }

    setLexofficeConnectionDataForDialog({
      users,
      businessObjects,
      lexofficeContacts,
      lexofficeConnections,
      preselectedBusinessObject,
      preselectedLexofficeContact,
      connection: lexofficeConnection,
    });

    setIsOpenLexofficeConnectionDialog(true);
  };

  const onSelectLexofficeConnection = (lexofficeConnection: LexofficeConnection) => {
    _openLexofficeConnectionDialog(lexofficeConnection, null, null);
  };

  const onSelectLexofficeContact = (lexofficeContact: LexofficeContact) => {
    _openLexofficeConnectionDialog(null, lexofficeContact, null);
  };

  const onSelectUnassignedBusinessObject = (businessObject: BusinessObject) => {
    _openLexofficeConnectionDialog(null, null, businessObject);
  };

  const onCreateLexofficeConnection = async (
    userId: number,
    contact: LexofficeContact,
    taxType: LEXOFFICE_TAX_TYPE,
    language: LEXOFFICE_BILLING_LANGUAGE,
    businessObjectId?: number,
    businessObjectName?: string
  ) => {
    setIsLoading(true);

    try {
      const createdLexofficeConnection = await adminService.createLexofficeConnection(
        userId,
        contact,
        taxType,
        language,
        businessObjectId,
        businessObjectName
      );

      if (createdLexofficeConnection?.id) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateLexofficeConnection = async (userId: number, connectionToUpdate: LexofficeConnection) => {
    setIsLoading(true);

    try {
      const updatedLexofficeConnection = await adminService.updateLexofficeConnection(userId, connectionToUpdate);

      if (updatedLexofficeConnection?.id) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteLexofficeConnection = async (userId: number, connectionId: number) => {
    setIsLoading(true);

    try {
      const isSuccess = await adminService.deleteLexofficeConnection(userId, connectionId);

      if (isSuccess) {
        await _init();
        closeLexofficeConnectionDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const tabChange = (event: any, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const classes = useStyles();

  return (
    <>
      {isLoading ? <LinearProgress /> : <></>}

      {isOpenLexofficeConnectionDialog ? (
        <LexofficeConnectionDialogComponent
          isOpen={isOpenLexofficeConnectionDialog}
          isLoading={isLoading}
          data={lexofficeConnectionDataForDialog}
          onClose={onCloseLexofficeConnectionDialog}
          onCreate={onCreateLexofficeConnection}
          onUpdate={onUpdateLexofficeConnection}
          onDelete={onDeleteLexofficeConnection}
        ></LexofficeConnectionDialogComponent>
      ) : (
        <></>
      )}

      <Tabs
        value={selectedTab}
        onChange={tabChange}
        aria-label="Lexoffice contacts tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={'Lexoffice connections (' + (lexofficeConnections?.length || 0) + ')'} {...a11yProps(0)} />
        <Tab label={'Lexoffice contacts (' + (lexofficeContacts?.length || 0) + ')'} {...a11yProps(1)} />
        <Tab label={'Unassigned salons (' + (unassignedBusinessObjects?.length || 0) + ')'} {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <Grid container spacing={3} className={classes.filterForm}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              select
              fullWidth
              label="Type"
              name="type"
              value={lexofficeConnectionType || ''}
              onChange={event => setLexofficeConnectionType(event.target.value as LEXOFFICE_CONNECTION_TYPE_FILTER)}
            >
              {LEXOFFICE_CONNECTION_TYPES.map(item => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              label="Search"
              name="search"
              value={lexofficeConnectionKeywordFilter || ''}
              onChange={event => setLexofficeConnectionKeywordFilter(event.target.value)}
            />
          </Grid>
        </Grid>

        <LexofficeConnectionTableComponent
          lexofficeConnections={filteredLexofficeConnections}
          onSelect={onSelectLexofficeConnection}
        ></LexofficeConnectionTableComponent>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <Grid container spacing={3} className={classes.filterForm}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              select
              fullWidth
              label="Type"
              name="type"
              value={lexofficeContactType || ''}
              onChange={event => setLexofficeContactType(event.target.value as LEXOFFICE_CONTACT_TYPE_FILTER)}
            >
              {LEXOFFICE_CONTACT_TYPES.map(item => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              label="Search"
              name="search"
              value={lexofficeContactKeywordFilter || ''}
              onChange={event => setLexofficeContactKeywordFilter(event.target.value)}
            />
          </Grid>
        </Grid>

        <LexofficeContactTableComponent
          lexofficeContacts={filteredLexofficeContacts}
          onSelect={onSelectLexofficeContact}
        ></LexofficeContactTableComponent>
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <TextField
          style={{ marginBottom: '20px' }}
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          label="Search"
          name="search"
          value={businessObjectsKeywordFilter || ''}
          onChange={event => setBusinessObjectsKeywordFilter(event.target.value)}
        />

        <BusinessObjectTableComponent
          businessObjects={unassignedBusinessObjects}
          onClick={onSelectUnassignedBusinessObject}
        ></BusinessObjectTableComponent>
      </TabPanel>
    </>
  );
}
