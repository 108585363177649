import React from 'react';
import CustomDialogComponent from '../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, TextField } from '@material-ui/core';

interface CreateNewUserTokenDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: Function;
  onClose: Function;
}

interface AuthInfo {
  email: string;
  password: string;
}

export default function CreateNewUserTokenDialogComponent(props: CreateNewUserTokenDialogComponentProps) {
  const [authInfo, setAuthInfo] = React.useState<AuthInfo>(null);

  React.useEffect(() => {
    if (!props.isOpen) {
      setAuthInfo(null);
    }
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    setAuthInfo({
      ...(authInfo || ({} as AuthInfo)),
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const getTitle = () => {
    return 'Add new user by login with email and password';
  };

  const getContent = () => {
    return (
      <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={authInfo?.email || ''}
          onChange={submitDataChangeHandler}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={authInfo?.password || ''}
          autoComplete="current-password"
          onChange={submitDataChangeHandler}
        />
      </>
    );
  };

  const getButtons = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            onClick={() => props.onSubmit(authInfo.email, authInfo.password)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
