import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import CategoryTableComponent from '../../components/category-table/category-table.component';
import { Link } from 'react-router-dom';
import React from 'react';
import { getBoDetailPath } from '../../../business-object-context/routing/business-object-route-definition.model';

export default function CategoryListComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;

  return (
    <div>
      <CategoryTableComponent
        userId={user?.id}
        businessObjectId={businessObject?.id}
        categories={businessObject?.categories}
      ></CategoryTableComponent>

      <div>
        <Link to={`${getBoDetailPath(user?.id, businessObject?.id)}`}>Back to business object detail</Link>
      </div>
    </div>
  );
}
