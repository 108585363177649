import { PRIVATE_ROUTE } from '../../../routes/route-definition.model';

const PRIVATE_FRAME_ROUTE = {
  ACCOUNT: `${PRIVATE_ROUTE.FRAME}profile`,
  ACCOUNT_PASSWORD: `${PRIVATE_ROUTE.FRAME}password`,
  STATISTIC: `${PRIVATE_ROUTE.FRAME}statistic`,
  USER: `${PRIVATE_ROUTE.FRAME}user`,
  IMPORT_CUSTOMER_CSV: `${PRIVATE_ROUTE.FRAME}import-customer`,
  CREDIT_PACKAGE_ORDER: `${PRIVATE_ROUTE.FRAME}credit-package-order`,
  SYSTEM_WALLET: `${PRIVATE_ROUTE.FRAME}system-wallet`,
  CREDIT_PACKAGE_MANAGEMENT: `${PRIVATE_ROUTE.FRAME}credit-package-management`,
  SERVICE_TAG_MANAGEMENT: `${PRIVATE_ROUTE.FRAME}service-tag-management`,
  SHORTENED_LINK: `${PRIVATE_ROUTE.FRAME}shortened-link`,
  USER_WATCHLIST: `${PRIVATE_ROUTE.FRAME}user-watchlist`,
  LEXOFFICE: `${PRIVATE_ROUTE.FRAME}lexoffice`,
  INVOICE_JOB: `${PRIVATE_ROUTE.FRAME}invoice-job`,
};

const DEFAULT_PRIVATE_ROUTE = PRIVATE_FRAME_ROUTE.STATISTIC;

export { PRIVATE_FRAME_ROUTE, DEFAULT_PRIVATE_ROUTE };
