import authenticationHelperService from './auth-helper.service';
import backendCallerService from './backend-caller.service';
import { User } from '../models/interfaces/User.interface';
import { AUTHORITY_ROLE_WITH_ACCESS } from '../models/consts/AuthorityRole.const';
import storageService from './storage.service';

class AuthenticationService {
  public canUserAccessApp(user: User): boolean {
    return !!user?.role && AUTHORITY_ROLE_WITH_ACCESS?.includes(user.role);
  }

  public async login(email: string, password: string): Promise<boolean> {
    const url: string = '/authorization';

    try {
      const response = await backendCallerService.post(url, {
        username: email,
        password,
        __type__: 'basic-credentials',
      });

      authenticationHelperService.setAuthToken(response?.header?.authorization);

      return true;
    } catch (e) {
      return null;
    }
  }

  public async loginAndReturnTokenOnly(email: string, password: string): Promise<string> {
    const url: string = '/authorization';

    try {
      const response = await backendCallerService.post(url, {
        username: email,
        password,
        __type__: 'basic-credentials',
      });

      return response?.header?.authorization;
    } catch (e) {
      return null;
    }
  }

  public logout(): void {
    storageService.clear();
  }
}

const authenticationService = new AuthenticationService();

export default authenticationService;
