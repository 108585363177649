import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { User } from '../../../../../../models/interfaces/User.interface';
import logger from '../../../../../../commons/logger';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, InputAdornment, MenuItem, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import smsService from '../../../../../../services/sms.service';
import { CreditOrder } from '../../../../../../models/interfaces/CreditOrder.interface';
import { CommunicationUnitPackage } from '../../../../../../models/interfaces/CommunicationUnitPackage.interface';
import { LexofficeContact } from '../../../../../../models/interfaces/LexofficeContact.interface';
import { LexofficeContactUtils } from '../../../../../../commons/utils/lexoffice-contact.utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface CreditOrderRequestFormComponentProps {
  user: User;
  admin: User;
  creditPackages: CommunicationUnitPackage[];
  lexofficeContactsOfUser?: LexofficeContact[];
  creditBudget?: number;
}

export default function CreditOrderRequestFormComponent(props: CreditOrderRequestFormComponentProps) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [creditOrder, setCreditOrder] = React.useState<CreditOrder>({});

  const listOfCreditPackages: CommunicationUnitPackage[] =
    props.creditPackages?.filter(
      creditPackage =>
        props?.user?.locale?.toString().includes(creditPackage.countryCode) &&
        creditPackage.provider === props?.user?.smsProvider
    ) || [];

  const generateDescription = (numberOfCredit: number, price: number) => {
    return `Admin [${props?.admin?.email}] requests for user ${props?.user?.fullName} [${props?.user?.email}] package ${numberOfCredit} Credit with price ${price} EUR (without VAT)`;
  };

  const selectedCreditPackageChangeHandler = (event: any) => {
    const { value } = event.target;

    const price = listOfCreditPackages?.find(p => p.balance === value)?.price;

    setCreditOrder({
      ...(creditOrder || {}),
      balance: value,
      price,
      description: generateDescription(value, price),
    });
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      const createdCreditOrder: CreditOrder = await smsService.createCreditOrderForUser(props?.user?.id, creditOrder);

      if (createdCreditOrder?.id) {
        setCreditOrder({});
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          Request buying Credit package
        </Typography>

        <div>
          Current Credit budget:{' '}
          <strong>
            <big>{props?.creditBudget || 0}</big> Credit
          </strong>
        </div>
      </Toolbar>

      <form className={classes.form} onSubmit={submit}>
        <TextField
          variant="outlined"
          margin="dense"
          select
          fullWidth
          label="Lexoffice contact"
          name="billingContactId"
          value={creditOrder?.billingContactId || ''}
          onChange={(event: any) => setCreditOrder({ ...(creditOrder || {}), billingContactId: event.target.value })}
        >
          {props?.lexofficeContactsOfUser?.map(lexofficeContact => (
            <MenuItem value={lexofficeContact.id} key={lexofficeContact.id}>
              {LexofficeContactUtils.generateContactPersonAsString(lexofficeContact)} (
              {LexofficeContactUtils.generateBillingAddressAsString(lexofficeContact)})
            </MenuItem>
          ))}
        </TextField>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Number of Credit*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Number of Credit"
              name="balance"
              value={creditOrder?.balance || ''}
              disabled={isSubmitting}
              onChange={selectedCreditPackageChangeHandler}
              placeholder="Please select number of Credit"
            >
              {listOfCreditPackages.map(creditPackage => (
                <MenuItem value={creditPackage.balance} key={creditPackage.balance}>
                  {creditPackage.balance} Credit
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            {/*Price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              key="price"
              id="price"
              label="Price"
              name="price"
              value={creditOrder?.price || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
              disabled={true}
            />
          </Grid>
        </Grid>

        {/*Description*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          required={true}
          multiline
          key="description"
          label="Description"
          name="description"
          value={creditOrder?.description || ''}
          disabled={true}
        />

        {/*Submit button*/}
        {
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
            startIcon={<AddShoppingCartIcon />}
          >
            Submit
          </Button>
        }
      </form>
    </Paper>
  );
}
