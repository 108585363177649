import React from 'react';
import { User } from '../../../../../../../../models/interfaces/User.interface';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link, Redirect } from 'react-router-dom';
import { ProductCategory } from '../../../../../../../../models/interfaces/ProductCategory.interface';
import CategoryFormComponent from '../../../../components/category-form/category-form.component';
import ProductTableComponent from '../../../../../product-context/components/product-table/product-table.component';
import { getBoDetailPath } from '../../../../../business-object-context/routing/business-object-route-definition.model';
import { BusinessObject } from '../../../../../../../../models/interfaces/BusinessObject.interface';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getProductCreatePath } from '../../../../../product-context/routing/product-route-definition.model';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createNewProductButton: {
      marginBottom: '20px',
    },
  })
);

export default function CategoryDetailComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;
  const category: ProductCategory = props.category;

  const [redirectToCreateProductPage, setRedirectToCreateProductPage] = React.useState<string>(null);

  const handleCreateProduct = () => {
    setRedirectToCreateProductPage(getProductCreatePath(user?.id, businessObject?.id, category?.id));
  };

  const classes = useStyles();

  return redirectToCreateProductPage ? (
    <Redirect to={redirectToCreateProductPage} />
  ) : (
    <>
      <CategoryFormComponent ownerId={user?.id} businessObjectId={businessObject?.id} category={category} />

      <Button
        onClick={handleCreateProduct}
        variant="contained"
        color="primary"
        className={classes.createNewProductButton}
        startIcon={<Add />}
      >
        Create new product
      </Button>

      <ProductTableComponent
        userId={user?.id}
        businessObjectId={businessObject?.id}
        categoryId={category?.id}
        products={category?.products}
      />

      <div>
        <Link to={getBoDetailPath(user?.id, businessObject?.id)}>Back to business object</Link>
      </div>
    </>
  );
}
