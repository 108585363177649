import { BusinessObject } from '../models/interfaces/BusinessObject.interface';
import backendCallerService from './backend-caller.service';
import { ProductCategory } from '../models/interfaces/ProductCategory.interface';
import { Product } from '../models/interfaces/Product.interface';
import { __TYPE__ } from '../models/enums/__Type__.enum';
import { DEFAULT_CATEGORY_NAME, SERVICE_TAG_DELIMITER } from '../models/consts/Other.const';
import { CURRENCY } from '../models/enums/Currency.enum';
import { BUSINESS_OBJECT_VERSION } from '../models/enums/BusinessObjectVersion.enum';
import { User } from '../models/interfaces/User.interface';
import { USER_ROLE } from '../models/enums/UserRole.enum';

class BusinessObjectService {
  // Business object
  public createBasicBusinessObjects(owner: User): BusinessObject {
    let boVersion = BUSINESS_OBJECT_VERSION.PRO;
    if (owner?.role === USER_ROLE.USER_LITE) {
      boVersion = BUSINESS_OBJECT_VERSION.LITE;
    } else if (owner?.role === USER_ROLE.USER_LITE_PLUS) {
      boVersion = BUSINESS_OBJECT_VERSION.LITE_PLUS;
    }

    let smsCheckoutTemplate = 'Thank you for your visit! We look forward to your feedback via {reviewUrl}';
    if (owner.locale?.includes('de')) {
      smsCheckoutTemplate = 'Vielen Dank für Ihren Besuch! Wir freuen uns auf Ihr Feedback über {reviewUrl}';
    } else if (owner.locale?.includes('fr')) {
      smsCheckoutTemplate = 'Nous vous remercions de votre visite ! Nous attendons vos commentaires via {reviewUrl}';
    } else if (owner?.locale?.includes('cz')) {
      smsCheckoutTemplate = 'Děkujeme vám za návštěvu! Těšíme se na vaši zpětnou vazbu prostřednictvím {reviewUrl}';
    }

    let currency = CURRENCY.EUR;
    if (owner?.locale?.includes('US')) {
      currency = CURRENCY.USD;
    } else if (owner?.locale?.includes('CZ')) {
      currency = CURRENCY.CZK;
    } else if (owner?.locale?.includes('CH')) {
      currency = CURRENCY.CHF;
    }

    return {
      __type__: __TYPE__.BASIC_BUSINESS_OBJECT,
      owner: owner?.email,
      currency,
      smsCheckoutTemplate,
      spendAmountToBonusPointConversionRate: 1,
      locale: owner?.locale,
      rwgActive: false,
      sendFeedbackOnGmbFiveStars: false,
      version: boVersion,
      serviceTagsList: [],
    };
  }

  public async getAllBusinessObjects(): Promise<BusinessObject[]> {
    const url = '/v1/admin/business-objects';

    const response = await backendCallerService.get(url);

    return response?.body?.map((bo: BusinessObject) => this.transformBoForFrontend(bo));
  }

  public async getAllBusinessObjectsOfUser(userEmail: string): Promise<BusinessObject[]> {
    const url = `/v1/owner/${userEmail}/business-objects`;

    const response = await backendCallerService.get(url);

    return response?.body?.map((bo: BusinessObject) => this.transformBoForFrontend(bo));
  }

  public async getAllBusinessObjectsOfUserByToken(token: string): Promise<BusinessObject[]> {
    const url = `/v1/business-objects`;

    const response = await backendCallerService.get(url, backendCallerService.generateHeaderWithToken(token));
    return response?.body?.map((bo: BusinessObject) => this.transformBoForFrontend(bo));
  }

  public async getBusinessObjectById(userEmail: string, businessObjectId: number): Promise<BusinessObject> {
    const url = `/v1/owner/${userEmail}/business-object/${businessObjectId}`;

    const response = await backendCallerService.get(url);

    return this.transformBoForFrontend(response?.body);
  }

  public async createBusinessObject(businessObject: BusinessObject): Promise<BusinessObject> {
    const url = `/v1/owner/${businessObject.owner}/business-object`;

    const response = await backendCallerService.post(url, businessObject);

    return this.transformBoForFrontend(response?.body);
  }

  public async updateBusinessObject(businessObject: BusinessObject): Promise<BusinessObject> {
    const url = `/v1/owner/${businessObject.owner}/business-object/${businessObject.id}`;

    await backendCallerService.put(url, businessObject);

    return businessObject;
  }

  // Category
  public createBasicCategory(ownerEmail: string, boId: number): ProductCategory {
    return {
      __type__: __TYPE__.BASIC_PRODUCT_CATEGORY,
      ownerEmail,
      businessObjectId: boId,
    };
  }

  public async getCategoryById(ownerEmail: string, boId: number, categoryId: number): Promise<ProductCategory> {
    const url = `/v1/owner/${ownerEmail}/business-object/${boId}/category/${categoryId}`;

    const response = await backendCallerService.get(url);

    return this.transformCategoryForFrontend(response?.body, ownerEmail, boId);
  }

  public async createCategoryForBo(category: ProductCategory): Promise<ProductCategory> {
    const url = `/v1/owner/${category.ownerEmail}/business-object/${category.businessObjectId}/category`;

    const response = await backendCallerService.post(url, category);

    return this.transformCategoryForFrontend(response?.body, category.ownerEmail, category.businessObjectId);
  }

  public async updateCategory(category: ProductCategory): Promise<ProductCategory> {
    const url = `/v1/owner/${category.ownerEmail}/business-object/${category.businessObjectId}/category/${category.id}`;

    await backendCallerService.put(url, category);

    return category;
  }

  public async deleteCategory(category: ProductCategory): Promise<boolean> {
    const url = `/v1/owner/${category.ownerEmail}/business-object/${category.businessObjectId}/category/${category.id}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 200;
  }

  // Product
  public createBasicProduct(ownerEmail: string, boId: number, categoryId: number): Product {
    return {
      __type__: __TYPE__.BASIC_PRODUCT,
      ownerEmail,
      businessObjectId: boId,
      categoryId,
      enabled4Booking: true,
    };
  }

  public async getProductById(
    ownerEmail: string,
    boId: number,
    categoryId: number,
    productId: number
  ): Promise<Product> {
    const url = `/v1/owner/${ownerEmail}/business-object/${boId}/category/${categoryId}/product/${productId}`;

    const response = await backendCallerService.get(url);

    return this.transformProductForFrontend(response?.body, ownerEmail, boId, categoryId);
  }

  public async createProductForCategory(product: Product): Promise<Product> {
    const url = `/v1/owner/${product.ownerEmail}/business-object/${product.businessObjectId}/category/${product.categoryId}/product`;

    const response = await backendCallerService.post(url, product);

    return this.transformProductForFrontend(
      response?.body,
      product.ownerEmail,
      product.businessObjectId,
      product.categoryId
    );
  }

  public async updateProduct(product: Product): Promise<Product> {
    const url = `/v1/owner/${product.ownerEmail}/business-object/${product.businessObjectId}/category/${product.categoryId}/product/${product.id}`;

    await backendCallerService.put(url, product);

    return product;
  }

  public async deleteProduct(product: Product): Promise<boolean> {
    const url = `/v1/owner/${product.ownerEmail}/business-object/${product.businessObjectId}/product/${product.id}`;

    const response = await backendCallerService.delete(url);

    return response?.status === 200;
  }

  // Import customer as CSV
  public async importCustomerCsv(businessObjectId: number, customerCsv: File): Promise<boolean> {
    const url = `/v1/business-object/${businessObjectId}/customers/tw-import`;

    const formData = new FormData();
    formData.append('file', customerCsv);

    const response = await backendCallerService.post(url, formData, backendCallerService.getEmptyHeader(), true);

    return response?.status === 200;
  }

  // Transformers
  private transformBoForFrontend(bo: BusinessObject): BusinessObject {
    if (!bo || !bo.categories) {
      return bo;
    }

    bo.categories = bo.categories
      .filter(cat => cat.name !== DEFAULT_CATEGORY_NAME)
      .map(category => this.transformCategoryForFrontend(category, bo.owner, bo.id));

    bo.categories.sort((c1, c2) => {
      if (c1.id < c2.id) {
        return -1;
      }
      if (c1.id > c2.id) {
        return 1;
      }
      return 0;
    });

    bo.serviceTagsList = bo.serviceTags?.length ? bo.serviceTags.split(SERVICE_TAG_DELIMITER) : [];

    return bo;
  }

  private transformCategoryForFrontend(category: ProductCategory, ownerEmail: string, boId: number): ProductCategory {
    category.businessObjectId = boId;
    category.ownerEmail = ownerEmail;

    if (!category?.products?.length) {
      return category;
    }

    category.products = category.products.map(p => this.transformProductForFrontend(p, ownerEmail, boId, category.id));

    category.products.sort((p1, p2) => {
      if (p1.id < p2.id) {
        return -1;
      }
      if (p1.id > p2.id) {
        return 1;
      }
      return 0;
    });

    return category;
  }

  private transformProductForFrontend(product: Product, ownerEmail: string, boId: number, categoryId: number): Product {
    if (product) {
      product.categoryId = categoryId;
      product.businessObjectId = boId;
      product.ownerEmail = ownerEmail;
    }

    return product;
  }
}

const businessObjectService = new BusinessObjectService();

export default businessObjectService;
