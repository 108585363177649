import { Redirect, Route } from 'react-router';
import authenticationHelperService from '../services/auth-helper.service';
import { PUBLIC_ROUTE } from './route-definition.model';
import React from 'react';
import { User } from '../models/interfaces/User.interface';
import { BusinessObject } from '../models/interfaces/BusinessObject.interface';
import { ProductCategory } from '../models/interfaces/ProductCategory.interface';
import { Product } from '../models/interfaces/Product.interface';

interface PropsData {
  user: User;
  businessObject: BusinessObject;
  category: ProductCategory;
  product: Product;
}

export default function PrivateRoute(props: any) {
  const isAuthenticated = authenticationHelperService.isAuthenticated();

  const { component: Component, ...rest } = props;

  const propsData: PropsData = {
    user: props?.user,
    businessObject: props?.businessObject,
    category: props?.category,
    product: props?.product,
  };

  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthenticated ? (
          <Component {...propsData} {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: PUBLIC_ROUTE.AUTH_PROCESS,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}
