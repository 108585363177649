import React from 'react';
import { User } from '../../../../../../models/interfaces/User.interface';
import { RANGE_VIEW_MODE } from '../../../../../../models/enums/RangeViewMode.enum';
import { DateRange } from '../../../../../../models/interfaces/DateRange.interface';
import { Chart } from 'chart.js';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { RangeViewModeUtils } from '../../../../../../commons/utils/RangeViewMode.utils';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import logger from '../../../../../../commons/logger';
import chartJsLineChartService from '../../../../../../services/chart-js-line-chart.service';
import { ChartJsDataSet } from '../../../../../../models/interfaces/ChartJsDataSet.interface';
import { DateTimeUtils } from '../../../../../../commons/utils/date-time.utils';
import { DATE_FORMAT } from '../../../../../../models/enums/DateFormat.enum';
import { NumberUtils } from '../../../../../../commons/utils/number.utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      marginTop: '20px',
      marginBottom: '40px',
      minHeight: '400px',
      maxHeight: '500px',
      width: '100%',
    },
  })
);

interface UserChartComponentProps {
  users: User[];
}

export default function UserChartComponent(props: UserChartComponentProps) {
  const [viewMode, setViewMode] = React.useState<RANGE_VIEW_MODE>(null);
  const [dateRange, setDateRange] = React.useState<DateRange>(null);
  const [myChart, setMyChart] = React.useState<Chart>(undefined);

  const VIEW_MODE_OPTIONS = [RANGE_VIEW_MODE.DAY, RANGE_VIEW_MODE.MONTH, RANGE_VIEW_MODE.YEAR];
  const CHART_ID = 'UserChartComponent';

  const renderChart = () => {
    if (!viewMode || !dateRange?.fromDate || !dateRange?.toDate) {
      return;
    }

    try {
      const labels: string[] = [];
      const mapOfLabelAndData = new Map<string, number>();

      if (viewMode === RANGE_VIEW_MODE.DAY) {
        const listOfDate: Date[] = DateTimeUtils.getListOfDate(dateRange.fromDate, dateRange.toDate);
        listOfDate?.forEach(d => {
          const l = DateTimeUtils.formatDateToString(d, DATE_FORMAT.DATE_FORMAT_BACKEND);
          labels.push(l);
          mapOfLabelAndData.set(l, 0);
        }); // YYYY-MM-DD

        props?.users?.forEach(u => {
          if (u?.createdAtAsDate) {
            const createdAtAsStr = DateTimeUtils.formatDateToString(u.createdAtAsDate, DATE_FORMAT.DATE_FORMAT_BACKEND);
            const matchedValue = mapOfLabelAndData.get(createdAtAsStr);

            if (NumberUtils.isNumber(matchedValue) && matchedValue >= 0) {
              mapOfLabelAndData.set(createdAtAsStr, matchedValue + 1);
            }
          }
        });
      } else if (viewMode === RANGE_VIEW_MODE.MONTH) {
        const listOfMonths: Date[] = DateTimeUtils.getListOfMonth(dateRange.fromDate, dateRange.toDate);
        listOfMonths?.forEach(m => {
          const l = DateTimeUtils.formatDateToString(m, DATE_FORMAT.DATE_FORMAT_BACKEND).slice(0, -3);
          labels.push(l);
          mapOfLabelAndData.set(l, 0);
        }); // YYYY-MM

        props?.users?.forEach(u => {
          if (u?.createdAtAsDate) {
            const createdAtAsStr = DateTimeUtils.formatDateToString(
              u.createdAtAsDate,
              DATE_FORMAT.DATE_FORMAT_BACKEND
            ).slice(0, -3);
            const matchedValue = mapOfLabelAndData.get(createdAtAsStr);

            if (NumberUtils.isNumber(matchedValue) && matchedValue >= 0) {
              mapOfLabelAndData.set(createdAtAsStr, matchedValue + 1);
            }
          }
        });
      } else if (viewMode === RANGE_VIEW_MODE.YEAR) {
        const listOfYear = DateTimeUtils.getListOfYear(dateRange.fromDate, dateRange.toDate);
        listOfYear?.forEach(y => {
          const l = DateTimeUtils.formatDateToString(y, DATE_FORMAT.DATE_FORMAT_BACKEND).slice(0, -6);
          labels.push(l);
          mapOfLabelAndData.set(l, 0);
        }); // YYYY

        props?.users?.forEach(u => {
          if (u?.createdAtAsDate) {
            const createdAtAsStr = DateTimeUtils.formatDateToString(
              u.createdAtAsDate,
              DATE_FORMAT.DATE_FORMAT_BACKEND
            ).slice(0, -6);
            const matchedValue = mapOfLabelAndData.get(createdAtAsStr);

            if (NumberUtils.isNumber(matchedValue) && matchedValue >= 0) {
              mapOfLabelAndData.set(createdAtAsStr, matchedValue + 1);
            }
          }
        });
      }

      // generated chart
      const numberOfUser: ChartJsDataSet = {
        label: 'Number of new users',
        backgroundColor: 'blue',
        borderColor: 'blue',
        data: labels?.map(l => mapOfLabelAndData.get(l) || 0),
      };

      const chartJsDataSet: ChartJsDataSet[] = [numberOfUser];

      const ctx = document.getElementById(CHART_ID);

      if (ctx) {
        const chartConfig = chartJsLineChartService.getStackedBarChartOption(chartJsDataSet, labels);

        if (myChart) {
          myChart.destroy();
        }

        setMyChart(new Chart(ctx as HTMLCanvasElement, chartConfig));
      } else {
        logger.debug(`ERROR: not found id=${CHART_ID}`);
      }
    } catch (e) {
      logger.error(e);
    }
  };

  React.useEffect(() => {
    setViewMode(RANGE_VIEW_MODE.DAY);
  }, []);

  React.useEffect(() => {
    renderChart();
  }, [props.users]);

  React.useEffect(() => {
    updateDateRangeOnViewModeChange(viewMode);
  }, [viewMode]);

  React.useEffect(() => {
    renderChart();
  }, [dateRange]);

  const updateDateRangeOnViewModeChange = (selectedViewMode: RANGE_VIEW_MODE) => {
    setDateRange(RangeViewModeUtils.generateEarliestDateRangeFromViewMode(selectedViewMode));
  };

  const changeTimeRangeWindow = (isNext: boolean) => {
    const newDateRange: DateRange = isNext
      ? RangeViewModeUtils.getNextDateRange(dateRange, viewMode)
      : RangeViewModeUtils.getPreviousDateRange(dateRange, viewMode);
    setDateRange(newDateRange);
  };

  const previousClickHandler = () => {
    changeTimeRangeWindow(false);
  };

  const currentClickHandler = () => {
    updateDateRangeOnViewModeChange(viewMode);
  };

  const nextClickHandler = () => {
    changeTimeRangeWindow(true);
  };

  const updateViewModeHandler = (event: any) => {
    const { value } = event.target;
    setViewMode(value);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            onClick={previousClickHandler}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Button color="primary" onClick={currentClickHandler}>
            {viewMode === RANGE_VIEW_MODE.DAY ? 'Today' : 'This month'}
          </Button>

          <IconButton aria-controls="customized-menu" aria-haspopup="true" color="primary" onClick={nextClickHandler}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            fullWidth
            label="View mode"
            name="viewMode"
            value={viewMode || ''}
            onChange={updateViewModeHandler}
          >
            {VIEW_MODE_OPTIONS.map(m => (
              <MenuItem value={m} key={m}>
                {m}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <div className={classes.chartWrapper}>
        <canvas id={CHART_ID}></canvas>
      </div>
    </>
  );
}
