import React from 'react';
import smsService from '../../../../services/sms.service';
import { BulkgateWalletInfoData } from '../../../../models/interfaces/smsProviderInterfaces/BulkgateWalletInfoData.interface';
import SmsProviderPriceTableComponent, { SmsProviderPrice } from './components/sms-provider-price-table.component';
import { Chart } from 'chart.js';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DateTimeUtils } from '../../../../commons/utils/date-time.utils';
import { DATE_FORMAT } from '../../../../models/enums/DateFormat.enum';
import { ChartJsDataSet } from '../../../../models/interfaces/ChartJsDataSet.interface';
import chartJsLineChartService from '../../../../services/chart-js-line-chart.service';
import logger from '../../../../commons/logger';
import { RANGE_VIEW_MODE } from '../../../../models/enums/RangeViewMode.enum';
import { DateRange } from '../../../../models/interfaces/DateRange.interface';
import { RangeViewModeUtils } from '../../../../commons/utils/RangeViewMode.utils';
import { DailyCreditUsageStatistic } from '../../../../models/interfaces/DailyCreditUsageStatistic.interface';
import { Button, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GatewayApiBalance } from '../../../../models/interfaces/smsProviderInterfaces/GatewayApiBalance.interface';
import smsProviderService from '../../../../services/sms-provider.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      marginTop: '20px',
      marginBottom: '40px',
      minHeight: '400px',
      maxHeight: '500px',
      width: '100%',
    },
  })
);

export default function SystemSmsBudgetComponent(props: any) {
  const [bulkgateWallet, setBulkgateWallet] = React.useState<BulkgateWalletInfoData>(null);
  const [gatewayApiBalance, setGatewayApiBalance] = React.useState<GatewayApiBalance>(null);

  const [smsProviderPriceData, setSmsProviderPriceData] = React.useState<SmsProviderPrice[]>([]);

  const [viewMode, setViewMode] = React.useState<RANGE_VIEW_MODE>(null);
  const [dateRange, setDateRange] = React.useState<DateRange>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [myChart, setMyChart] = React.useState<Chart>(undefined);

  const COUNTRY_OPTIONS = ['DE', 'CH', 'CZ', 'NL', 'FR', 'AT'];
  const VIEW_MODE_OPTIONS = [RANGE_VIEW_MODE.DAY, RANGE_VIEW_MODE.MONTH];
  const CHART_ID = 'SystemSmsBudgetComponent';

  const fetchDataAndRerenderChart = async () => {
    if (!viewMode || !dateRange?.fromDate || !dateRange?.toDate) {
      return;
    }

    setIsLoading(true);

    try {
      // fetch data
      let matchedData: DailyCreditUsageStatistic[] = [];

      if (viewMode === RANGE_VIEW_MODE.DAY) {
        const rawData = await smsService.getDailyCreditUsageStatistic(dateRange.fromDate, dateRange.toDate);

        const listOfDate: Date[] = DateTimeUtils.getListOfDate(dateRange.fromDate, dateRange.toDate);
        const listOfDatesAsStr = listOfDate?.map(d =>
          DateTimeUtils.formatDateToString(d, DATE_FORMAT.DATE_FORMAT_BACKEND)
        ); // YYYY-MM-DD

        const mapOfDateAndData = new Map<string, DailyCreditUsageStatistic>(
          listOfDatesAsStr?.map(dateAsString => [
            dateAsString,
            {
              date: dateAsString,
              numberOfSentSms: 0,
              numberOfSentEmail: 0,
              emailCost: 0,
              smsCost: 0,
            },
          ])
        );

        rawData?.forEach(data => mapOfDateAndData.set(data.date, data));

        mapOfDateAndData.forEach(value => matchedData.push(value));
      } else if (viewMode === RANGE_VIEW_MODE.MONTH) {
        const rawData = await smsService.getDailyCreditUsageStatistic(dateRange.fromDate, dateRange.toDate);

        const listOfMonths: Date[] = DateTimeUtils.getListOfMonth(dateRange.fromDate, dateRange.toDate);
        const listOfMonthsAsStr = listOfMonths?.map(m =>
          DateTimeUtils.formatDateToString(m, DATE_FORMAT.DATE_FORMAT_BACKEND).slice(0, -3)
        ); // YYYY-MM

        const mapOfMonthAndData = new Map<string, DailyCreditUsageStatistic>(
          listOfMonthsAsStr?.map(monthAsString => [
            monthAsString,
            {
              date: `${monthAsString}-01`,
              numberOfSentSms: 0,
              numberOfSentEmail: 0,
              emailCost: 0,
              smsCost: 0,
            },
          ])
        );

        rawData?.forEach(data => {
          const monthAsString = data.date.slice(0, -3);

          const matchedMonthData = mapOfMonthAndData.get(monthAsString);
          matchedMonthData.numberOfSentSms += data.numberOfSentSms;
          matchedMonthData.numberOfSentEmail += data.numberOfSentEmail;
          matchedMonthData.emailCost += data.emailCost;
          matchedMonthData.smsCost += data.smsCost;

          mapOfMonthAndData.set(monthAsString, matchedMonthData);
        });

        mapOfMonthAndData.forEach(value => matchedData.push(value));
      }

      matchedData.sort((a, b) => a.date.localeCompare(b.date));

      // generated chart
      const numberOfSentSmsData: ChartJsDataSet = {
        label: 'Nr sent SMS',
        backgroundColor: 'blue',
        borderColor: 'blue',
        data: [],
      };
      const smsCostData: ChartJsDataSet = {
        label: 'SMS cost',
        backgroundColor: 'purple',
        borderColor: 'purple',
        data: [],
      };
      const numberOfSentEmailData: ChartJsDataSet = {
        label: 'Nr sent Email',
        backgroundColor: 'green',
        borderColor: 'green',
        data: [],
      };
      const emailCostData: ChartJsDataSet = {
        label: 'Email cost',
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        data: [],
      };
      const labels: string[] = [];

      matchedData?.forEach(d => {
        numberOfSentSmsData.data.push(d.numberOfSentSms);
        smsCostData.data.push(d.smsCost);
        numberOfSentEmailData.data.push(d.numberOfSentEmail);
        emailCostData.data.push(d.emailCost);
        labels.push(viewMode === RANGE_VIEW_MODE.DAY ? d.date : d.date.slice(0, -3));
      });

      const chartJsDataSet: ChartJsDataSet[] = [numberOfSentSmsData, smsCostData, numberOfSentEmailData, emailCostData];

      const ctx = document.getElementById(CHART_ID);

      if (ctx) {
        const chartConfig = chartJsLineChartService.generateMultiLinesChartOption(chartJsDataSet, labels);

        if (myChart) {
          myChart.destroy();
        }

        setMyChart(new Chart(ctx as HTMLCanvasElement, chartConfig));
      } else {
        logger.debug(`ERROR: not found id=${CHART_ID}`);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDateRangeOnViewModeChange = (selectedViewMode: RANGE_VIEW_MODE) => {
    setDateRange(RangeViewModeUtils.generateEarliestDateRangeFromViewMode(selectedViewMode));
  };

  const changeTimeRangeWindow = (isNext: boolean) => {
    const newDateRange: DateRange = isNext
      ? RangeViewModeUtils.getNextDateRange(dateRange, viewMode)
      : RangeViewModeUtils.getPreviousDateRange(dateRange, viewMode);
    setDateRange(newDateRange);
  };

  const previousClickHandler = () => {
    changeTimeRangeWindow(false);
  };

  const currentClickHandler = () => {
    updateDateRangeOnViewModeChange(viewMode);
  };

  const nextClickHandler = () => {
    changeTimeRangeWindow(true);
  };

  const updateViewModeHandler = (event: any) => {
    const { value } = event.target;
    setViewMode(value);
  };

  const _initSmsProviderPriceData = async () => {
    const _bulkgateWallet = await smsService.getBulkgateWalletInfo();
    setBulkgateWallet(_bulkgateWallet);

    const _gatewayApiBalance = await smsService.getGatewayApiBalance();
    _gatewayApiBalance.creditAsNumber = Number(_gatewayApiBalance.credit);
    setGatewayApiBalance(_gatewayApiBalance);

    const mapOfCountryCodeAndBulkgatePriceEur = await smsProviderService.getAllBulkgatePrices(COUNTRY_OPTIONS);
    const mapOfCountryCodeAndGatewayApiPriceEur = await smsService.getGatewayApiPrices(COUNTRY_OPTIONS);

    setSmsProviderPriceData(
      COUNTRY_OPTIONS.map(countryCode => {
        const bulkgatePriceEur = mapOfCountryCodeAndBulkgatePriceEur.get(countryCode);
        const bulkgateTotalAvailableSms = bulkgatePriceEur ? Math.floor(_bulkgateWallet?.credit / bulkgatePriceEur) : 0;

        const gatewayApiPriceEur = mapOfCountryCodeAndGatewayApiPriceEur.get(countryCode);
        const gatewayApiTotalAvailableSms = gatewayApiPriceEur
          ? Math.floor(_gatewayApiBalance?.creditAsNumber / gatewayApiPriceEur)
          : 0;

        return {
          countryCode,
          bulkgatePriceEur,
          bulkgateTotalAvailableSms,
          gatewayApiPriceEur,
          gatewayApiTotalAvailableSms,
        };
      })
    );
  };

  // use this syntax to trigger function on init
  React.useEffect(() => {
    _initSmsProviderPriceData();
    setViewMode(RANGE_VIEW_MODE.DAY);
  }, []);

  // use this syntax to watch change of range
  React.useEffect(() => {
    updateDateRangeOnViewModeChange(viewMode);
  }, [viewMode]);

  // use this syntax to watch change of range
  React.useEffect(() => {
    fetchDataAndRerenderChart();
  }, [dateRange]);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            disabled={isLoading}
            onClick={previousClickHandler}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <Button color="primary" disabled={isLoading} onClick={currentClickHandler}>
            {viewMode === RANGE_VIEW_MODE.DAY ? 'Today' : 'This month'}
          </Button>

          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            disabled={isLoading}
            onClick={nextClickHandler}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            fullWidth
            label="View mode"
            name="viewMode"
            value={viewMode || ''}
            disabled={isLoading}
            onChange={updateViewModeHandler}
          >
            {VIEW_MODE_OPTIONS.map(m => (
              <MenuItem value={m} key={m}>
                {m}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {isLoading ? <LinearProgress /> : <></>}

      <div className={classes.chartWrapper}>
        <canvas id={CHART_ID}></canvas>
      </div>

      <h2>
        Bulkgate wallet balance: {bulkgateWallet?.credit ? Math.floor(bulkgateWallet?.credit) : '-'}{' '}
        {gatewayApiBalance?.currency?.toUpperCase()}
      </h2>
      <h2>
        Gateway API wallet balance:{' '}
        {gatewayApiBalance?.creditAsNumber ? Math.floor(gatewayApiBalance?.creditAsNumber) : '-'}{' '}
        {gatewayApiBalance?.currency}
      </h2>

      <SmsProviderPriceTableComponent data={smsProviderPriceData}></SmsProviderPriceTableComponent>
    </>
  );
}
