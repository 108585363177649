import ScrollToTop from '../../../../../../../commons/utils/scroll-to-top';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../../../../../routes/private-route.component';
import React from 'react';
import ProductContextComponent from '../../../../product-context/product-context.component';
import CategoryDetailComponent from '../pages/category-detail/category-detail.component';
import { CATEGORY_DETAIL_ROUTE } from './category-detail-route-definition.model';

export default function CategoryDetailRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} path={CATEGORY_DETAIL_ROUTE.PRODUCT} component={ProductContextComponent} />
          <PrivateRoute {...props} component={CategoryDetailComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
