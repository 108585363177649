import React from 'react';
import ProductDetailComponent from '../pages/product-detail/product-detail.component';
import ScrollToTop from '../../../../../../../commons/utils/scroll-to-top';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../../../../../routes/private-route.component';

export default function ProductDetailRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} component={ProductDetailComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
