import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../../../routes/private-route.component';
import ScrollToTop from '../../../../../commons/utils/scroll-to-top';
import { USER_ROUTE } from './user-route-definition.model';
import UserListComponent from '../pages/user-list/user-list.component';
import UserCreateComponent from '../pages/user-create/user-create.component';
import UserDetailContextComponent from '../pages/user-detail-context/user-detail-context.component';

export default function UserRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} path={USER_ROUTE.USER_CREATE} component={UserCreateComponent} />
          <PrivateRoute {...props} path={USER_ROUTE.USER_DETAIL} component={UserDetailContextComponent} />
          <PrivateRoute {...props} component={UserListComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
