import React from 'react';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { DEFAULT_PROVIDER_OPTIONS } from '../../../../../../models/consts/DefaultProviderOptions.const';
import { CommunicationUnitPrice } from '../../../../../../models/interfaces/CommunicationUnitPrice.interface';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import { COMMUNICATION_WAY } from '../../../../../../models/enums/CommunicationWay.enum';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import smsService from '../../../../../../services/sms.service';

const COMMUNICATION_WAY_OPTIONS = convertEnumToArray(COMMUNICATION_WAY);

interface CommunicationUnitDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  communicationUnitPrice?: CommunicationUnitPrice;
  onClose: Function;
  onDelete?: Function;
  onUpdate?: Function;
  onCreate?: Function;
}

export default function CommunicationUnitDialogComponent(props: CommunicationUnitDialogComponentProps) {
  const [isoCodeWithCountryCodes, setIsoCodeWithCountryCodes] = React.useState<IsoCountryCodeWithPhoneCountryCode[]>(
    []
  );
  const [unitPrice, setUnitPrice] = React.useState<CommunicationUnitPrice>(null);

  const fetchIsoCodeWithCountryCodes = async () => {
    const response = await smsService.getAllCountryCodes();
    setIsoCodeWithCountryCodes(response);
  };

  React.useEffect(() => {
    fetchIsoCodeWithCountryCodes();
  }, []);

  React.useEffect(() => {
    setUnitPrice(props.communicationUnitPrice);
  }, [props]);

  const dataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    setUnitPrice({
      ...(unitPrice || {}),
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const getTitle = () => {
    const p = props.communicationUnitPrice;

    if (p) {
      return `[${p.countryCode} | ${p.provider}] 1 ${p.communicationWay} = ${p.price} Credit [id=${p.id}]`;
    } else {
      return 'Add a new unit price';
    }
  };

  const getContent = () => {
    return (
      <>
        {/*ID*/}
        {unitPrice?.id ? (
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            fullWidth
            key="id"
            label="ID"
            name="id"
            value={unitPrice?.id}
            disabled={true}
          />
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Locale*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Locale"
              name="countryCode"
              value={unitPrice?.countryCode || ''}
              disabled={!!unitPrice?.id}
              onChange={dataChangeHandler}
            >
              {isoCodeWithCountryCodes.map(item => (
                <MenuItem value={item.isoCode} key={item.isoCode}>
                  {item.isoCode} ({item.phoneCountryCode})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            {/*Provider*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Provider"
              name="provider"
              value={unitPrice?.provider || ''}
              disabled={!!unitPrice?.id}
              onChange={dataChangeHandler}
            >
              {DEFAULT_PROVIDER_OPTIONS.map(provider => (
                <MenuItem value={provider.value} key={provider.value}>
                  {provider.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Communication way*/}
            <TextField
              variant="outlined"
              margin="dense"
              select
              required
              fullWidth
              label="Communication way"
              name="communicationWay"
              value={unitPrice?.communicationWay || ''}
              disabled={!!unitPrice?.id}
              onChange={dataChangeHandler}
            >
              {COMMUNICATION_WAY_OPTIONS.map(c => (
                <MenuItem value={c} key={c}>
                  {' '}
                  {c}{' '}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            {/*Price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              label="Price"
              name="price"
              value={unitPrice?.price || ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">Credit</InputAdornment>,
              }}
              onChange={dataChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getButtons = () => {
    return props.communicationUnitPrice ? (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={props.isLoading}
            onClick={() => props.onDelete(props.communicationUnitPrice)}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            onClick={() => props.onUpdate(unitPrice)}
          >
            Update
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={props.isLoading}
            onClick={() => props.onCreate(unitPrice)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
