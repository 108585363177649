import React from 'react';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import { CommunicationUnitPrice } from '../../../../../../models/interfaces/CommunicationUnitPrice.interface';

interface CommunicationUnitTableComponentProps {
  unitPrices: CommunicationUnitPrice[];
  onSelect?: Function;
}

export default function CommunicationUnitTableComponent(props: CommunicationUnitTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'communicationWay', field: 'communicationWay', label: 'Communication way' },
    { id: 'price', field: 'price', label: 'Price' },
    { id: 'provider', field: 'provider', label: 'Provider' },
    { id: 'countryCode', field: 'countryCode', label: 'Locale' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Communication unit price`,
    rowsPerPage: 10,
    headCells,
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.unitPrices} onSelect={props?.onSelect} />;
}
