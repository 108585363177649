import React from 'react';
import { LexofficeContact } from '../../../../../models/interfaces/LexofficeContact.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';
import { LexofficeContactUtils } from '../../../../../commons/utils/lexoffice-contact.utils';

interface LexofficeContactTableComponentProps {
  lexofficeContacts: LexofficeContact[];
  onSelect?: Function;
}

export default function LexofficeContactTableComponent(props: LexofficeContactTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'customerNumber', field: 'roles.customer.number', label: 'Customer number' },
    { id: 'salonName', label: 'Salon name', field: 'company.name' },
    {
      id: 'contactPerson',
      label: 'Contact person',
      getFieldValueFunc: (contact: LexofficeContact) => LexofficeContactUtils.generateContactPersonAsString(contact),
    },
    {
      id: 'billingAddress',
      label: 'Billing address',
      getFieldValueFunc: (contact: LexofficeContact) => LexofficeContactUtils.generateBillingAddressAsString(contact),
    },
    {
      id: 'email',
      label: 'Email',
      getFieldValueFunc: (contact: LexofficeContact) => LexofficeContactUtils.generateEmailAsArrayString(contact),
    },
    {
      id: 'phoneNumber',
      label: 'Phone number',
      getFieldValueFunc: (contact: LexofficeContact) => LexofficeContactUtils.generatePhoneNumberAsArrayString(contact),
    },
    { id: 'note', field: 'note', label: 'Note' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Lexoffice contacts`,
    rowsPerPage: 10,
    headCells,
  };

  return (
    <CustomDataTableComponent tableConfig={tableConfig} data={props?.lexofficeContacts} onSelect={props?.onSelect} />
  );
}
