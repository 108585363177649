import React from 'react';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import { CREDIT_ORDER_STATUS } from '../../../../../../models/enums/CreditOrderStatus.enum';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { CreditOrder } from '../../../../../../models/interfaces/CreditOrder.interface';

interface CreditOrderDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  creditOrder: CreditOrder;
  onClose: Function;
  onReject?: Function;
  onFulfill?: Function;
}

export default function CreditOrderDialogComponent(props: CreditOrderDialogComponentProps) {
  const getTitle = () => {
    return `[${props.creditOrder?.status}] Credit order [id=${props.creditOrder?.id}]`;
  };

  const getContent = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*ID*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
              key="id"
              label="ID"
              name="id"
              value={props.creditOrder?.id}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Created at*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="createdAt"
              label="Created at"
              name="createdAt"
              value={props.creditOrder?.createdAt}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Last modified by*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="lastModifiedBy"
              label="Last modified by"
              name="lastModifiedBy"
              value={props.creditOrder?.lastModifiedBy || '-'}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Last modified*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="lastModified"
              label="Last modified"
              name="lastModified"
              value={props.creditOrder?.lastModified || '-'}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Provider*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="provider"
              label="Provider"
              name="provider"
              value={props.creditOrder?.provider}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Locale*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="countryCode"
              label="Locale"
              name="countryCode"
              value={props.creditOrder?.countryCode}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Number of Sms*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              key="balance"
              label="Number of credit"
              name="balance"
              value={props.creditOrder?.balance}
              disabled={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">Credit</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Purpose of transfer*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="orderNr"
              label="Order number"
              name="orderNr"
              value={props.creditOrder?.orderNr}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Price*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              required
              fullWidth
              key="price"
              label="Price"
              name="price"
              value={props.creditOrder?.price}
              disabled={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Price incl. VAT*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
              key="priceIncludingVAT"
              label="Price incl. VAT"
              name="priceIncludingVAT"
              value={props.creditOrder?.priceIncludingVAT}
              disabled={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        {/*Description*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          multiline
          key="description"
          label="Description"
          name="description"
          value={props.creditOrder?.description}
          disabled={true}
        />
      </>
    );
  };

  const getButtons = () => {
    switch (props.creditOrder?.status) {
      case CREDIT_ORDER_STATUS.PENDING:
        return (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={props.isLoading}
                onClick={() => props.onReject(props.creditOrder)}
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={props.isLoading}
                onClick={() => props.onFulfill(props.creditOrder)}
              >
                Fulfill
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        );
      case CREDIT_ORDER_STATUS.FULFILLED:
        return (
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        );
      case CREDIT_ORDER_STATUS.REJECTED:
        return (
          <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        );
    }
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
