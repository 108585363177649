import httpService from './http.service';
import authenticationHelperService from './auth-helper.service';
import logger from '../commons/logger';
import { AxiosResponse } from 'axios';
import ENV from '../commons/environment';
import { BackendResponse } from '../models/interfaces/BackendResponse.interface';

class BackendCallerService {
  public getEmptyHeader(): any {
    return {
      Authorization: authenticationHelperService.getAuthToken(),
    };
  }

  public generateHeaderWithToken(token: string): any {
    return {
      'Content-Type': 'application/json',
      Authorization: token,
    };
  }

  public async get(url: string, customHeader?: any): Promise<BackendResponse> {
    try {
      const response = await httpService.get(this.createConcreteUrl(url), customHeader || this.getDefaultHeader());
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e?.response);
    }
  }

  public async post(url: string, data: any, customHeader?: any, isFormData?: boolean): Promise<BackendResponse> {
    try {
      const response = await httpService.post(
        this.createConcreteUrl(url),
        isFormData ? data : JSON.stringify(data),
        customHeader || this.getDefaultHeader()
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e?.response);
    }
  }

  public async put(url: string, data: any, customHeader?: any, isFormData?: boolean): Promise<BackendResponse> {
    try {
      const response = await httpService.put(
        this.createConcreteUrl(url),
        isFormData ? data : JSON.stringify(data),
        customHeader || this.getDefaultHeader()
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e?.response);
    }
  }

  public async patch(url: string, data: any, customHeader?: any): Promise<BackendResponse> {
    try {
      const response = await httpService.patch(
        this.createConcreteUrl(url),
        JSON.stringify(data),
        customHeader || this.getDefaultHeader()
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e?.response);
    }
  }

  public async delete(url: string, customHeader?: any): Promise<BackendResponse> {
    try {
      const response = await httpService.delete(this.createConcreteUrl(url), customHeader || this.getDefaultHeader());
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e?.response);
    }
  }

  private handleError(response: AxiosResponse): any {
    const error = this.formatError(response);
    logger.error(JSON.stringify(error));

    if (error.status === 401) {
      authenticationHelperService.removeAuthToken();
      window.location.reload();
    }
    return {};
  }

  private handleResponse(response: AxiosResponse): any {
    return {
      header: response.headers,
      body: response.data,
      status: response.status,
      statusText: response.statusText,
    };
  }

  private getDefaultHeader(): any {
    return {
      'Content-Type': 'application/json',
      Authorization: authenticationHelperService.getAuthToken(),
    };
  }

  private createConcreteUrl(url: string): string {
    return ENV.MODE === 'dev' ? url : `${ENV.BACKEND_URL}${url}`;
  }

  private formatError(response: AxiosResponse): any {
    const { data, status } = response;

    return {
      data,
      status,
    };
  }
}

const backendCallerService = new BackendCallerService();

export default backendCallerService;
