import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { User } from '../../../../../../models/interfaces/User.interface';
import ProductFormComponent from '../../components/product-form/product-form.component';
import { getCategoryDetailPath } from '../../../category-context/routing/category-route-definition.model';
import { Link } from 'react-router-dom';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      marginBottom: '20px',
    },
  })
);

export default function ProductCreateComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;
  const category: ProductCategory = props.category;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>Create new product</div>

      <ProductFormComponent
        ownerId={user?.id}
        ownerEmail={user?.email}
        businessObjectId={businessObject?.id}
        categoryId={category?.id}
      />

      <Link to={getCategoryDetailPath(user?.id, businessObject?.id, category?.id)}>Back to category</Link>
    </div>
  );
}
