export class StringUtils {
  public static simplifyString(str: string): string {
    // make string more simple to analyse
    try {
      return str.replace(/ /g, '').toLowerCase();
    } catch (e) {
      return '';
    }
  }
}
