import { createRoute } from '../../../../../commons/utils/route-helper';
import { BUSINESS_OBJECT_DETAIL_ROUTE } from '../../business-object-context/pages/business-object-detail-context/routing/business-object-detail-route-definition.model';

const CATEGORY_ROUTE = {
  CATEGORY_CREATE: `${BUSINESS_OBJECT_DETAIL_ROUTE.CATEGORY}/create`,
  MASSIVE_CREATE: `${BUSINESS_OBJECT_DETAIL_ROUTE.CATEGORY}/massive-create`,
  CATEGORY_DETAIL: `${BUSINESS_OBJECT_DETAIL_ROUTE.CATEGORY}/:categoryId`,
};

const getCategoryMassiveCreatePath = (userId: number, boId: number): string => {
  return createRoute(CATEGORY_ROUTE.MASSIVE_CREATE, { userId, boId });
};

const getCategoryDetailPath = function (userId: number, boId: number, categoryId: number): string {
  return createRoute(CATEGORY_ROUTE.CATEGORY_DETAIL, { userId, boId, categoryId });
};

const getCategoryCreatePath = function (userId: number, boId: number): string {
  return createRoute(CATEGORY_ROUTE.CATEGORY_CREATE, { userId, boId });
};

export { CATEGORY_ROUTE, getCategoryDetailPath, getCategoryCreatePath, getCategoryMassiveCreatePath };
