import { createRoute } from '../../../../../commons/utils/route-helper';
import { CATEGORY_DETAIL_ROUTE } from '../../category-context/pages/category-detail-context/routing/category-detail-route-definition.model';

const PRODUCT_ROUTE = {
  PRODUCT_CREATE: `${CATEGORY_DETAIL_ROUTE.PRODUCT}/create`,
  PRODUCT_DETAIL: `${CATEGORY_DETAIL_ROUTE.PRODUCT}/:productId`,
};

const getProductDetailPath = function (userId: number, boId: number, categoryId: number, productId: number): string {
  return createRoute(PRODUCT_ROUTE.PRODUCT_DETAIL, { userId, boId, categoryId, productId });
};

const getProductCreatePath = function (userId: number, boId: number, categoryId: number): string {
  return createRoute(PRODUCT_ROUTE.PRODUCT_CREATE, { userId, boId, categoryId });
};

export { PRODUCT_ROUTE, getProductDetailPath, getProductCreatePath };
