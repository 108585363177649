import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

interface CategoryInfoCardComponentProps {
  category: ProductCategory;
}

export default function CategoryInfoCardComponent(props: CategoryInfoCardComponentProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          Category
        </Typography>
      </Toolbar>

      <form className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
              key="id"
              id="id"
              label="ID"
              name="id"
              value={props?.category?.id || ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={9}>
            <TextField
              variant="outlined"
              margin="dense"
              type="email"
              required
              fullWidth
              key="name"
              id="name"
              label="Name"
              name="name"
              value={props?.category?.name || ''}
              disabled={true}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
