import React from 'react';
import { InvoiceJob } from '../../../../../models/interfaces/InvoiceJob.interface';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import CustomDataTableComponent from '../../../../../commons/components/custom-data-table/custom-data-table.component';
import { DateTimeUtils } from '../../../../../commons/utils/date-time.utils';

interface InvoiceJobTableComponentProps {
  invoiceJobs: InvoiceJob[];
  onSelect?: Function;
}

export default function InvoiceJobTableComponent(props: InvoiceJobTableComponentProps) {
  const headCells: HeadCell[] = [
    { id: 'userEmail', field: 'userEmail', label: 'User' },
    { id: 'businessObjectName', field: 'businessObjectName', label: 'Salon' },
    { id: 'connection', field: 'connection.id', label: 'Lexoffice connection ID' },
    { id: 'communicationUnitPackage', field: 'communicationUnitPackage.id', label: 'Credit package ID' },
    {
      id: 'appointmentFee',
      label: 'Appointment Fee',
      getFieldValueFunc: (invoiceJob: InvoiceJob) => (invoiceJob.appointmentFee ? 'yes' : 'no'),
    },
    {
      id: 'twFee',
      label: 'TW Connect Fee',
      getFieldValueFunc: (invoiceJob: InvoiceJob) => (invoiceJob.twFee ? 'yes' : 'no'),
    },
    { id: 'bpFee', label: 'Pro Fee', getFieldValueFunc: (invoiceJob: InvoiceJob) => (invoiceJob.bpFee ? 'yes' : 'no') },
    {
      id: 'active',
      label: 'Activation',
      getFieldValueFunc: (invoiceJob: InvoiceJob) => (invoiceJob.active ? 'yes' : 'no'),
    },
    {
      id: 'repeated',
      label: 'Repeat',
      getFieldValueFunc: (invoiceJob: InvoiceJob) => (invoiceJob.repeated ? invoiceJob.jobInterval : 'no'),
    },
    { id: 'startDate', field: 'startDate', label: 'Start date' },
    { id: 'lastExecutionDate', field: 'lastExecutionDate', label: 'Last execution date' },
    { id: 'nextExecutionDate', field: 'nextExecutionDate', label: 'Next execution date' },
    { id: 'note', field: 'note', label: 'Note' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: `Lexoffice contacts`,
    rowsPerPage: 10,
    headCells,
    highlightRowColorFunc: (item: InvoiceJob): string | null => {
      if (!item?.active) {
        return 'lightgrey';
      }

      const nextExecutionDate = DateTimeUtils.getEndOfDate(DateTimeUtils.parseStringToDate(item.nextExecutionDate));
      const now = DateTimeUtils.getEndOfDate(new Date());

      return nextExecutionDate < now ? 'red' : 'limegreen';
    },
  };

  return <CustomDataTableComponent tableConfig={tableConfig} data={props?.invoiceJobs} onSelect={props?.onSelect} />;
}
