import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import { ContinentData } from '../interfaces/ContinentData.interface';
import { byIso } from 'country-code-lookup';

export class ContinentDataUtils {
  public static generateContinentData(isoCodeWithCountryCodes: IsoCountryCodeWithPhoneCountryCode[]): ContinentData[] {
    const UNKNOWN = 'Unknown';

    const continentData: ContinentData[] = [];

    isoCodeWithCountryCodes?.forEach(i => {
      const country = byIso(i.isoCode);

      const continentName = country?.continent || UNKNOWN;

      const matchedContinentIndex = continentData?.findIndex(c => c.name === continentName);

      if (matchedContinentIndex < 0) {
        continentData.push({
          name: continentName,
          countries: [
            {
              info: country,
              phoneData: i,
            },
          ],
        });
      } else {
        const matchedContinent = { ...continentData[matchedContinentIndex] };

        matchedContinent.countries.push({
          info: country,
          phoneData: i,
        });

        continentData[matchedContinentIndex] = matchedContinent;
      }
    });

    continentData.sort((a, b) => a.name.localeCompare(b.name));

    return continentData;
  }
}
