import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import UserTableComponent from '../../components/user-table/user-table.component';
import { User } from '../../../../../../models/interfaces/User.interface';
import userService from '../../../../../../services/user.service';
import { Button, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { createRoute } from '../../../../../../commons/utils/route-helper';
import { USER_ROUTE } from '../../routing/user-route-definition.model';
import { PersonAdd } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import { convertEnumToArray } from '../../../../../../commons/utils/enum-to-array';
import logger from '../../../../../../commons/logger';
import adminService from '../../../../../../services/admin.service';
import { UserCreditBudget } from '../../../../../../models/interfaces/UserCreditBudget.interface';
import { NumberUtils } from '../../../../../../commons/utils/number.utils';
import { StringUtils } from '../../../../../../commons/utils/string.util';
import UserChartComponent from '../../components/user-chart/user-chart.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    filterForm: {
      marginBottom: theme.spacing(1),
    },
    createNewUserButton: {
      marginBottom: '20px',
    },
  })
);

enum USER_TYPE {
  ALL = 'all',
  SALON_OWNER_USER = 'salon owner user',
  CUSTOMER_USER = 'customer user',
}

const userTypeOptions = convertEnumToArray(USER_TYPE);

export default function UserListComponent(props: any) {
  const classes = useStyles();

  const [userType, setUserType] = React.useState<USER_TYPE>(USER_TYPE.SALON_OWNER_USER);
  const [users, setUsers] = React.useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);

  const [searchKeyword, setSearchKeyword] = React.useState<string>(null);

  const [mapOfUserEmailAndBudget, setMapOfUserEmailAndBudget] = React.useState<UserCreditBudget>({});
  const [redirectToCreateUserPage, setRedirectToCreateUserPage] = React.useState<string>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    initData();
  }, []);

  React.useEffect(() => {
    updateFilteredUsers();
  }, [users, userType, searchKeyword]);

  const initData = async () => {
    setIsLoading(true);

    try {
      const map = await adminService.getUserCreditBudget();
      setMapOfUserEmailAndBudget(map);

      const userData = await userService.getAllUsers();
      setUsers(userData);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFilteredUsers = () => {
    users?.forEach(u => (u.creditBudget = mapOfUserEmailAndBudget?.[u?.email] || 0));

    let filteredResult: User[];

    if (userType === USER_TYPE.SALON_OWNER_USER) {
      filteredResult = users?.filter(u => NumberUtils.isNumber(mapOfUserEmailAndBudget?.[u?.email]));
    } else if (userType === USER_TYPE.CUSTOMER_USER) {
      filteredResult = users?.filter(u => !NumberUtils.isNumber(mapOfUserEmailAndBudget?.[u?.email]));
    } else {
      filteredResult = [...users];
    }

    const keywordToSearch = StringUtils.simplifyString(searchKeyword);
    if (keywordToSearch?.length) {
      filteredResult = filteredResult?.filter(u => {
        if (StringUtils.simplifyString(u.name).includes(keywordToSearch)) {
          return true;
        }
        if (StringUtils.simplifyString(u.surname).includes(keywordToSearch)) {
          return true;
        }
        if (StringUtils.simplifyString(u.email).includes(keywordToSearch)) {
          return true;
        }
        if (StringUtils.simplifyString(u.phoneNr).includes(keywordToSearch)) {
          return true;
        }
        return false;
      });
    }

    setFilteredUsers(filteredResult);
  };

  const handleUserTypeChange = (event: any) => {
    setUserType(event.target?.value);
  };

  const handleCreateUser = () => {
    const routePath = createRoute(USER_ROUTE.USER_CREATE);
    setRedirectToCreateUserPage(routePath);
  };

  return redirectToCreateUserPage ? (
    <Redirect to={redirectToCreateUserPage} />
  ) : isLoading ? (
    <LinearProgress />
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.filterForm}>
        <Grid item xs={12} sm={12} md={4}>
          <Button
            onClick={handleCreateUser}
            variant="contained"
            color="primary"
            className={classes.createNewUserButton}
            startIcon={<PersonAdd />}
          >
            Create new user
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4} justifyContent="flex-end">
          <TextField
            variant="outlined"
            margin="dense"
            select
            fullWidth
            label="User type"
            name="userType"
            value={userType}
            onChange={handleUserTypeChange}
          >
            {userTypeOptions.map(item => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} justifyContent="flex-end">
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            fullWidth
            label="Search"
            name="search"
            value={searchKeyword || ''}
            onChange={event => setSearchKeyword(event.target.value)}
          />
        </Grid>
      </Grid>

      <UserTableComponent {...props} users={filteredUsers}></UserTableComponent>

      <UserChartComponent users={filteredUsers}></UserChartComponent>
    </div>
  );
}
