import React from 'react';
import RouteMapping from './routes/route-mapping.component';
import './App.scss';
import ENV from './commons/environment';
import logger from './commons/logger';
import googleApiService from './services/google-api.service';

if (ENV.MODE !== 'production') {
  document.title = `Super Admin (${ENV.MODE})`;
  logger.info(`mode = ${ENV.MODE}`);
  logger.info(`log level = ${logger.level}`);
} else {
  document.title = 'Super Admin';
}

googleApiService.init();

function App() {
  return <RouteMapping />;
}

export default App;
