import React from 'react';
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  LinearProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles(() => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const CustomDialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(DialogActions);

interface CustomDialogComponentProps {
  isOpen: boolean;
  onClose: Function;
  title?: any;
  content?: any;
  buttons?: any;
  isLoading?: boolean;
  styleOptions?: {
    fullWidth?: boolean;
  };
}

export default function CustomDialogComponent(props: CustomDialogComponentProps) {
  const classes = useStyles();

  return (
    <Dialog
      scroll={'paper'}
      maxWidth={false}
      fullWidth={props?.styleOptions?.fullWidth}
      onClose={() => props.onClose()}
      aria-labelledby="customized-dialog-title"
      open={props.isOpen}
    >
      <form className={classes.form}>
        <CustomDialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
          {props?.title}
        </CustomDialogTitle>
        <CustomDialogContent dividers>{props?.content}</CustomDialogContent>
        <CustomDialogActions>{props?.buttons}</CustomDialogActions>
      </form>

      {props.isLoading ? <LinearProgress /> : <></>}
    </Dialog>
  );
}
