import React from 'react';
import { BusinessObjectStatistic } from '../../../../models/interfaces/BusinessObjectStatistic.interface';
import { DateTimeUtils } from '../../../../commons/utils/date-time.utils';
import adminService from '../../../../services/admin.service';
import { DATE_FORMAT } from '../../../../models/enums/DateFormat.enum';
import { Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BusinessObjectStatisticDetailsDialogComponent from './components/business-object-statistic-details-dialog.component';
import logger from '../../../../commons/logger';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../services/business-object.service';
import { BUSINESS_OBJECT_VERSION } from '../../../../models/enums/BusinessObjectVersion.enum';
import { InvoiceJob } from '../../../../models/interfaces/InvoiceJob.interface';
import BusinessObjectStatisticTableComponent from './components/business-object-statistic-table.component';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { StringUtils } from '../../../../commons/utils/string.util';
import { Autocomplete } from '@material-ui/lab';
import { LAST_BO_ID_WITH_OLD_PRO_PRICE } from '../../../../models/consts/Other.const';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: '20px',
    },
    optionDescription: {
      marginLeft: '5px',
      fontSize: 'small',
      color: 'grey',
    },
  })
);

const OWNER_TEST: string[] = [
  'one@testusers.com',
  'tunggad.vu@googlemail.com',
  'hovietanh.1991@gmail.com',
  'linhlinhlinh0611991@gmail.com',
  'son87.lengoc@gmail.com',
];

const OWNER_WITH_NEW_PRICE: string[] = [
  '2016winterthur2016@gmail.com',
  'vu-tien@hotmail.com',
  'nailsbeautykarben@gmail.com',
  'heinze.lashes@gmail.com',
  'danganh05012018@gmail.com',
  'tanguyvu72@gmail.com',
  'Longky@gmx.de',
  'stellakoehler13@gmail.com',
  'toninails290821@gmail.com',
];

const monthOptions: number[] = Array(12)
  .fill(1)
  .map((v, index) => index + 1);
const firstYearOfBusiness = 2019;
const thisYear = new Date().getFullYear();
const yearOptions: number[] = Array(thisYear - firstYearOfBusiness + 1)
  .fill(1)
  .map((v, index) => index + firstYearOfBusiness);

export default function BusinessObjectStatisticPageComponent(props: any) {
  const [businessObjectStatistics, setBusinessObjectStatistics] = React.useState<BusinessObjectStatistic[]>([]);
  const [filteredBusinessObjectStatistics, setFilteredBusinessObjectStatistics] = React.useState<
    BusinessObjectStatistic[]
  >([]);

  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [selectedBusinessObjects, setSelectedBusinessObjects] = React.useState<BusinessObject[]>([]);

  const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());

  const [isOpenDetailsDialog, setIsOpenDetailsDialog] = React.useState<boolean>(false);
  const [selectedBusinessObjectForDialog, setSelectedBusinessObjectForDialog] =
    React.useState<BusinessObjectStatistic>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const _calculateAppointmentFee = (data: BusinessObjectStatistic): number => {
    const nrAppointment = data?.numberOfOnlineBPAppointments;

    if (nrAppointment < 10) {
      return 0;
    }

    if (nrAppointment < 20) {
      return 10;
    }

    if (nrAppointment < 40) {
      return 20;
    }

    if (nrAppointment < 70) {
      return 40;
    }

    if (nrAppointment < 110) {
      return 70;
    }

    return 100;
  };

  const _addExtraInfo = (
    data: BusinessObjectStatistic[],
    businessObjects: BusinessObject[],
    invoiceJobs: InvoiceJob[]
  ): BusinessObjectStatistic[] => {
    const NEW_PRO_FEE = 30;
    const NEW_TW_CONNECT_FEE = 20;
    const OLD_PRO_FEE = 18.95;

    data.forEach(item => {
      const matchedBo = businessObjects?.find(bo => bo.id === item.boId);

      if (OWNER_TEST.includes(matchedBo?.owner)) {
        // Test salons
        item.monthlyFee = 0;
        item.priceDescription = 'Test salon';
      } else {
        const isProVersion = matchedBo?.version === BUSINESS_OBJECT_VERSION.PRO;
        const isConnectWithTreatwell = matchedBo?.twConnect?.syncActive || matchedBo?.twPro?.syncActive;
        const isNewPricing =
          matchedBo?.id > LAST_BO_ID_WITH_OLD_PRO_PRICE || OWNER_WITH_NEW_PRICE.includes(matchedBo?.owner);

        const appointmentFee = _calculateAppointmentFee(item);

        item.priceDescription = `[${matchedBo?.version}] [${isNewPricing ? 'NEW PRICING' : 'OLD PRICING'}]`;

        if (isNewPricing) {
          item.invoiceJobExisted = !!invoiceJobs?.find(i => i.businessObjectId === item.boId);

          item.monthlyFee = 0;

          if (isProVersion) {
            item.monthlyFee += NEW_PRO_FEE;
            item.priceDescription += ` PRO fee=${NEW_PRO_FEE}€`;
          }

          if (isConnectWithTreatwell) {
            item.monthlyFee += NEW_TW_CONNECT_FEE;
            item.priceDescription += ` + TW connect fee=${NEW_TW_CONNECT_FEE}€`;
          }

          item.monthlyFee += appointmentFee;
          item.priceDescription += ` + Appointment fee=${appointmentFee}€`;
        } else {
          item.monthlyFee = isProVersion ? OLD_PRO_FEE : 0;
          item.invoiceJobExisted = true;
        }
      }
    });

    return data;
  };

  const _fetchData = async () => {
    if (!selectedMonth || !selectedYear) {
      return;
    }

    try {
      setIsLoading(true);

      const invoiceJobs = await adminService.fetchAllInvoiceJobs();

      const businessObjects = await businessObjectService.getAllBusinessObjects();
      setBusinessObjects(businessObjects);

      // month begins with 0 (Jan = 0, Feb = 1, ... Dec = 11)
      const selectedDate = new Date(selectedYear, selectedMonth - 1, 1);

      const fromDate = DateTimeUtils.getFirstDateOfMonth(selectedDate);
      const toDate = DateTimeUtils.getLastDateOfMonth(selectedDate);

      const businessObjectStatistic = await adminService.getBusinessObjectStatistic(
        DateTimeUtils.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
        DateTimeUtils.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND)
      );

      const processedData = _addExtraInfo(businessObjectStatistic, businessObjects, invoiceJobs);

      setBusinessObjectStatistics(processedData);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const _updateFilteredBusinessObjectStatistics = () => {
    if (!selectedBusinessObjects?.length) {
      setFilteredBusinessObjectStatistics([...businessObjectStatistics]);
    } else {
      const setOfSelectedBoId = new Set<number>(selectedBusinessObjects?.map(bo => bo.id));
      setFilteredBusinessObjectStatistics(businessObjectStatistics?.filter(s => setOfSelectedBoId.has(s.boId)));
    }
  };

  // use this syntax to watch change of range
  React.useEffect(() => {
    _fetchData();
  }, [selectedMonth, selectedYear]);

  // use this syntax to watch change of range
  React.useEffect(() => {
    _updateFilteredBusinessObjectStatistics();
  }, [businessObjectStatistics, selectedBusinessObjects]);

  const isAbleToPrevious = () => {
    return (
      !isLoading &&
      !!selectedMonth &&
      !!selectedYear &&
      (selectedMonth !== monthOptions[0] || selectedYear !== yearOptions[0])
    );
  };

  const previousClickHandler = () => {
    if (!isAbleToPrevious()) {
      return;
    }

    if (selectedMonth === monthOptions[0]) {
      setSelectedYear(selectedYear - 1);
      setSelectedMonth(12);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const isAbleToNext = () => {
    return (
      !isLoading &&
      !!selectedMonth &&
      !!selectedYear &&
      (selectedMonth !== monthOptions[monthOptions.length - 1] || selectedYear !== yearOptions[yearOptions.length - 1])
    );
  };

  const nextClickHandler = () => {
    if (!isAbleToNext()) {
      return;
    }

    if (selectedMonth === monthOptions[monthOptions.length - 1]) {
      setSelectedYear(selectedYear + 1);
      setSelectedMonth(1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const selectBoHandler = (data: BusinessObjectStatistic) => {
    setSelectedBusinessObjectForDialog(data);
    setIsOpenDetailsDialog(true);
  };

  const closeDetailsDialog = () => {
    setIsOpenDetailsDialog(false);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.marginBottom}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            disabled={!isAbleToPrevious()}
            onClick={previousClickHandler}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <TextField
            style={{ marginRight: '20px' }}
            variant="outlined"
            margin="dense"
            select
            required
            label="Month"
            name="month"
            value={selectedMonth || ''}
            onChange={(event: any) => setSelectedMonth(event.target.value)}
          >
            {monthOptions.map(month => (
              <MenuItem value={month} key={month}>
                {month}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            margin="dense"
            select
            required
            label="Year"
            name="year"
            value={selectedYear || ''}
            onChange={(event: any) => setSelectedYear(event.target.value)}
          >
            {yearOptions.map(year => (
              <MenuItem value={year} key={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>

          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            color="primary"
            disabled={!isAbleToNext()}
            onClick={nextClickHandler}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={6} md={7} lg={8}>
          <Autocomplete
            multiple
            autoHighlight
            options={businessObjects}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                item =>
                  StringUtils.simplifyString(item.name).includes(StringUtils.simplifyString(inputValue)) ||
                  StringUtils.simplifyString(item.address).includes(StringUtils.simplifyString(inputValue)) ||
                  StringUtils.simplifyString(item.telephone).includes(StringUtils.simplifyString(inputValue)) ||
                  StringUtils.simplifyString(item.owner).includes(StringUtils.simplifyString(inputValue))
              )
            }
            filterSelectedOptions
            value={selectedBusinessObjects}
            onChange={(event: any, newValue) => setSelectedBusinessObjects(newValue as BusinessObject[])}
            getOptionLabel={option => `[${option.id}] ${option.name} [${option.address}]`}
            renderOption={option => (
              <>
                <div>
                  [{option.id}]&nbsp;<strong>{option.name}</strong>
                  <div className={classes.optionDescription}>
                    {option.address}
                    <div>{option.owner}</div>
                  </div>
                </div>
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Salons to filter"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'salon-to-filter', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      {isOpenDetailsDialog ? (
        <BusinessObjectStatisticDetailsDialogComponent
          isOpen={isOpenDetailsDialog}
          onClose={closeDetailsDialog}
          boId={selectedBusinessObjectForDialog?.boId}
          boName={selectedBusinessObjectForDialog?.name}
          owner={selectedBusinessObjectForDialog?.owner}
        ></BusinessObjectStatisticDetailsDialogComponent>
      ) : (
        <></>
      )}

      {isLoading ? (
        <LinearProgress />
      ) : (
        <BusinessObjectStatisticTableComponent
          businessObjectStatistics={filteredBusinessObjectStatistics}
          onSelect={selectBoHandler}
        />
      )}
    </>
  );
}
