import React from 'react';
import { User } from '../../../../../../../../models/interfaces/User.interface';
import { Link } from 'react-router-dom';
import { Product } from '../../../../../../../../models/interfaces/Product.interface';
import ProductFormComponent from '../../../../components/product-form/product-form.component';
import { getCategoryDetailPath } from '../../../../../category-context/routing/category-route-definition.model';
import { BusinessObject } from '../../../../../../../../models/interfaces/BusinessObject.interface';
import { ProductCategory } from '../../../../../../../../models/interfaces/ProductCategory.interface';

export default function ProductDetailComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;
  const category: ProductCategory = props.category;
  const product: Product = props.product;

  return (
    <div>
      <ProductFormComponent
        ownerId={user?.id}
        businessObjectId={businessObject?.id}
        categoryId={category?.id}
        product={product}
      />

      <div>
        <Link to={getCategoryDetailPath(user?.id, businessObject?.id, category?.id)}>Back to category</Link>
      </div>
    </div>
  );
}
