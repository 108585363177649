import React from 'react';
import { User } from '../../../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../../../../../services/business-object.service';
import UserFormComponent from '../../../../components/user-form/user-form.component';
import BusinessObjectTableComponent from '../../../../../business-object-context/components/business-object-table/business-object-table.component';
import { Link, Redirect } from 'react-router-dom';
import { PRIVATE_FRAME_ROUTE } from '../../../../../../routing/private-frame-route-definition.model';
import TopUpFormComponent from '../../../../components/top-up-form/top-up-form.component';
import { getBoCreatePath } from '../../../../../business-object-context/routing/business-object-route-definition.model';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import smsService from '../../../../../../../../services/sms.service';
import CreditOrderRequestFormComponent from '../../../../components/credit-order-request-form/credit-order-request-form.component';
import userService from '../../../../../../../../services/user.service';
import { CommunicationUnitPackage } from '../../../../../../../../models/interfaces/CommunicationUnitPackage.interface';
import { LexofficeConnection } from '../../../../../../../../models/interfaces/LexofficeConnection.interface';
import adminService from '../../../../../../../../services/admin.service';
import { LexofficeContact } from '../../../../../../../../models/interfaces/LexofficeContact.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createNewBoButton: {
      marginBottom: '20px',
    },
  })
);

export default function UserDetailComponent(props: any) {
  const user: User = props.user;

  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [admin, setAdmin] = React.useState<User>(null);
  const [creditBudget, setCreditBudget] = React.useState<number>(null);
  const [redirectToCreateBoPage, setRedirectToCreateBoPage] = React.useState<string>(null);
  const [creditPackages, setCreditPackages] = React.useState<CommunicationUnitPackage[]>([]);
  const [lexofficeContactsOfUser, setLexofficeContactsOfUser] = React.useState<LexofficeContact[]>([]);

  const getCreditBudgetOfUser = async (userId: number) => {
    const data = await smsService.getCreditBudgetOfUser(userId);
    setCreditBudget(data);
  };

  React.useEffect(() => {
    const getAllBusinessObjectsOfUser = async (userEmail: string) => {
      const data = await businessObjectService.getAllBusinessObjectsOfUser(userEmail);
      setBusinessObjects(data);
    };

    const getCurrentAdmin = async () => {
      const data = await userService.getCurrentLoggedInUser();
      setAdmin(data);
    };

    const getAllCreditPackages = async () => {
      const data = await smsService.getAllCommunicationUnitPackages();
      setCreditPackages(data);
    };

    const getAllLexofficeContactsOfUser = async (userId: number) => {
      const lexofficeConnections = await adminService.fetchAllLexofficeConnections();

      const setOfLexofficeContactIdOfUser = new Set<string>();
      lexofficeConnections?.forEach(c => {
        if (c.userId === userId) {
          setOfLexofficeContactIdOfUser.add(c.contactId);
        }
      });

      if (setOfLexofficeContactIdOfUser?.size > 0) {
        const lexofficeContacts = await adminService.fetchAllLexofficeContacts();
        setLexofficeContactsOfUser(lexofficeContacts?.filter(c => setOfLexofficeContactIdOfUser.has(c.id)));
      }
    };

    getCurrentAdmin();
    getAllCreditPackages();
    getAllBusinessObjectsOfUser(user?.email);
    getCreditBudgetOfUser(user?.id);
    getAllLexofficeContactsOfUser(user?.id);
  }, [user]);

  const handleCreateBo = () => {
    setRedirectToCreateBoPage(getBoCreatePath(user?.id));
  };

  const handleTopUp = () => {
    getCreditBudgetOfUser(user?.id);
  };

  const classes = useStyles();

  return redirectToCreateBoPage ? (
    <Redirect to={redirectToCreateBoPage} />
  ) : (
    <>
      <UserFormComponent user={user} />

      <CreditOrderRequestFormComponent
        admin={admin}
        user={user}
        creditBudget={creditBudget}
        creditPackages={creditPackages}
        lexofficeContactsOfUser={lexofficeContactsOfUser}
      ></CreditOrderRequestFormComponent>

      <TopUpFormComponent ownerId={user?.id} creditBudget={creditBudget} onTopUp={handleTopUp} />

      <Button
        onClick={handleCreateBo}
        variant="contained"
        color="primary"
        className={classes.createNewBoButton}
        startIcon={<Add />}
      >
        Create new business object
      </Button>

      <BusinessObjectTableComponent userId={user?.id} businessObjects={businessObjects} />

      <div>
        <Link to={`${PRIVATE_FRAME_ROUTE.USER}`}>Back to user table</Link>
      </div>
    </>
  );
}
