import { makeStyles } from '@material-ui/core/styles';
import { User } from '../../../../../../models/interfaces/User.interface';
import { Grid, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import adminService from '../../../../../../services/admin.service';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import businessObjectService from '../../../../../../services/business-object.service';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

interface UserInfoCardComponentProps {
  user: User;
}

export default function UserInfoCardComponent(props: UserInfoCardComponentProps) {
  const [role, setRole] = React.useState<string>();

  const fetchRoleOfUser = async (userId: number) => {
    const authorities = await adminService.getAuthoritiesOfUser(userId);
    setRole(authorities?.map(a => a.authority)?.join(', '));
  };

  React.useEffect(() => {
    fetchRoleOfUser(props?.user?.id);
  }, [props]);

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          User
        </Typography>
      </Toolbar>

      <form className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
              key="id"
              id="id"
              label="ID"
              name="id"
              value={props?.user?.id || ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="dense"
              type="email"
              required
              fullWidth
              key="role"
              id="role"
              label="Role"
              name="role"
              value={role || ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="dense"
              type="email"
              required
              fullWidth
              key="email"
              id="email"
              label="Email address"
              name="email"
              value={props?.user?.email || ''}
              disabled={true}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
