import React from 'react';
import { LexofficeConnection } from '../../../../../models/interfaces/LexofficeConnection.interface';
import { LexofficeContact } from '../../../../../models/interfaces/LexofficeContact.interface';
import { User } from '../../../../../models/interfaces/User.interface';
import CustomDialogComponent from '../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { LEXOFFICE_TAX_TYPE } from '../../../../../models/enums/LexofficeTaxType.enum';
import { LEXOFFICE_BILLING_LANGUAGE } from '../../../../../models/enums/LexofficeBillingLanguage.enum';
import { EU_COUNTRY_CODES } from '../../../../../models/consts/EuCountryCode.const';
import { convertEnumToArray } from '../../../../../commons/utils/enum-to-array';
import { BusinessObject } from '../../../../../models/interfaces/BusinessObject.interface';
import { Autocomplete } from '@material-ui/lab';
import { StringUtils } from '../../../../../commons/utils/string.util';
import { LexofficeContactUtils } from '../../../../../commons/utils/lexoffice-contact.utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionDescription: {
      marginLeft: '5px',
      fontSize: 'small',
      color: 'grey',
    },
  })
);

export interface LexofficeConnectionDataForDialog {
  connection?: LexofficeConnection;
  preselectedLexofficeContact?: LexofficeContact;
  preselectedBusinessObject?: BusinessObject;
  users: User[];
  businessObjects: BusinessObject[];
  lexofficeContacts: LexofficeContact[];
  lexofficeConnections: LexofficeConnection[];
}

interface LexofficeConnectionDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  data: LexofficeConnectionDataForDialog;
  onClose: Function;
  onCreate: Function;
  onUpdate: Function;
  onDelete: Function;
}

const taxTypeOptions = convertEnumToArray(LEXOFFICE_TAX_TYPE);
const billingLanguageOptions = convertEnumToArray(LEXOFFICE_BILLING_LANGUAGE);

export function LexofficeConnectionDialogComponent(props: LexofficeConnectionDialogComponentProps) {
  const [selectedUser, setSelectedUser] = React.useState<User>(null);
  const [selectedBusinessObject, setSelectedBusinessObject] = React.useState<BusinessObject>(null);
  const [selectedLexofficeContact, setSelectedLexofficeContact] = React.useState<LexofficeContact>(null);
  const [selectedTaxType, setSelectedTaxType] = React.useState<LEXOFFICE_TAX_TYPE>(LEXOFFICE_TAX_TYPE.NET);
  const [selectedBillingLanguage, setSelectedBillingLanguage] = React.useState<LEXOFFICE_BILLING_LANGUAGE>(
    LEXOFFICE_BILLING_LANGUAGE.DE
  );

  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [disabledBusinessObjectIds, setDisabledBusinessObjectIds] = React.useState<Set<number>>(new Set());

  const _findUserById = (userId: number) => {
    if (!userId) {
      return null;
    }
    return props.data?.users?.find(u => u.id === userId);
  };

  const _findUserByEmail = (userEmail: string) => {
    if (!userEmail) {
      return null;
    }
    return props.data?.users?.find(u => u.email === userEmail);
  };

  const _findBusinessObjectById = (boId: number) => {
    if (!boId) {
      return null;
    }
    return props.data?.businessObjects?.find(bo => bo.id === boId);
  };

  const _findLexofficeContactById = (id: string) => {
    if (!id) {
      return null;
    }
    return props.data?.lexofficeContacts?.find(c => c.id === id);
  };

  React.useEffect(() => {
    const availableBo = selectedUser?.email
      ? props?.data?.businessObjects?.filter(bo => bo.owner === selectedUser.email)
      : [...(props?.data?.businessObjects || [])];

    // sorting is required for display as group in dropdown
    setBusinessObjects(availableBo.sort((a, b) => -b.owner.localeCompare(a.owner)));

    if (!!selectedBusinessObject?.id && selectedBusinessObject?.owner !== selectedUser?.email) {
      setSelectedBusinessObject(null);
    }
  }, [selectedUser]);

  React.useEffect(() => {
    if (selectedBusinessObject?.id && selectedBusinessObject?.owner !== selectedUser?.email) {
      setSelectedUser(_findUserByEmail(selectedBusinessObject.owner));
    }
  }, [selectedBusinessObject]);

  React.useEffect(() => {
    // all assigned bo (except bo in connection) will be set as disabled to select
    const setOfDisabledBoIds = new Set<number>(
      props?.data?.lexofficeConnections?.filter(c => c.businessObjectId)?.map(c => c.businessObjectId)
    );
    if (props?.data?.connection?.businessObjectId) {
      setOfDisabledBoIds.delete(props.data.connection.businessObjectId);
    }
    setDisabledBusinessObjectIds(setOfDisabledBoIds);

    setSelectedUser(
      _findUserById(props?.data?.connection?.userId) || _findUserByEmail(props?.data?.preselectedBusinessObject?.owner)
    );
    setSelectedBusinessObject(
      _findBusinessObjectById(props?.data?.connection?.businessObjectId) || props?.data?.preselectedBusinessObject
    );
    setSelectedLexofficeContact(
      _findLexofficeContactById(props?.data?.connection?.contactId) || props?.data?.preselectedLexofficeContact
    );

    const locale = props?.data?.preselectedLexofficeContact?.addresses?.billing?.[0]?.countryCode?.toLowerCase();

    if (props?.data?.connection?.id) {
      setSelectedBillingLanguage(props?.data?.connection?.language);
    } else if (locale?.includes(LEXOFFICE_BILLING_LANGUAGE.DE)) {
      setSelectedBillingLanguage(LEXOFFICE_BILLING_LANGUAGE.DE);
    } else {
      setSelectedBillingLanguage(LEXOFFICE_BILLING_LANGUAGE.EN);
    }

    const matchedEuCountryCode = EU_COUNTRY_CODES.find(code => locale?.toString()?.includes(code));
    if (props?.data?.connection?.id) {
      setSelectedTaxType(props?.data?.connection?.taxType);
    } else if (matchedEuCountryCode === 'de') {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.NET);
    } else if (matchedEuCountryCode) {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.INTRA_COMMUNITY_SUPPLY);
    } else {
      setSelectedTaxType(LEXOFFICE_TAX_TYPE.THIRD_PARTY_COUNTRY_SERVICE);
    }
  }, [props]);

  const onCreateNewConnection = async () => {
    if (!!props?.data?.connection?.id) {
      return;
    }

    await props.onCreate(
      selectedUser?.id,
      selectedLexofficeContact,
      selectedTaxType,
      selectedBillingLanguage,
      selectedBusinessObject?.id,
      selectedBusinessObject?.name
    );
  };

  const onUpdateConnection = async () => {
    if (!props?.data?.connection?.id) {
      return;
    }

    const connectionToUpdate: LexofficeConnection = {
      ...props?.data?.connection,
      userId: selectedUser?.id,
      userEmail: selectedUser?.email,
      businessObjectId: selectedBusinessObject?.id,
      businessObjectName: selectedBusinessObject?.name,
      contactId: selectedLexofficeContact.id,
      contactName:
        selectedLexofficeContact.company?.name ||
        `${selectedLexofficeContact.person?.firstName} ${selectedLexofficeContact.person?.lastName}`,
      billingEmails: LexofficeContactUtils.generateEmailAsArrayString(selectedLexofficeContact),
      customerNumber: selectedLexofficeContact.roles?.customer?.number,
      taxType: selectedTaxType,
      language: selectedBillingLanguage,
    };

    await props.onUpdate(selectedUser?.id, connectionToUpdate);
  };

  const onDeleteConnection = async () => {
    await props.onDelete(props.data?.connection?.userId, props.data?.connection?.id);
  };

  const classes = useStyles();

  const getTitle = () => {
    return props?.data?.connection?.id
      ? `Edit connection with id=${props?.data?.connection?.id}`
      : 'Create new connection';
  };

  const getContent = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={props?.data?.users?.sort((a, b) => -b.locale.localeCompare(a.locale))}
              groupBy={option => option.locale}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(item.email).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.phoneNr).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.fullName).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedUser}
              onChange={(event: any, newValue) => setSelectedUser(newValue as User)}
              getOptionLabel={option => `[${option.id}] [${option.email}] [${option.fullName}]`}
              renderOption={option => (
                <>
                  <div>
                    [{option.id}]&nbsp;<strong>{option.email}</strong>
                    <div className={classes.optionDescription}>
                      {option.fullName}
                      <div>{option.phoneNr}</div>
                    </div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="User"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'user-to-assign', // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              style={{ marginTop: '20px' }}
              autoHighlight
              options={businessObjects}
              groupBy={option => option.owner}
              getOptionDisabled={option => disabledBusinessObjectIds.has(option.id)}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    StringUtils.simplifyString(item.name).includes(StringUtils.simplifyString(inputValue)) ||
                    StringUtils.simplifyString(item.address).includes(StringUtils.simplifyString(inputValue))
                )
              }
              value={selectedBusinessObject}
              onChange={(event: any, newValue) => setSelectedBusinessObject(newValue as BusinessObject)}
              getOptionLabel={option => `[${option.id}] ${option.name} [${option.address}]`}
              renderOption={option => (
                <>
                  <div>
                    [{option.id}]&nbsp;<strong>{option.name}</strong>
                    <div className={classes.optionDescription}>{option.address}</div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Salon (optional)"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'salon-to-assign', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              autoHighlight
              options={props?.data?.lexofficeContacts}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  item =>
                    LexofficeContactUtils.isCompanyNameContainsString(inputValue, item) ||
                    LexofficeContactUtils.isContactPersonContainsString(inputValue, item) ||
                    LexofficeContactUtils.isBillingAddressContainsString(inputValue, item) ||
                    LexofficeContactUtils.isAnyEmailContainsString(inputValue, item) ||
                    LexofficeContactUtils.isAnyPhoneNumberContainsString(inputValue, item)
                )
              }
              value={selectedLexofficeContact}
              onChange={(event: any, newValue) => setSelectedLexofficeContact(newValue as LexofficeContact)}
              getOptionLabel={option =>
                `[${LexofficeContactUtils.generateContactPersonAsString(option) || ''}] ${
                  option?.company?.name || ''
                } ${LexofficeContactUtils.generateBillingAddressAsString(option) || ''}`
              }
              renderOption={option => (
                <>
                  <div>
                    <strong>{option.company?.name || '-'}</strong>
                    <div className={classes.optionDescription}>
                      {LexofficeContactUtils.generateContactPersonAsString(option) || ''}
                      <div>{LexofficeContactUtils.generateBillingAddressAsString(option) || ''}</div>
                    </div>
                  </div>
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="Lexoffice contact"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'lexoffice-contact', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="Tax type"
              name="taxType"
              value={selectedTaxType || ''}
              onChange={(event: any) => setSelectedTaxType(event.target?.value)}
              helperText={
                <span>
                  'net' for DE, 'intraCommunitySupply' for other EU countries, 'thirdPartyCountryService' for other
                  countries
                </span>
              }
            >
              {taxTypeOptions.map(type => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              select
              fullWidth
              label="Billing language"
              name="billingLanguage"
              value={selectedBillingLanguage || ''}
              onChange={(event: any) => setSelectedBillingLanguage(event.target?.value)}
            >
              {billingLanguageOptions.map(lang => (
                <MenuItem value={lang} key={lang}>
                  {lang}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </>
    );
  };

  const getButtons = () => {
    if (props?.data?.connection?.id) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={props.isLoading}
              onClick={onDeleteConnection}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onUpdateConnection}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" disabled={props.isLoading} onClick={() => props.onClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={onCreateNewConnection}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <CustomDialogComponent
      isLoading={props.isLoading}
      styleOptions={{ fullWidth: true }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
