import React from 'react';
import { Button, Grid, LinearProgress, TextField } from '@material-ui/core';
import { GoogleApiPlace } from '../../../../../../models/interfaces/GoogleApiPlace.interface';
import logger from '../../../../../../commons/logger';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import googleApiService from '../../../../../../services/google-api.service';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  marginTopAndBottom: {
    margin: theme.spacing(2, 0),
  },
  placeImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  placeImageWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    maxHeight: '150px',
  },
  placeContainer: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: '4px',
    border: '2px solid white',
  },
  placeContainerSelected: {
    border: '2px solid #3F51B5 !important',
  },
}));

export interface MatchedGooglePlaceResult {
  latitude?: number;
  longitude?: number;
  placeId?: string;
  address?: string;
}

interface GooglePlaceSearchDialogComponentProps {
  isOpen: boolean;
  address?: string;
  placeId?: string;
  onSave: Function;
  onClose: Function;
}

export default function GooglePlaceSearchDialogComponent(props: GooglePlaceSearchDialogComponentProps) {
  const [address, setAddress] = React.useState<string>(null);
  const [keyword, setKeyword] = React.useState<string>('nails');
  const [googlePlaces, setGooglePlaces] = React.useState<GoogleApiPlace[]>([]);
  const [result, setResult] = React.useState<MatchedGooglePlaceResult>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const addressChangeHandler = (event: any) => {
    const { value } = event.target;
    setAddress(value);
  };

  const keywordChangeHandler = (event: any) => {
    const { value } = event.target;
    setKeyword(value);
  };

  const isPlaceSelected = (place: GoogleApiPlace): boolean => {
    return place?.place_id === result?.placeId;
  };

  const selectPlace = (place: GoogleApiPlace): void => {
    setResult({
      placeId: place.place_id,
      longitude: place.geometryLocationData?.lng,
      latitude: place.geometryLocationData?.lat,
      address: place.vicinity,
    });
  };

  const fetchGooglePlace = async (inputAddress: string, inputKeyword: string) => {
    setResult(null);

    if (!inputAddress || !props.isOpen) {
      setGooglePlaces([]);
      return;
    }

    try {
      setIsLoading(true);

      const data = await googleApiService.getSalonNearby(inputAddress, inputKeyword);
      setGooglePlaces(data || []);

      const matchedPlace = data?.find(pl => pl.place_id === props?.placeId);
      if (matchedPlace?.place_id) {
        selectPlace(matchedPlace);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setAddress(props?.address);
    fetchGooglePlace(props?.address, keyword);
  }, [props]);

  const classes = useStyles();

  const getTitle = () => {
    return `Find Google place for ${props?.address}`;
  };

  const getContent = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*Address*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="address"
              label="Address"
              name="address"
              value={address || ''}
              onChange={addressChangeHandler}
              placeholder="What is the address of business?"
              helperText={
                <span>
                  Hint: Make sure salon has google my business. In case not found any salon, remove zip code (PLZ) of
                  address and try again.
                </span>
              }
            />
          </Grid>

          <Grid item xs={6}>
            {/*Keyword*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="keyword"
              label="Keyword"
              name="keyword"
              value={keyword || ''}
              onChange={keywordChangeHandler}
              placeholder="What is type of business?"
              helperText={<span>For example: nails (default), beauty, lashes, hair, etc.</span>}
            />
          </Grid>
        </Grid>

        <Button
          className={classes.marginTopAndBottom}
          type="submit"
          fullWidth
          variant="outlined"
          color="primary"
          disabled={isLoading || !address}
          onClick={() => fetchGooglePlace(address, keyword)}
        >
          Search
        </Button>

        {isLoading ? <LinearProgress /> : <></>}

        {googlePlaces?.map(place => (
          <div
            key={place.place_id}
            className={classes.placeContainer + ' ' + `${isPlaceSelected(place) ? classes.placeContainerSelected : ''}`}
            onClick={() => selectPlace(place)}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className={classes.placeImageWrapper}>
                  <img className={classes.placeImage} src={place.photoUrls?.[0]} alt={place.name} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <h3>{place.name}</h3>
                <div>Address: {place.vicinity}</div>
                <div>
                  Rating: {place.rating} ({place.user_ratings_total} reviews)
                </div>
                <div>PlaceID={place.place_id}</div>
                <div>
                  Longitude={place.geometryLocationData?.lng} - Latitude={place.geometryLocationData?.lat}
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
      </>
    );
  };

  const getButtons = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button fullWidth variant="contained" disabled={isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading || !result || !googlePlaces?.length}
            onClick={() => props.onSave(result)}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      styleOptions={{ fullWidth: true }}
      title={getTitle()}
      content={getContent()}
      buttons={getButtons()}
    ></CustomDialogComponent>
  );
}
