import React from 'react';
import { ShortenedLink } from '../../../../../models/interfaces/ShortenedLink.interface';
import logger from '../../../../../commons/logger';
import shortenedLinkService from '../../../../../services/shortened-link.service';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, LinearProgress, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Cancel, Edit, Save } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  editButton: {
    width: 'fit-content',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface ShortenedLinkFormComponentProps {
  shortenedLink: ShortenedLink;
}

export default function ShortenedLinkFormComponent(props: ShortenedLinkFormComponentProps) {
  const [isEmitMode, setIsEmitMode] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [shortenedLinkToSubmit, setShortenedLinkToSubmit] = React.useState<ShortenedLink>(null);

  React.useEffect(() => {
    setShortenedLinkToSubmit(props?.shortenedLink);
  }, [props]);

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    setShortenedLinkToSubmit({
      ...shortenedLinkToSubmit,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const reset = () => {
    setShortenedLinkToSubmit(props.shortenedLink);
    setIsEmitMode(false);
  };

  const submit = async (event: any) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const isSuccess = await shortenedLinkService.updateShortenedLink(shortenedLinkToSubmit);

      if (isSuccess) {
        setIsEmitMode(false);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  return !shortenedLinkToSubmit?.id ? (
    <></>
  ) : (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          Shortened link details
        </Typography>

        {/*Edit button*/}
        <Button
          variant="contained"
          color="primary"
          startIcon={<Edit />}
          disabled={isLoading}
          onClick={() => {
            setIsEmitMode(true);
          }}
          className={classes.editButton}
        >
          Edit
        </Button>
      </Toolbar>

      <form className={classes.form} onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*ID*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="id"
              label="ID"
              name="id"
              value={shortenedLinkToSubmit?.id || ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            {/*Short Version*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              required
              fullWidth
              key="shortVersion"
              label="Shortened link"
              name="shortVersion"
              value={shortenedLinkToSubmit?.shortVersion || ''}
              disabled={true}
            />
          </Grid>
        </Grid>

        {/*Link to shorted*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          multiline
          fullWidth
          key="originalLink"
          label="Link to shorten"
          name="originalLink"
          value={shortenedLinkToSubmit?.originalLink || ''}
          disabled={!isEmitMode}
          onChange={submitDataChangeHandler}
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/*createdAt*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="createdAt"
              label="Created at"
              name="createdAt"
              disabled={true}
              value={shortenedLinkToSubmit?.createdAt || ''}
            />
          </Grid>

          <Grid item xs={6}>
            {/*lastUpdatedAt*/}
            <TextField
              variant="outlined"
              margin="dense"
              type="text"
              fullWidth
              key="lastUpdatedAt"
              label="Last updated at"
              name="lastUpdatedAt"
              disabled={true}
              value={shortenedLinkToSubmit?.lastUpdatedAt || ''}
            />
          </Grid>
        </Grid>

        {/*Submit button*/}
        {isEmitMode ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                disabled={isLoading}
                onClick={reset}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
                startIcon={<Save />}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </form>

      {isLoading ? <LinearProgress /> : <></>}
    </Paper>
  );
}
