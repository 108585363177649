import React from 'react';
import CustomDialogComponent from '../../../../../../commons/components/custom-dialog/custom-dialog.component';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { IsoCountryCodeWithPhoneCountryCode } from '../../../../../../models/interfaces/IsoCountryCodeWithPhoneCountryCode.interface';
import smsService from '../../../../../../services/sms.service';
import QuickCreateUpdatePackageStep1 from './quick-create-update-package-step-1';
import QuickCreateUpdatePackageStep2 from './quick-create-update-package-step-2';
import QuickCreateUpdatePackageStep3 from './quick-create-update-package-step-3';
import { EurPriceOfProvider } from './interfaces/EurPriceOfProvider.interface';
import { CreditPriceOfProvider } from './interfaces/CreditPriceOfProvider.interface';

interface QuickCreateUpdatePackageDialogComponentProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: Function;
  onSubmit: Function;
}

export default function QuickCreateUpdatePackageDialogComponent(props: QuickCreateUpdatePackageDialogComponentProps) {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [selectedIsoCodes, setSelectedIsoCodes] = React.useState<string[]>([]);
  const [isoCodeWithCountryCodes, setIsoCodeWithCountryCodes] = React.useState<IsoCountryCodeWithPhoneCountryCode[]>(
    []
  );
  const [mapOfIsoCodeAndProviderPrice, setMapOfIcoCodeAndProviderPrice] = React.useState<
    Map<string, EurPriceOfProvider[]>
  >(new Map<string, EurPriceOfProvider[]>());

  const fetchIsoCodeWithCountryCodes = async () => {
    const response = await smsService.getAllCountryCodes();
    setIsoCodeWithCountryCodes(response);
  };

  React.useEffect(() => {
    setActiveStep(0);
  }, [props.isOpen]);

  React.useEffect(() => {
    fetchIsoCodeWithCountryCodes();
  }, []);

  const selectedIsoCodeListHandler = (selectedIsoCodeList: string[]) => {
    setSelectedIsoCodes(selectedIsoCodeList);
    handleNext();
  };

  const updateNewEurProviderPriceHandler = (data: Map<string, EurPriceOfProvider[]>) => {
    setMapOfIcoCodeAndProviderPrice(data);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSubmit = (data: Map<string, CreditPriceOfProvider[]>) => {
    props.onSubmit(data);
  };

  const onClose = () => {
    props.onClose();
  };

  const getContent = () => {
    return (
      <>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="step-1">
            <StepLabel>Select country to update</StepLabel>
          </Step>
          <Step key="step-2">
            <StepLabel>Set new price in EUR</StepLabel>
          </Step>
          <Step key="step-3">
            <StepLabel>Confirmation new credit price</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 ? (
          <QuickCreateUpdatePackageStep1
            isoCodeWithCountryCodes={isoCodeWithCountryCodes}
            selectedIsoCodes={selectedIsoCodes}
            onNext={selectedIsoCodeListHandler}
            onCancel={props.onClose}
          ></QuickCreateUpdatePackageStep1>
        ) : (
          <></>
        )}

        {activeStep === 1 ? (
          <QuickCreateUpdatePackageStep2
            isoCodeWithCountryCodes={isoCodeWithCountryCodes}
            selectedIsoCodes={selectedIsoCodes}
            mapOfIsoCodeAndEurPriceOfProvider={mapOfIsoCodeAndProviderPrice}
            onUpdateEurPriceOfProvider={updateNewEurProviderPriceHandler}
            onNext={handleNext}
            onBack={handleBack}
          ></QuickCreateUpdatePackageStep2>
        ) : (
          <></>
        )}

        {activeStep === 2 ? (
          <QuickCreateUpdatePackageStep3
            isoCodeWithCountryCodes={isoCodeWithCountryCodes}
            selectedIsoCodes={selectedIsoCodes}
            mapOfIsoCodeAndEurPriceOfProvider={mapOfIsoCodeAndProviderPrice}
            isLoading={props.isLoading}
            onSubmit={onSubmit}
            onBack={handleBack}
          ></QuickCreateUpdatePackageStep3>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getHints = () => {
    return (
      <>
        {activeStep === 0 ? <div>Please select at least 1 country to update or to create</div> : <></>}

        {activeStep === 1 ? <div>Set price in EUR to update/create. Not set = not update/create</div> : <></>}

        {activeStep === 2 ? (
          <div>Please review before submit. Red = price increased, Green = price reduced</div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <CustomDialogComponent
      isOpen={props.isOpen}
      onClose={onClose}
      title="Quick create/update all packages"
      content={getContent()}
      buttons={getHints()}
      styleOptions={{ fullWidth: true }}
    ></CustomDialogComponent>
  );
}
