import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { User } from '../../../../../../models/interfaces/User.interface';
import { Link } from 'react-router-dom';
import CategoryFormComponent from '../../components/category-form/category-form.component';
import { getBoDetailPath } from '../../../business-object-context/routing/business-object-route-definition.model';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      marginBottom: '20px',
    },
  })
);

export default function CategoryCreateComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>Create new category</div>

      <CategoryFormComponent ownerId={user?.id} ownerEmail={user?.email} businessObjectId={businessObject?.id} />

      <Link to={getBoDetailPath(user?.id, businessObject?.id)}>Back to business object</Link>
    </div>
  );
}
