import React from 'react';
import CategoryRoutingComponent from './routing/category-routing.component';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import BusinessObjectInfoCardComponent from '../business-object-context/components/business-object-info-card/business-object-info-card.component';

export default function CategoryContextComponent(props: any) {
  const businessObject: BusinessObject = props.businessObject;

  return (
    <>
      <BusinessObjectInfoCardComponent businessObject={businessObject}></BusinessObjectInfoCardComponent>
      <CategoryRoutingComponent {...props} businessObject={businessObject} />
    </>
  );
}
