import React from 'react';
import UserDetailRoutingComponent from './routing/user-detail-routing.component';
import { User } from '../../../../../../models/interfaces/User.interface';
import userService from '../../../../../../services/user.service';
import adminService from '../../../../../../services/admin.service';
import { USER_ROLE } from '../../../../../../models/enums/UserRole.enum';

export default function UserDetailContextComponent(props: any) {
  const { match } = props;

  const [user, setUser] = React.useState<User>(null);

  React.useEffect(() => {
    const getUserById = async (userId: number) => {
      const response = await userService.getUserById(userId);
      const authoritiesOfUser = await adminService.getAuthoritiesOfUser(userId);

      response.role = authoritiesOfUser
        ?.map(authority => {
          if (authority?.name === 'USER_LITE') {
            return USER_ROLE.USER_LITE;
          } else if (authority?.name === 'USER_LITE_PLUS') {
            return USER_ROLE.USER_LITE_PLUS;
          } else if (authority?.name === 'USER') {
            return USER_ROLE.OWNER;
          } else if (authority?.name === 'ADMIN') {
            return USER_ROLE.ADMIN;
          } else {
            return null;
          }
        })
        ?.find(role => !!role);

      setUser(response);
    };

    const userId = match?.params?.userId;
    getUserById(userId);
  }, []);

  return user ? <UserDetailRoutingComponent {...props} user={user} /> : <></>;
}
