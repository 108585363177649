import ScrollToTop from '../../../../../commons/utils/scroll-to-top';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../../../routes/private-route.component';
import React from 'react';
import { CATEGORY_ROUTE } from './category-route-definition.model';
import CategoryCreateComponent from '../pages/category-create/category-create.component';
import CategoryDetailContextComponent from '../pages/category-detail-context/category-detail-context.component';
import CategoryListComponent from '../pages/category-list/category-list.component';
import MassiveCreateCategoryAndProductComponent from '../pages/massive-create-category-and-product/massive-create-category-and-product.component';

export default function CategoryRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} path={CATEGORY_ROUTE.CATEGORY_CREATE} component={CategoryCreateComponent} />
          <PrivateRoute
            {...props}
            path={CATEGORY_ROUTE.MASSIVE_CREATE}
            component={MassiveCreateCategoryAndProductComponent}
          />
          <PrivateRoute {...props} path={CATEGORY_ROUTE.CATEGORY_DETAIL} component={CategoryDetailContextComponent} />
          <PrivateRoute {...props} component={CategoryListComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
