import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InvoiceJob } from '../../../../models/interfaces/InvoiceJob.interface';
import { LexofficeConnection } from '../../../../models/interfaces/LexofficeConnection.interface';
import { CommunicationUnitPackage } from '../../../../models/interfaces/CommunicationUnitPackage.interface';
import smsService from '../../../../services/sms.service';
import adminService from '../../../../services/admin.service';
import { StringUtils } from '../../../../commons/utils/string.util';
import logger from '../../../../commons/logger';
import { Button, Grid, LinearProgress, TextField } from '@material-ui/core';
import InvoiceJobDialogComponent, { InvoiceJobDataForDialog } from './components/invoice-job-dialog.component';
import InvoiceJobTableComponent from './components/invoice-job-table.component';
import { PersonAdd } from '@material-ui/icons';
import { User } from '../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import userService from '../../../../services/user.service';
import businessObjectService from '../../../../services/business-object.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterForm: {
      marginBottom: theme.spacing(1),
    },
  })
);

export default function InvoiceJobPageComponent() {
  const [users, setUsers] = React.useState<User[]>([]);
  const [businessObjects, setBusinessObjects] = React.useState<BusinessObject[]>([]);
  const [lexofficeConnections, setLexofficeConnections] = React.useState<LexofficeConnection[]>([]);
  const [communicationUnitPackages, setCommunicationUnitPackages] = React.useState<CommunicationUnitPackage[]>([]);

  const [invoiceJobs, setInvoiceJobs] = React.useState<InvoiceJob[]>([]);
  const [invoiceJobsKeywordFilter, setInvoiceJobsKeywordFilter] = React.useState<string>(null);
  const [filteredInvoiceJobs, setFilteredInvoiceJobs] = React.useState<InvoiceJob[]>([]);

  const [invoiceJobDataForDialog, setInvoiceJobDataForDialog] = React.useState<InvoiceJobDataForDialog>(null);
  const [isOpenInvoiceJobDialog, setIsOpenInvoiceJobDialog] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const _fetchAllBusinessOwnerUsers = async () => {
    const data = await userService.getAllBusinessOwnerUsers();
    setUsers(data);
  };

  const _fetchAllBusinessObjects = async () => {
    const data = await businessObjectService.getAllBusinessObjects();
    setBusinessObjects(data);
  };

  const _fetchAllCreditPackages = async () => {
    const data = await smsService.getAllCommunicationUnitPackages();
    setCommunicationUnitPackages(data);
  };

  const _fetchAllLexofficeConnections = async () => {
    const data = await adminService.fetchAllLexofficeConnections();
    setLexofficeConnections(data || []);
  };

  const _fetchAllInvoiceJobs = async () => {
    const data = await adminService.fetchAllInvoiceJobs();
    setInvoiceJobs(data || []);
  };

  const _updateInvoiceJobsToDisplay = () => {
    const keyword = StringUtils.simplifyString(invoiceJobsKeywordFilter);

    setFilteredInvoiceJobs(
      invoiceJobs?.filter(invoiceJob => {
        if (!keyword?.length) {
          return true;
        }

        if (StringUtils.simplifyString(invoiceJob.userEmail).includes(keyword)) {
          return true;
        }

        if (StringUtils.simplifyString(invoiceJob.businessObjectName).includes(keyword)) {
          return true;
        }

        if (StringUtils.simplifyString(invoiceJob.connection.userEmail).includes(keyword)) {
          return true;
        }

        if (StringUtils.simplifyString(invoiceJob.connection.contactName).includes(keyword)) {
          return true;
        }

        return false;
      })
    );
  };

  const _init = async () => {
    setIsLoading(true);

    try {
      await Promise.all([
        _fetchAllBusinessOwnerUsers(),
        _fetchAllBusinessObjects(),
        _fetchAllLexofficeConnections(),
        _fetchAllCreditPackages(),
        _fetchAllInvoiceJobs(),
      ]);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    _init();
  }, []);

  React.useEffect(() => {
    _updateInvoiceJobsToDisplay();
  }, [invoiceJobs, invoiceJobsKeywordFilter]);

  const _closeInvoiceJobDialog = () => {
    setIsOpenInvoiceJobDialog(false);
    setInvoiceJobDataForDialog(null);
  };

  const _openInvoiceJobDialog = (invoiceJob: InvoiceJob) => {
    if (isLoading) {
      return;
    }

    setInvoiceJobDataForDialog({
      invoiceJob,
      users,
      businessObjects,
      lexofficeConnections,
      communicationUnitPackages,
    });
    setIsOpenInvoiceJobDialog(true);
  };

  const onCloseLexofficeConnectionDialog = () => {
    if (isLoading) {
      return;
    }

    _closeInvoiceJobDialog();
  };

  const onSelectInvoiceJob = (invoiceJob: InvoiceJob) => {
    _openInvoiceJobDialog(invoiceJob);
  };

  const onCreateInvoiceJob = async (invoiceJob: InvoiceJob) => {
    setIsLoading(true);

    try {
      const createdInvoiceJob = await adminService.createInvoiceJob(invoiceJob);

      if (createdInvoiceJob?.id) {
        await _init();
        _closeInvoiceJobDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateInvoiceJob = async (invoiceJob: InvoiceJob) => {
    setIsLoading(true);

    try {
      const updatedInvoiceJob = await adminService.updateInvoiceJob(invoiceJob);

      if (updatedInvoiceJob?.id) {
        await _init();
        _closeInvoiceJobDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteInvoiceJob = async (invoiceJobId: number) => {
    setIsLoading(true);

    try {
      const isSuccess = await adminService.deleteInvoiceJobById(invoiceJobId);

      if (isSuccess) {
        await _init();
        _closeInvoiceJobDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <>
      {isLoading ? <LinearProgress /> : <></>}

      {isOpenInvoiceJobDialog ? (
        <InvoiceJobDialogComponent
          isOpen={isOpenInvoiceJobDialog}
          isLoading={isLoading}
          data={invoiceJobDataForDialog}
          onClose={onCloseLexofficeConnectionDialog}
          onCreate={onCreateInvoiceJob}
          onUpdate={onUpdateInvoiceJob}
          onDelete={onDeleteInvoiceJob}
        ></InvoiceJobDialogComponent>
      ) : (
        <></>
      )}

      <Grid container spacing={3} className={classes.filterForm}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="dense"
            type="text"
            fullWidth
            label="Search"
            name="search"
            value={invoiceJobsKeywordFilter || ''}
            onChange={event => setInvoiceJobsKeywordFilter(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={<PersonAdd />}
            onClick={() => onSelectInvoiceJob(null)}
          >
            Create new invoice job
          </Button>
        </Grid>
      </Grid>

      <InvoiceJobTableComponent
        invoiceJobs={filteredInvoiceJobs}
        onSelect={onSelectInvoiceJob}
      ></InvoiceJobTableComponent>
    </>
  );
}
