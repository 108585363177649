import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { PUBLIC_ROUTE, PRIVATE_ROUTE } from './route-definition.model';
import Frame from '../components/private/private.component';
import NotFound from '../components/public/not-found/not-found.component';
import PrivateRoute from './private-route.component';
import ScrollToTop from '../commons/utils/scroll-to-top';
import AuthProcessComponent from '../components/public/auth-process/auth-process.component';

export default function RouteMapping() {
  return (
    <Router>
      <div>
        <ScrollToTop>
          <Switch>
            <Route path={PUBLIC_ROUTE.AUTH_PROCESS} component={AuthProcessComponent} />

            <PrivateRoute exac path={PRIVATE_ROUTE.FRAME} component={Frame} />

            <Route component={NotFound} />
          </Switch>
        </ScrollToTop>
      </div>
    </Router>
  );
}
