class ScriptLoaderService {
  private loadedScripts: Set<string> = new Set<string>();

  public loadScript(src: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.isScriptLoaded(src)) {
        resolve(true);
      } else {
        //load script
        let script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.loadedScripts.add(src);
              resolve(true);
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.loadedScripts.add(src);
            resolve(true);
          };
        }
        script.onerror = (error: any) => resolve(false);
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  private isScriptLoaded(src: string): boolean {
    return this.loadedScripts.has(src);
  }
}

const scriptLoaderService = new ScriptLoaderService();
export default scriptLoaderService;
