import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TopUp } from '../../../../../../models/interfaces/TopUp.interface';
import logger from '../../../../../../commons/logger';
import Paper from '@material-ui/core/Paper';
import { Button, InputAdornment, TextField, Toolbar } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import smsService from '../../../../../../services/sms.service';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface TopUpFormComponentProps {
  ownerId: number;
  creditBudget?: number;
  onTopUp?: Function;
}

export default function TopUpFormComponent(props: TopUpFormComponentProps) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [topUpData, setTopUpData] = React.useState<TopUp>({});

  const submitDataChangeHandler = (event: any) => {
    const { name, value, type, checked } = event.target;

    // @ts-ignore
    setTopUpData({
      ...topUpData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const submit = async (event: any) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      const ownerId = props.ownerId;

      const response = await smsService.topUp(ownerId, topUpData);

      if (response) {
        setTopUpData({});

        if (props.onTopUp) {
          props.onTopUp(true);
        }
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          Top up Credit
        </Typography>

        <div>
          Current Credit budget:{' '}
          <strong>
            <big>{props?.creditBudget || 0}</big> Credit
          </strong>
        </div>
      </Toolbar>

      <form className={classes.form} onSubmit={submit}>
        {/*Amount*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="number"
          required
          fullWidth
          key="amount"
          id="amount"
          label="Amount"
          name="amount"
          value={topUpData?.amount || ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">Credit</InputAdornment>,
          }}
          disabled={isSubmitting}
          onChange={submitDataChangeHandler}
        />

        {/*Description*/}
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          required
          fullWidth
          key="description"
          id="description"
          label="Description"
          name="description"
          value={topUpData?.description || ''}
          disabled={isSubmitting}
          onChange={submitDataChangeHandler}
          helperText={
            <span>
              Example: Anh Tung mua goi 2000 credit (tri gia 115 Euro), Khuyen mai dung thu 6 thang va 5000 credit mien
              phi
            </span>
          }
        />

        {/*Submit button*/}
        {
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
            startIcon={<AddShoppingCartIcon />}
          >
            Submit
          </Button>
        }
      </form>
    </Paper>
  );
}
