import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import React from 'react';
import ProductTableComponent from '../../components/product-table/product-table.component';
import { Link } from 'react-router-dom';
import { getCategoryDetailPath } from '../../../category-context/routing/category-route-definition.model';

export default function ProductListComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;
  const category: ProductCategory = props.category;

  return (
    <div>
      <ProductTableComponent
        userId={user?.id}
        businessObjectId={businessObject?.id}
        categoryId={category?.id}
        products={category?.products}
      ></ProductTableComponent>

      <div>
        <Link to={getCategoryDetailPath(user?.id, businessObject?.id, category?.id)}>Back to category</Link>
      </div>
    </div>
  );
}
