export enum __TYPE__ {
  BASIC_APPOINTMENT = 'basic-appointment',
  BASIC_APPOINTMENT_MESSAGE = 'basic-appointment-message',
  BASIC_APPOINTMENT_SERVICE = 'basic-appointment-service',
  BASIC_APPOINTMENT_SETTINGS = 'basic-appointment-settings',
  BASIC_BASE_ORDER = 'basic-base-order',
  BASIC_BASE_ORDER_ITEM = 'basic-base-order-item',
  BASIC_BIRTHDAY_PROMOTION = 'basic-birthday-promotion',
  BASIC_BIRTHDAY_PROMOTION_DISCOUNT_REDEMPTION = 'basic-birthday-promotion-discount-redemption',
  BASIC_BIRTHDAY_REWARD_PROMOTION_DISCOUNT = 'basic-birthday-reward-promotion-discount',
  BASIC_BUSINESS_OBJECT = 'basic-business-object',
  BASIC_CUSTOMER_BUSINESS_OBJECT_VISIT_PROFILE = 'basic-customer-business-object-visit-profile',
  BASIC_CUSTOMER = 'basic-customer',
  BASIC_CUSTOMER_INFO = 'basic-customer-info',
  BASIC_CUSTOMER_TYPE_BASED_PROMOTION = 'basic-customer-type-based-promotion',
  BASIC_CUSTOMER_TYPE_BASED_PROMOTION_DISCOUNT_REDEMPTION = 'basic-customer-type-based-promotion-discount-redemption',
  BASIC_CUSTOMER_TYPE_BASED_REWARD_PROMOTION_DISCOUNT = 'basic-customer-typed-base-reward-promotion-discount',
  BASIC_CREDENTIALS = 'basic-credentials',
  BASIC_DAILY_CUSTOMER_CHECKIN_STATISTIC = 'basic-daily-customer-check-in-statistics',
  BASIC_DAILY_INCOME_STATISTICS = 'basic-daily-income-statistics',
  BASIC_DAILY_SENT_SMS_STATISTIC = 'basic-daily-sent-sms-statistics',
  BASIC_DISCOUNT_REDEMPTION_INFO = 'basic-discount-redemption-info',
  BASIC_ORDER = 'basic-order',
  BASIC_ORDER_CHECKOUT_INFO = 'basic-order-checkout-info',
  BASIC_ORDER_ITEM = 'basic-order-item',
  BASIC_ORDER_UPDATE = 'basic-order-update',
  BASIC_PRODUCT = 'basic-product',
  BASIC_PRODUCT_CATEGORY = 'basic-product-category',
  BASIC_REMINDER_PROMOTION = 'basic-reminder-promotion',
  BASIC_REMINDER_PROMOTION_DISCOUNT_REDEMPTION = 'basic-reminder-promotion-discount-redemption',
  BASIC_REMINDER_REWARD_PROMOTION_DISCOUNT = 'basic-reminder-reward-promotion-discount',
  BASIC_REVIEW = 'basic-review',
  BASIC_REVIEW_BUSINESS_OBJECT = 'basic-review-business-object',
  BASIC_REVIEW_REPLY = 'basic-review-reply',
  BASIC_REWARD_BONUS_POINT_BASED_DISCOUNT = 'basic-reward-bonus-point-based-discount',
  BASIC_SETTINGS_RULE = 'basic-settings-rule',
  BASIC_STAFF = 'basic-staff',
  BASIC_USER = 'basic-user',
  BASIC_WORK_SPACE = 'basic-work-place',
}
