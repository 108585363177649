import React from 'react';
import ENV from '../../../../commons/environment';
import IframeResizer from 'iframe-resizer-react';
import { IFRAME_EVENT_NAME } from '../../../../models/consts/IframeEventName.const';
import { Button, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core';
import { User } from '../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../models/interfaces/BusinessObject.interface';
import storageService from '../../../../services/storage.service';
import logger from '../../../../commons/logger';
import { PersonAdd } from '@material-ui/icons';
import userService from '../../../../services/user.service';
import businessObjectService from '../../../../services/business-object.service';
import authenticationService from '../../../../services/auth.service';
import CreateNewUserTokenDialogComponent from './components/create-new-user-token-dialog.component';

export interface TokenInfo {
  user: User;
  boList: BusinessObject[];
  token: string;
}

const WATCHLIST_STORAGE_KEY = 'WATCHLIST';

export default function UserWatchlistComponent(props: any) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = React.useState<string>(ENV.BEAUTY_ADMIN_URL);
  const [watchList, setWatchList] = React.useState<TokenInfo[]>([]);
  const [selectedTokenInfo, setSelectedTokenInfo] = React.useState<TokenInfo>(null);

  // use this syntax to trigger function on init
  React.useEffect(() => {
    loadWatchListFromStorage();
    addEventListener();
  }, []);

  React.useEffect(() => {
    setIframeUrl(`${ENV.BEAUTY_ADMIN_URL}/auth/login?tkn=${encodeURIComponent(selectedTokenInfo?.token)}`);
  }, [selectedTokenInfo]);

  const updateWatchList = (newWatchlist: TokenInfo[]) => {
    newWatchlist.sort((a, b) => {
      if (a.user.id < b.user.id) {
        return 1;
      }
      if (a.user.id > b.user.id) {
        return -1;
      }
      return 0;
    });
    setWatchList(newWatchlist);
    storageService.setItem(WATCHLIST_STORAGE_KEY, newWatchlist);
  };

  const removeToken = (token: string) => {
    const newWatchList = watchList?.filter(tokenInfo => tokenInfo.token !== token);
    updateWatchList(newWatchList);
  };

  const addOrUpdateToken = async (token: string) => {
    const tokenInfo = await generateTokenInfo(token);

    if (!tokenInfo?.user?.email) {
      return false;
    }

    const matchedTokenInfo = watchList?.find(tk => tk.user?.email === tokenInfo.user.email);

    if (matchedTokenInfo) {
      // update token info
      const newWatchList = watchList.map(tk => (tk.user?.email === tokenInfo.user.email ? tokenInfo : tk));
      updateWatchList(newWatchList);
    } else {
      // add new token info
      const newWatchList = [...(watchList || []), tokenInfo];
      updateWatchList(newWatchList);
    }

    setSelectedTokenInfo(tokenInfo);

    return true;
  };

  const loginAndAddOrUpdateTokenInfo = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const token = await authenticationService.loginAndReturnTokenOnly(email, password);

      const isSuccess = await addOrUpdateToken(token);

      if (isSuccess) {
        hideLoginDialog();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const generateTokenInfo = async (token: string) => {
    try {
      setIsLoading(true);

      const tokenInfo: TokenInfo = {
        token,
        user: null,
        boList: [],
      };

      await Promise.all([
        userService.getCurrentLoggedInUserByToken(token).then(user => (tokenInfo.user = user)),
        businessObjectService
          .getAllBusinessObjectsOfUserByToken(token)
          .then(boList => (tokenInfo.boList = boList || [])),
      ]);

      setIsLoading(false);

      return tokenInfo;
    } catch (e) {
      logger.error(e);
      setIsLoading(false);

      return null;
    }
  };

  const loadWatchListFromStorage = () => {
    try {
      const data = storageService.getItem(WATCHLIST_STORAGE_KEY);
      if (data?.length > 0) {
        setWatchList(data);
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const addEventListener = () => {
    window.addEventListener('message', e => {
      try {
        const event = JSON.parse(e?.data);
        const data = event?.data;
        switch (event?.type) {
          case IFRAME_EVENT_NAME.SAVE_TOKEN:
            addOrUpdateToken(data);
            break;
          case IFRAME_EVENT_NAME.REMOVE_TOKEN:
            removeToken(data);
            break;
          case IFRAME_EVENT_NAME.OPEN_URL:
            window.open(data?.url, '_blank');
            break;
        }
      } catch (error) {
        // deactivate because of too many logs
        // logger.error(error);
      }
    });
  };

  const selectTokenHandler = (event: any) => {
    const token = event.target?.value;
    const matchedTokenInfo = watchList?.find(tk => tk.token === token);
    setSelectedTokenInfo(matchedTokenInfo);
  };

  const showLoginDialog = () => {
    if (isLoading) {
      return;
    }
    setIsOpenDialog(true);
  };

  const hideLoginDialog = () => {
    if (isLoading) {
      return;
    }
    setIsOpenDialog(false);
  };

  return (
    <>
      <CreateNewUserTokenDialogComponent
        isOpen={isOpenDialog}
        isLoading={isLoading}
        onClose={hideLoginDialog}
        onSubmit={loginAndAddOrUpdateTokenInfo}
      ></CreateNewUserTokenDialogComponent>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={<PersonAdd />}
            onClick={showLoginDialog}
          >
            Add new user to watch list
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <TextField
            variant="outlined"
            margin="dense"
            select
            fullWidth
            label="Selected user"
            name="selectedUser"
            disabled={!watchList?.length}
            value={selectedTokenInfo?.token || ''}
            onChange={selectTokenHandler}
          >
            {watchList.map(tokenInfo => (
              <MenuItem value={tokenInfo.token} key={tokenInfo.token}>
                <div>
                  [{tokenInfo.user?.id}] [{tokenInfo.user?.email}] [{tokenInfo.user?.fullName}]
                </div>
                <ul>
                  {tokenInfo.boList?.map(bo => (
                    <li key={bo.id}>
                      [{bo.id}] [{bo.name}] [{bo.address}]
                    </li>
                  ))}
                </ul>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {isLoading ? <LinearProgress /> : <></>}

      {selectedTokenInfo?.token ? (
        <div style={{ width: '100%', flexGrow: '1', marginTop: '10px' }}>
          <IframeResizer
            scrolling={true}
            src={iframeUrl}
            onLoad={() => {}}
            style={{ minWidth: '100%', minHeight: '100%', border: '0' }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
