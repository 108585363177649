import React from 'react';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import { User } from '../../../../../../models/interfaces/User.interface';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Button, Grid, TextField, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Cancel, Save } from '@material-ui/icons';
import { getBoDetailPath } from '../../../business-object-context/routing/business-object-route-definition.model';
import logger from '../../../../../../commons/logger';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import { Product } from '../../../../../../models/interfaces/Product.interface';
import { Alert } from '@material-ui/lab';
import businessObjectService from '../../../../../../services/business-object.service';
import { __TYPE__ } from '../../../../../../models/enums/__Type__.enum';
import { NumberUtils } from '../../../../../../commons/utils/number.utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  validationMessage: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const example = [
  {
    name: 'Category 1',
    description: 'Category description is not required',
    products: [
      {
        name: 'Product 1',
        description: 'Product description is not required',
        price: 10,
        duration: 60,
      },
      {
        name: 'Product 2',
        description: 'Product description is not required',
        price: 20,
        duration: 30,
      },
    ],
  },
  {
    name: 'Category 2',
    description: 'Category description is not required',
  },
];

const template = JSON.stringify(example, null, 2);

interface RequestLog {
  type: 'error' | 'success';
  text: string;
}

export default function MassiveCreateCategoryAndProductComponent(props: any) {
  const user: User = props.user;
  const businessObject: BusinessObject = props.businessObject;

  const [redirectToBoDetail, setRedirectToBoDetail] = React.useState<string>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [dataToSubmit, setDataToSubmit] = React.useState<any>(null);

  const [validationSuccess, setValidationSuccess] = React.useState<string[]>([]);
  const [validationWarn, setValidationWarn] = React.useState<string[]>([]);
  const [validationError, setValidationError] = React.useState<string[]>([]);

  const [requestLogs, setRequestLogs] = React.useState<RequestLog[]>([]);

  const isAbleToMassiveUpdate = !businessObject?.categories?.length;

  const parseToProduct = (obj: any): Product => {
    try {
      const price = Number(obj.price);

      const product: Product = {
        __type__: __TYPE__.BASIC_PRODUCT,
        enabled4Booking: true,
        duration: obj.duration || 60,
        name: obj.name,
        description: obj.description,
        price: price,
      };

      if (!NumberUtils.isPositiveOrZeroNumber(price) || typeof obj.name != 'string' || !obj.name?.length) {
        setValidationError(validationError => [...validationError, `Invalid product: ${JSON.stringify(obj)}`]);
        return null;
      }

      return product;
    } catch (e) {
      logger.error(e);
      setValidationError(validationError => [...validationError, `Invalid product format: ${JSON.stringify(obj)}`]);
      return null;
    }
  };

  const parseToCategory = (obj: any): ProductCategory => {
    try {
      const category: ProductCategory = {
        __type__: __TYPE__.BASIC_PRODUCT_CATEGORY,
        name: obj.name,
        description: obj.description,
        products: obj.products?.map((p: any) => parseToProduct(p))?.filter((p: Product) => !!p),
      };

      if (typeof obj.name != 'string' || !obj.name?.length) {
        setValidationError(validationError => [...validationError, `Invalid category: ${JSON.stringify(obj)}`]);
        return null;
      }

      if (!category.products?.length) {
        setValidationWarn(validationWarn => [...validationWarn, `Category ${obj.name} does not have any products`]);
      }
      return category;
    } catch (e) {
      logger.error(e);
      setValidationError(validationError => [...validationError, `Invalid category format: ${JSON.stringify(obj)}`]);
      return null;
    }
  };

  const parseToCategoryList = (obj: any): ProductCategory[] => {
    try {
      const categoryList: ProductCategory[] = obj?.map((item: any) => parseToCategory(item))?.filter((p: any) => !!p);

      if (!categoryList?.length) {
        setValidationError(validationError => [...validationError, `Category list contain none valid category`]);
        return null;
      }

      return categoryList?.length ? categoryList : null;
    } catch (e) {
      logger.error(e);
      setValidationError(validationError => [
        ...validationError,
        `Invalid category list format:  ${JSON.stringify(obj)}`,
      ]);
      return null;
    }
  };

  const parseToObject = (input: string): any => {
    try {
      return JSON.parse(input);
    } catch (e) {
      logger.error(e);
      setValidationError(validationError => [...validationError, 'Input is not in json format']);
      return null;
    }
  };

  const validateInput = (input: string): ProductCategory[] => {
    const inputObj = parseToObject(input);

    if (!inputObj) {
      return null;
    }

    const catList = parseToCategoryList(inputObj);

    if (!catList?.length) {
      return null;
    }

    setValidationSuccess(validationSuccess => [...validationSuccess, 'Input is valid']);
    return catList;
  };

  const submitDataChangeHandler = (event: any) => {
    const { value } = event.target;
    setDataToSubmit(value);
  };

  const resetValidationMessages = () => {
    setValidationWarn([]);
    setValidationError([]);
    setValidationSuccess([]);
  };

  const resetRequestLogs = () => {
    setRequestLogs([]);
  };

  const reset = () => {
    resetValidationMessages();
    resetRequestLogs();
    setDataToSubmit(null);
  };

  const createProduct = async (product: Product, categoryId: number): Promise<boolean> => {
    logger.debug(`creating product ${product.name} ...`);

    const productToCreate = {
      ...product,
      ownerId: user?.id,
      ownerEmail: user?.email,
      businessObjectId: businessObject?.id,
      categoryId,
    };

    try {
      const createdProduct = await businessObjectService.createProductForCategory(productToCreate);

      if (!createdProduct?.id) {
        throw new Error(`Cannot get id of product ${productToCreate.name}`);
      }

      setRequestLogs(requestLogs => [
        ...requestLogs,
        {
          type: 'success',
          text: `Successfully created product ${productToCreate.name}`,
        },
      ]);
      return true;
    } catch (e) {
      logger.error(e);
      setRequestLogs(requestLogs => [
        ...requestLogs,
        {
          type: 'error',
          text: `Failed to create product ${product.name}`,
        },
      ]);
      return false;
    }
  };

  const createCategory = async (category: ProductCategory): Promise<ProductCategory> => {
    logger.debug(`creating category ${category.name} ...`);

    const categoryToCreate: ProductCategory = {
      ...category,
      ownerEmail: user?.email,
      businessObjectId: businessObject?.id,
      products: undefined,
    };

    try {
      const createdCategory = await businessObjectService.createCategoryForBo(categoryToCreate);

      if (!createdCategory?.id) {
        throw new Error(`Cannot get id of category ${categoryToCreate.name}`);
      }

      setRequestLogs(requestLogs => [
        ...requestLogs,
        {
          type: 'success',
          text: `Successfully created category ${categoryToCreate.name}`,
        },
      ]);

      return createdCategory;
    } catch (e) {
      logger.error(e);
      setRequestLogs(requestLogs => [
        ...requestLogs,
        {
          type: 'error',
          text: `Failed to create category ${category.name}`,
        },
      ]);
      return null;
    }
  };

  const createCategoryAndItsProducts = async (category: ProductCategory): Promise<boolean> => {
    let isAllSuccess = true;
    const createdCategory = await createCategory(category);

    if (!createdCategory?.id) {
      return false;
    }

    for (let i = 0; i < category?.products?.length; i++) {
      const isSuccess = await createProduct(category.products[i], createdCategory.id);
      isAllSuccess = isAllSuccess && isSuccess;
    }

    return isAllSuccess;
  };

  const executeRequest = async (data: ProductCategory[]): Promise<boolean> => {
    let isAllSuccess = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i].name === '__default__') {
        logger.debug(`Skip creating category ${data[i].name}`);
        continue;
      }

      const isSuccess = await createCategoryAndItsProducts(data[i]);
      isAllSuccess = isAllSuccess && isSuccess;
    }
    return isAllSuccess;
  };

  const submit = async (event: any) => {
    event.preventDefault();

    const data = validateInput(dataToSubmit);

    if (!data) {
      return;
    }

    setIsSubmitting(true);

    resetValidationMessages();
    resetRequestLogs();

    const isAllSuccess = await executeRequest(data);

    if (isAllSuccess) {
      setRedirectToBoDetail(getBoDetailPath(user?.id, businessObject?.id));
    }

    setIsSubmitting(false);
  };

  const classes = useStyles();

  return redirectToBoDetail ? (
    <Redirect to={redirectToBoDetail} />
  ) : (
    <>
      <Paper className={classes.paper}>
        {isAbleToMassiveUpdate ? (
          <>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" className={classes.title}>
                Massive create categories and products
              </Typography>
            </Toolbar>

            <form className={classes.form} onSubmit={submit}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                required
                multiline
                fullWidth
                inputRef={input => input && input.focus()}
                key="dataAsJson"
                label="Data as json"
                value={dataToSubmit || ''}
                placeholder={template}
                disabled={isSubmitting}
                onChange={submitDataChangeHandler}
              />

              {validationError?.map((message, index) => (
                <Alert
                  key={'error' + index}
                  className={classes.validationMessage}
                  elevation={6}
                  variant="filled"
                  severity="error"
                >
                  {message}
                </Alert>
              ))}
              {validationWarn?.map((message, index) => (
                <Alert
                  key={'warning' + index}
                  className={classes.validationMessage}
                  elevation={6}
                  variant="filled"
                  severity="warning"
                >
                  {message}
                </Alert>
              ))}
              {validationSuccess?.map((message, index) => (
                <Alert
                  key={'success' + index}
                  className={classes.validationMessage}
                  elevation={6}
                  variant="filled"
                  severity="success"
                >
                  {message}
                </Alert>
              ))}

              {/*Submit button*/}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    disabled={isSubmitting}
                    onClick={reset}
                    startIcon={<Cancel />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                    startIcon={<Save />}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

              {requestLogs?.map((log, index) => (
                <Alert
                  key={'log' + index}
                  className={classes.validationMessage}
                  elevation={6}
                  variant="filled"
                  severity={log.type}
                >
                  {log.text}
                </Alert>
              ))}
            </form>
          </>
        ) : (
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              Unavailable because business object has already category.
            </Typography>
          </Toolbar>
        )}
      </Paper>

      <div>
        <Link to={`${getBoDetailPath(user?.id, businessObject?.id)}`}>Back to business object detail</Link>
      </div>
    </>
  );
}
