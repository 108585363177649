import { createRoute } from '../../../../../commons/utils/route-helper';
import { USER_DETAIL_ROUTE } from '../../user-context/pages/user-detail-context/routing/user-detail-route-definition.model';

const BUSINESS_OBJECT_ROUTE = {
  BUSINESS_OBJECT_CREATE: `${USER_DETAIL_ROUTE.BUSINESS_OBJECT}/create`,
  BUSINESS_OBJECT_DETAIL: `${USER_DETAIL_ROUTE.BUSINESS_OBJECT}/:boId`,
};

const getBoDetailPath = function (userId: number, boId: number): string {
  return createRoute(BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_DETAIL, { userId, boId });
};

const getBoCreatePath = function (userId: number): string {
  return createRoute(BUSINESS_OBJECT_ROUTE.BUSINESS_OBJECT_CREATE, { userId });
};

export { BUSINESS_OBJECT_ROUTE, getBoDetailPath, getBoCreatePath };
