import ScrollToTop from '../../../../../../../commons/utils/scroll-to-top';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../../../../../routes/private-route.component';
import React from 'react';
import CategoryContextComponent from '../../../../category-context/category-context.component';
import BusinessObjectDetailComponent from '../pages/business-object-detail/business-object-detail.component';
import { BUSINESS_OBJECT_DETAIL_ROUTE } from './business-object-detail-route-definition.model';

export default function BusinessObjectDetailRoutingComponent(props: any) {
  return (
    <div>
      <ScrollToTop>
        <Switch>
          <PrivateRoute {...props} path={BUSINESS_OBJECT_DETAIL_ROUTE.CATEGORY} component={CategoryContextComponent} />
          <PrivateRoute {...props} component={BusinessObjectDetailComponent} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}
