import React from 'react';
import ProductDetailRoutingComponent from './routing/product-detail-routing.component';
import { User } from '../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import { ProductCategory } from '../../../../../../models/interfaces/ProductCategory.interface';
import { Product } from '../../../../../../models/interfaces/Product.interface';
import businessObjectService from '../../../../../../services/business-object.service';

export default function ProductDetailContextComponent(props: any) {
  const { match } = props;
  const [product, setProduct] = React.useState<Product>(null);

  React.useEffect(() => {
    const getProduct = async (userEmail: string, boId: number, categoryId: number, productId: number) => {
      const data = await businessObjectService.getProductById(userEmail, boId, categoryId, productId);
      setProduct(data);
    };

    const user: User = props.user;
    const businessObject: BusinessObject = props.businessObject;
    const category: ProductCategory = props.category;

    const productId = match?.params?.productId;

    getProduct(user?.email, businessObject?.id, category?.id, productId);
  }, []);

  return product ? <ProductDetailRoutingComponent {...props} product={product} /> : <></>;
}
