import React from 'react';
import BusinessObjectFormComponent from '../../../../components/business-object-form/business-object-form.component';
import { User } from '../../../../../../../../models/interfaces/User.interface';
import { BusinessObject } from '../../../../../../../../models/interfaces/BusinessObject.interface';
import { Link, Redirect } from 'react-router-dom';
import CategoryTableComponent from '../../../../../category-context/components/category-table/category-table.component';
import { getUserDetailPath } from '../../../../../user-context/routing/user-route-definition.model';
import { Add } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  getCategoryCreatePath,
  getCategoryMassiveCreatePath,
} from '../../../../../category-context/routing/category-route-definition.model';
import BusinessObjectBookingSettingsComponent from '../../../../components/business-object-booking-settings/business-object-booking-settings.component';
import businessObjectService from '../../../../../../../../services/business-object.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createNewCategoryButton: {
      marginBottom: '20px',
      marginRight: '20px',
    },
  })
);

export default function BusinessObjectDetailComponent(props: any) {
  const [user, setUser] = React.useState<User>(null);
  const [businessObject, setBusinessObject] = React.useState<BusinessObject>(null);
  const [redirectToCreateCategoryPage, setRedirectToCreateCategoryPage] = React.useState<string>(null);

  React.useEffect(() => {
    setUser(props.user);
    setBusinessObject(props.businessObject);
  }, [props]);

  const isAbleToMassiveUpdate = () => !businessObject?.categories?.length;

  const onBoChangeHandler = async () => {
    const data = await businessObjectService.getBusinessObjectById(user?.email, businessObject?.id);
    setBusinessObject(data);
  };

  const handleCreateCategory = () => {
    setRedirectToCreateCategoryPage(getCategoryCreatePath(user?.id, businessObject?.id));
  };

  const classes = useStyles();

  return redirectToCreateCategoryPage ? (
    <Redirect to={redirectToCreateCategoryPage} />
  ) : (
    <>
      {user?.id ? <BusinessObjectFormComponent owner={user} businessObject={businessObject} /> : <></>}

      <BusinessObjectBookingSettingsComponent
        ownerId={user?.id}
        ownerEmail={user?.email}
        businessObject={businessObject}
        onBoChange={onBoChangeHandler}
      />

      <Button
        onClick={handleCreateCategory}
        variant="contained"
        color="primary"
        className={classes.createNewCategoryButton}
        startIcon={<Add />}
      >
        Create new category
      </Button>

      {isAbleToMassiveUpdate() ? (
        <Link to={`${getCategoryMassiveCreatePath(user?.id, businessObject?.id)}`}>
          <Button variant="contained" color="primary" className={classes.createNewCategoryButton}>
            Massive create category
          </Button>
        </Link>
      ) : (
        <></>
      )}

      <CategoryTableComponent
        userId={user?.id}
        businessObjectId={businessObject?.id}
        categories={businessObject?.categories}
      />

      <div>
        <Link to={`${getUserDetailPath(user?.id)}`}>Back to user detail</Link>
      </div>
    </>
  );
}
