export class NumberUtils {
  public static isNumber(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return typeof value === 'number';
  }

  public static isPositiveOrZeroNumber(value: any): boolean {
    if (!NumberUtils.isNumber(value)) {
      return false;
    }

    return value >= 0;
  }

  public static roundUpNumber(num: number, decimalDigit = 1): number {
    let tenFactor = 1;
    for (let i = 0; i < decimalDigit; i += 1) {
      tenFactor *= 10;
    }

    return Math.ceil(num * tenFactor) / tenFactor;
  }

  public static isSameDecimalNumber(n1: number, n2: number): boolean {
    return Math.abs(n1 - n2) < 0.0000000000001;
  }
}
