import storageService from './storage.service';

class AuthenticationHelperService {
  private static readonly TOKEN_KEY = 'token';

  public isAuthenticated(): boolean {
    return Boolean(this.getAuthToken());
  }

  public getAuthToken(): string {
    return storageService.getItem(AuthenticationHelperService.TOKEN_KEY);
  }

  public setAuthToken(token: string) {
    storageService.setItem(AuthenticationHelperService.TOKEN_KEY, token);
  }

  public removeAuthToken(): void {
    storageService.removeItem(AuthenticationHelperService.TOKEN_KEY);
  }
}

const authenticationHelperService = new AuthenticationHelperService();

export default authenticationHelperService;
