import { format, parse } from 'date-fns';
import moment from 'moment';
import { DATE_FORMAT } from '../../models/enums/DateFormat.enum';
import { MONTH_FORMAT } from '../../models/enums/MonthFormat.enum';
import { TIME_FORMAT } from '../../models/enums/TimeFormat.enum';

// Format Docs: https://date-fns.org/v1.28.5/docs/format

export class DateTimeUtils {
  public static formatDateToString(d: Date, targetFormat: DATE_FORMAT | MONTH_FORMAT): string {
    return format(d, targetFormat);
  }

  public static formatTimeToString(d: Date, withoutSecond?: boolean): string {
    return withoutSecond ? format(d, TIME_FORMAT.TIME_FORMAT_WITHOUT_SECOND) : format(d, TIME_FORMAT.TIME_FORMAT);
  }

  public static formatDateTimeToString(d: Date, targetFormat: DATE_FORMAT): string {
    if (targetFormat === DATE_FORMAT.DATE_FORMAT_BACKEND) {
      return `${DateTimeUtils.formatDateToString(d, targetFormat)}T${DateTimeUtils.formatTimeToString(d)}`;
    } else {
      return `${DateTimeUtils.formatDateToString(d, targetFormat)} ${DateTimeUtils.formatTimeToString(d, true)}`;
    }
  }

  public static formatTimeWithSecondToHourMinute(timeWithSecond: string): string {
    const pattern = 'HH:mm';

    if (moment(timeWithSecond, pattern, true).isValid()) {
      return timeWithSecond;
    }

    const arr = timeWithSecond?.split(':');
    const formattedValue = `${arr?.[0]}:${arr?.[1]}`;

    if (moment(formattedValue, pattern, true).isValid()) {
      return formattedValue;
    }

    return null;
  }

  public static formatHourMinuteToTimeWithSecond(hourMinuteTime: string): string {
    const pattern = 'HH:mm:ss';

    if (moment(hourMinuteTime, pattern, true).isValid()) {
      return hourMinuteTime;
    }
    const formattedValue = `${hourMinuteTime}:00`;

    if (moment(formattedValue, pattern, true).isValid()) {
      return formattedValue;
    }

    return null;
  }

  public static isSameDate(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d1.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }

  public static isDateInRange(d: Date, fromDate: Date, toDate: Date): boolean {
    const dStr = DateTimeUtils.formatDateToString(d, DATE_FORMAT.DATE_FORMAT_BACKEND);
    const fromDateStr = DateTimeUtils.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND);
    const toDateStr = DateTimeUtils.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND);

    return dStr >= fromDateStr && dStr <= toDateStr;
  }

  public static parseStringToDate(dateTime: string): Date {
    if (!dateTime) {
      return null;
    }

    return parse(dateTime);
  }

  public static generateDateFromDateAndTimeString(date: string, time: string): Date {
    const dateObj = DateTimeUtils.parseStringToDate(date);

    const timeArr = time?.split(':');

    try {
      if (timeArr?.length > 1) {
        dateObj.setHours(Number(timeArr?.[0]));
        dateObj.setMinutes(Number(timeArr?.[1]));
        dateObj.setSeconds(Number(timeArr?.[2]));
      }
    } catch (e) {
      // no need to log
    }

    return dateObj;
  }

  public static getBeginOfDate(d: Date): Date {
    if (!d) return null;
    d.setHours(0, 0, 0, 0);
    return d;
  }

  public static getEndOfDate(d: Date): Date {
    if (!d) return null;
    d.setHours(23, 59, 59, 999);
    return d;
  }

  public static getFirstDateOfWeek(d?: Date): Date {
    return moment(d || new Date())
      .startOf('isoWeek')
      .toDate();
  }

  public static getFirstDateOfPreviousWeek(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'week')
      .startOf('isoWeek')
      .toDate();
  }

  public static getFirstDateOfNextWeek(d?: Date): Date {
    return moment(d || new Date())
      .add(1, 'week')
      .startOf('isoWeek')
      .toDate();
  }

  public static getFirstDateOfMonth(d?: Date): Date {
    return moment(d || new Date())
      .startOf('month')
      .toDate();
  }

  public static getFirstDateOfPreviousMonth(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'month')
      .startOf('month')
      .toDate();
  }

  public static getFirstDateOfNextMonth(d?: Date): Date {
    return moment(d || new Date())
      .add(1, 'month')
      .startOf('month')
      .toDate();
  }

  public static getFirstDateOfQuarter(d?: Date): Date {
    return moment(d || new Date())
      .startOf('quarter')
      .toDate();
  }

  public static getFirstDateOfPreviousQuarter(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'quarter')
      .startOf('quarter')
      .toDate();
  }

  public static getFirstDateOfYear(d?: Date): Date {
    return moment(d || new Date())
      .startOf('year')
      .toDate();
  }

  public static getFirstDateOfPreviousYear(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'year')
      .startOf('year')
      .toDate();
  }

  public static getLastDateOfWeek(d?: Date): Date {
    return moment(d || new Date())
      .endOf('isoWeek')
      .toDate();
  }

  public static getLastDateOfPreviousWeek(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'week')
      .endOf('isoWeek')
      .toDate();
  }

  public static getLastDateOfNextWeek(d?: Date): Date {
    return moment(d || new Date())
      .add(1, 'week')
      .endOf('isoWeek')
      .toDate();
  }

  public static getLastDateOfMonth(d?: Date): Date {
    return moment(d || new Date())
      .endOf('month')
      .toDate();
  }

  public static getLastDateOfPreviousMonth(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'month')
      .endOf('month')
      .toDate();
  }

  public static getLastDateOfNextMonth(d?: Date): Date {
    return moment(d || new Date())
      .add(1, 'month')
      .endOf('month')
      .toDate();
  }

  public static getLastDateOfPreviousQuarter(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'quarter')
      .endOf('quarter')
      .toDate();
  }

  public static getLastDateOfYear(d?: Date): Date {
    return moment(d || new Date())
      .endOf('year')
      .toDate();
  }

  public static getLastDateOfPreviousYear(d?: Date): Date {
    return moment(d || new Date())
      .subtract(1, 'year')
      .endOf('year')
      .toDate();
  }

  public static addDayToDate(d: Date, diffDays = 0): Date {
    if (!d) {
      return null;
    }

    return moment(d).add(diffDays, 'day').toDate();
  }
  public static addMonthToDate(d: Date, diffMonths = 0): Date {
    if (!d) {
      return null;
    }

    return moment(d).add(diffMonths, 'month').toDate();
  }
  public static addYearToDate(d: Date, diffYears = 0): Date {
    if (!d) {
      return null;
    }

    return moment(d).add(diffYears, 'year').toDate();
  }

  public static addHourToDate(d: Date, diffHours = 0): Date {
    if (!d) {
      return null;
    }

    return moment(d).add(diffHours, 'hour').toDate();
  }

  public static getListOfDateString(fromDate: string, toDate: string): string[] {
    const listOfDate: string[] = [];

    let runnerAsObj = this.parseStringToDate(fromDate);
    let runnerAsStr = `${fromDate}`;

    while (runnerAsStr <= toDate) {
      if (listOfDate.includes(runnerAsStr)) {
        break;
      }

      listOfDate.push(runnerAsStr);

      runnerAsObj = this.addDayToDate(runnerAsObj, 1);
      runnerAsStr = this.formatDateToString(runnerAsObj, DATE_FORMAT.DATE_FORMAT_BACKEND);
    }

    return listOfDate;
  }

  public static getListOfDate(fromDate: Date, toDate: Date): Date[] {
    const listOfDateStr = this.getListOfDateString(
      this.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
      this.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND)
    );

    return listOfDateStr?.map(dateStr => this.parseStringToDate(dateStr));
  }

  // fromMonth and toMonth have also format YYYY-MM-DD
  // results is the list of first date of months in format YYYY-MM-DD
  public static getListOfMonthString(fromMonth: string, toMonth: string): string[] {
    const listOfDate: string[] = [];

    let runnerAsObj = this.getFirstDateOfMonth(this.parseStringToDate(fromMonth));
    let runnerAsStr = this.formatDateToString(runnerAsObj, DATE_FORMAT.DATE_FORMAT_BACKEND);

    const endMonthStr = this.formatDateToString(
      this.getFirstDateOfMonth(this.parseStringToDate(toMonth)),
      DATE_FORMAT.DATE_FORMAT_BACKEND
    );

    while (runnerAsStr <= endMonthStr) {
      if (listOfDate.includes(runnerAsStr)) {
        break;
      }

      listOfDate.push(runnerAsStr);

      runnerAsObj = this.addMonthToDate(runnerAsObj, 1);
      runnerAsStr = this.formatDateToString(runnerAsObj, DATE_FORMAT.DATE_FORMAT_BACKEND);
    }

    return listOfDate;
  }

  public static getListOfMonth(fromDate: Date, toDate: Date): Date[] {
    const listOfDateStr = this.getListOfMonthString(
      this.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
      this.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND)
    );

    return listOfDateStr?.map(dateStr => this.parseStringToDate(dateStr));
  }

  // fromYear and toYear have also format YYYY-MM-DD
  // results is the list of first date of years in format YYYY-MM-DD
  public static getListOfYearString(fromYear: string, toYear: string): string[] {
    const listOfDate: string[] = [];

    let runnerAsObj = this.getFirstDateOfYear(this.parseStringToDate(fromYear));
    let runnerAsStr = this.formatDateToString(runnerAsObj, DATE_FORMAT.DATE_FORMAT_BACKEND);

    const endYearStr = this.formatDateToString(
      this.getFirstDateOfYear(this.parseStringToDate(toYear)),
      DATE_FORMAT.DATE_FORMAT_BACKEND
    );

    while (runnerAsStr <= endYearStr) {
      if (listOfDate.includes(runnerAsStr)) {
        break;
      }

      listOfDate.push(runnerAsStr);

      runnerAsObj = this.getFirstDateOfYear(this.addYearToDate(runnerAsObj, 1));
      runnerAsStr = this.formatDateToString(runnerAsObj, DATE_FORMAT.DATE_FORMAT_BACKEND);
    }

    return listOfDate;
  }

  public static getListOfYear(fromDate: Date, toDate: Date): Date[] {
    const listOfDateStr = this.getListOfYearString(
      this.formatDateToString(fromDate, DATE_FORMAT.DATE_FORMAT_BACKEND),
      this.formatDateToString(toDate, DATE_FORMAT.DATE_FORMAT_BACKEND)
    );

    return listOfDateStr?.map(dateStr => this.parseStringToDate(dateStr));
  }
}
