import axios, { AxiosResponse } from 'axios';

class HttpService {
  public get(url: string, headers: any = null): Promise<AxiosResponse> {
    return axios.get(url, { headers });
  }

  public post(url: string, data: any, headers: any = null): Promise<AxiosResponse> {
    return axios.post(url, data, { headers });
  }

  public put(url: string, data: any, headers: any = null): Promise<AxiosResponse> {
    return axios.put(url, data, { headers });
  }

  public patch(url: string, data: any, headers: any = null): Promise<AxiosResponse> {
    return axios.patch(url, data, { headers });
  }

  public delete(url: string, headers: any = null): Promise<AxiosResponse> {
    return axios.delete(url, { headers });
  }
}

const httpService = new HttpService();

export default httpService;
