import scriptLoaderService from './script-loader.service';
import { UrlUtils } from '../commons/utils/url.utils';
import { GoogleApiPlace } from '../models/interfaces/GoogleApiPlace.interface';
import ENV from '../commons/environment';

declare var google: any;

class GoogleApiService {
  // private readonly API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  private readonly API_KEY = ENV.GOOGLE_API_KEY;

  private readonly GOOGLE_MAPS_URL = 'https://maps.googleapis.com';
  private readonly GOOGLE_FIND_PLACE_MAX_RADIUS = 50 * 1000; // meter

  private googlePlacesService: google.maps.places.PlacesService;

  public async init() {
    await scriptLoaderService.loadScript(
      UrlUtils.addParameterToUrl(`${this.GOOGLE_MAPS_URL}/maps/api/js`, {
        libraries: 'places',
        key: this.API_KEY,
        // To ignore warning from google api
        // see: https://stackoverflow.com/questions/75179573/how-to-fix-loading-the-google-maps-javascript-api-without-a-callback-is-not-supp
        callback: 'Function.prototype',
      })
    );

    this.googlePlacesService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  // keyword can be nails, hair, lashes, etc...
  public async getSalonNearby(address: string, keyword: string): Promise<GoogleApiPlace[]> {
    const matchedPlaceForAddress = await this.findPlacesFromQuery(address);
    // take only max 3 first results
    const firstThreeResults = matchedPlaceForAddress?.slice(0, 3);

    const promises = firstThreeResults?.map(r =>
      this.findSalonNearby(r.geometry?.location?.lat(), r.geometry?.location?.lng(), 10, keyword)
    );

    const promisesResults = await Promise.all(promises);

    const setOfUniquePlaceId = new Set<string>();
    const results: GoogleApiPlace[] = [];
    promisesResults?.forEach(places => {
      places?.forEach(place => {
        if (!setOfUniquePlaceId.has(place.place_id)) {
          results.push(place);
          setOfUniquePlaceId.add(place.place_id);
        }
      });
    });

    return results;
  }

  // see: https://developers.google.com/maps/documentation/javascript/places#find_place_requests
  private findPlacesFromQuery(text: string): Promise<GoogleApiPlace[]> {
    return new Promise(resolve => {
      if (!text?.length) {
        resolve([]);
      }

      const request = {
        query: text,
        fields: ['formatted_address', 'name', 'place_id', 'geometry'],
      };

      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.findPlaceFromQuery(request, (results: any[], status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          resolve([]);
        }
      });
    });
  }

  // see: https://developers.google.com/maps/documentation/javascript/places#place_search_requests
  private findSalonNearby(
    lat: number,
    lng: number,
    radiusInMeter: number,
    keyword?: string
  ): Promise<GoogleApiPlace[]> {
    return new Promise(resolve => {
      const request: google.maps.places.PlaceSearchRequest = {
        location: { lat, lng },
        radius: Math.min(radiusInMeter, this.GOOGLE_FIND_PLACE_MAX_RADIUS), // max radius for google api = 50km
        keyword: keyword?.length ? `${keyword} salon` : 'nails salon',
        type: 'beauty_salon',
      };

      this.googlePlacesService.nearbySearch(
        request,
        (
          results: google.maps.places.PlaceResult[],
          status: google.maps.places.PlacesServiceStatus,
          pagination: google.maps.places.PlaceSearchPagination
        ) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            // this.searchPagination = pagination;
            resolve(results?.map(r => this.formatResult(r))?.filter(r => r.photoUrls?.length > 0));
          } else {
            resolve([]);
          }
        }
      );
    });
  }

  private formatResult(result: google.maps.places.PlaceResult): GoogleApiPlace {
    return {
      ...result,
      geometryLocationData: {
        lat: result.geometry?.location?.lat(),
        lng: result.geometry?.location?.lng(),
      },
      photoUrls: result.photos?.map(p => p?.getUrl({})),
    };
  }
}

const googleApiService = new GoogleApiService();
export default googleApiService;
