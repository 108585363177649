import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginComponent from './login/login.component';
import { Route, Switch } from 'react-router-dom';
import { AUTH_ROUTE } from './auth-route-definition.model';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(3),
    backgroundColor: 'transparent',
    height: '10vh',
  },
}));

export default function AuthProcessComponent(props: any) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="logo" className={classes.avatar} src="/images/logo/logo_small.png" />
        <Switch>
          <Route {...props} path={AUTH_ROUTE.USERNAME_AND_PASSWORD} component={LoginComponent} />
          <Route {...props} component={LoginComponent} />
        </Switch>
      </div>
    </Container>
  );
}
