import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessObject } from '../../../../../../models/interfaces/BusinessObject.interface';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import businessObjectService from '../../../../../../services/business-object.service';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid, LinearProgress } from '@material-ui/core';
import logger from '../../../../../../commons/logger';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '20px',
    padding: '20px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    width: '100%',
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

interface BusinessObjectBookingSettingsComponentProps {
  ownerId?: number;
  ownerEmail?: string;
  businessObject?: BusinessObject;
  onBoChange: Function;
}

export default function BusinessObjectBookingSettingsComponent(props: BusinessObjectBookingSettingsComponentProps) {
  const [rwgActive, setRwgActive] = React.useState<boolean>(false);
  const [isTestingBo, setIsTestingBo] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setRwgActive(props.businessObject?.rwgActive);
    setIsTestingBo(props.businessObject?.testing);
  }, [props]);

  const saveRwgActiveFlag = async () => {
    try {
      setIsLoading(true);

      const boToSubmit = { ...(props.businessObject || {}) };
      boToSubmit.rwgActive = !rwgActive;

      const response = await businessObjectService.updateBusinessObject(boToSubmit);

      if (response?.id) {
        props.onBoChange();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const saveOnlineSearchActiveFlag = async () => {
    try {
      setIsLoading(true);

      const boToSubmit = { ...(props.businessObject || {}) };
      boToSubmit.testing = !isTestingBo;

      const response = await businessObjectService.updateBusinessObject(boToSubmit);

      if (response?.id) {
        props.onBoChange();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          Booking settings
        </Typography>
      </Toolbar>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Switch checked={rwgActive} disabled={isLoading} onChange={saveRwgActiveFlag} />}
            label="Reserve with Google"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={!isTestingBo}
                disabled={isLoading || !props?.businessObject?.urlName}
                onChange={saveOnlineSearchActiveFlag}
              />
            }
            label="Online searching"
          />
          {!props?.businessObject?.urlName ? <div>URL name is required</div> : <></>}
        </Grid>
      </Grid>

      {isLoading ? <LinearProgress /> : <></>}
    </Paper>
  );
}
