import React from 'react';
import CustomDataTableComponent from '../../../../../../commons/components/custom-data-table/custom-data-table.component';
import {
  CustomDataTableConfig,
  HeadCell,
} from '../../../../../../commons/components/custom-data-table/custom-data-table-config.model';
import { User } from '../../../../../../models/interfaces/User.interface';
import { Redirect } from 'react-router-dom';
import { createRoute } from '../../../../../../commons/utils/route-helper';
import { USER_ROUTE } from '../../routing/user-route-definition.model';

interface UserTableComponentProps {
  users: User[];
  onSelect?: Function;
}

export default function UserTableComponent(props: UserTableComponentProps) {
  const [redirectToUserDetail, setRedirectToUserDetail] = React.useState<string>(null);

  const headCells: HeadCell[] = [
    { id: 'id', field: 'id', label: 'ID' },
    { id: 'name', field: 'name', label: 'Name' },
    { id: 'surname', field: 'surname', label: 'Surname' },
    { id: 'phoneNr', field: 'phoneNr', label: 'Telephone number' },
    { id: 'email', field: 'email', label: 'Email' },
    { id: 'creditBudget', field: 'creditBudget', label: 'Credit budget' },
  ];

  const tableConfig: CustomDataTableConfig = {
    tableName: 'Users',
    rowsPerPage: 10,
    headCells,
  };

  const handleSelectRow = (selectedUser: User) => {
    if (props?.onSelect) {
      props.onSelect(selectedUser);
    } else if (selectedUser?.id) {
      const routePath = createRoute(USER_ROUTE.USER_DETAIL, { userId: selectedUser.id });
      setRedirectToUserDetail(routePath);
    }
  };

  return redirectToUserDetail ? (
    <Redirect to={redirectToUserDetail} />
  ) : (
    <CustomDataTableComponent tableConfig={tableConfig} data={props.users} onSelect={handleSelectRow} />
  );
}
